<template>
    <Panel
        :modelValue="modelValue"
        title="Search Terms"
        @close="$emit('update:modelValue', false)"
        :width="1116"
    >
        <template #title>
            <EntityPill
                :type="Improvement.LocationEntity.NGram"
                :content="`${ngrams.length} ${ngrams.length > 1 ? 'NGrams' : 'NGram'}`"
            />
        </template>
        <template #content>
            <oTable
                :headers="detailedViewNgramHeaders"
                :items="ngrams"
                fixed-layout
                :border-radius="24"
            >
                <template #column.ngram="{ value }">
                    <EntityPill :type="Improvement.LocationEntity.NGram" :content="value" />
                </template>
                <template #column.cost="{ value }">
                    <Money :value="value" />
                </template>
                <template #column.impressions="{ value }">
                    <Number :value="value" />
                </template>
                <template #column.conversions="{ value }">
                    <Number :value="value" />
                </template>
                <template #column.conversionValue="{ value }">
                    <Money :value="value" />
                </template>
                <template #column.cpa="{ value }">
                    <Money :value="value" />
                </template>
                <template #column.roas="{ value }">
                    <Percent :value="value" />
                </template>
                <template #column.cpi="{ value }">
                    {{ formatCpi(value) }}
                </template>
                <template #column.vpi="{ value }">
                    {{ formatVpi(value) }}
                </template>
                <template #column.vsAvg="{ value, row }">
                    <Text size="f-8" weight="500" :color="row.vsAverageColor">
                        <Percent :value="value" :decimal-places="2" include-sign />
                    </Text>
                </template>

                <template #column.nscoreSortValue="{ row }">
                    <NScoreCell v-bind="row" />
                </template>
                <template #column.keywordConflicts="{ value, row }">
                    <AffectedKeywordsCell
                        :ngram="row.ngram"
                        :keyword-resource-names="value"
                        :modal="false"
                    />
                </template>
            </oTable>
            <Spacer height="2.25rem" />
            <BlockNote
                >Adding these n-grams as negatives would block the following search terms</BlockNote
            >
            <Spacer height="2.25rem" />
            <SearchTermTable :search-terms="searchTerms" :key="uniqId" />
        </template>
    </Panel>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import uniq from 'lodash-es/uniq'

import {
    Panel,
    oTable,
    Spacer,
    Text,
    EntityPill,
    Percent,
    Money,
    Number,
} from '@opteo/components-next'
import BlockNote from '@/components/toolkit/ngram/BlockNote.vue'

import { Improvement, NgramTool } from '@opteo/types'
import { useNGramTool } from '@/composition/toolkit/nGramTool/useNGramTool'

import SearchTermTable from './SearchTermTable.vue'
import NScoreCell from './NScoreCell.vue'
import AffectedKeywordsCell from './AffectedKeywordsCell.vue'

const { detailedViewNgramHeaders, formatCpi, formatVpi, performanceMaxActive } = useNGramTool()

const props = defineProps<{
    modelValue: boolean
    ngrams: NgramTool.NgramData[]
}>()

const uniqId = computed(() => uniq(props.ngrams.map(ngram => ngram.ngram)).join('-'))

const searchTerms = computed(() => uniq(props.ngrams.flatMap(ngram => ngram.searchTerms)))
</script>

<style scoped lang="scss">
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.conflicts-cell,
.nscore-cell,
.keyword-source-cell {
    display: flex;
    align-items: center;
}
.info-circle-icon {
    width: 1rem;
    cursor: pointer;
}
</style>
