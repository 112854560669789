<template>
    <ImprovementView :push-messages="pushMessages" :push-action-text="pushActionText">
        <ImprovementContent>
            <ImprovementHeader :title="title">
                <div v-if="aboveMobile">
                    <EntityPillLink
                        :entityPillData="{ type: entity, content: entityName }"
                        :deepLinkParams="entityParams"
                    />
                    <Spacer height="2rem" />
                </div>
                <Text v-if="single" as="p">
                    The campaign above is set to display ads on Google Search Partner sites. Ads
                    listed on these sites have
                    {{ campaignPerformanceDescription }}. Preventing your ads from being shown on
                    Google Search Partner sites should
                    {{ isUsingROAS ? 'increase overall ROAS' : 'reduce overall CPA' }} and
                    {{ isUsingROAS ? 'reduce' : 'decrease' }}
                    wasted spend.
                </Text>
                <Text v-else as="p">
                    Campaigns in this campaign group are set to display ads on Google Search Partner
                    sites. Ads listed on these sites have
                    {{ campaignPerformanceDescription }}. Preventing your ads from being shown on
                    these sites should
                    {{ isUsingROAS ? 'increase overall ROAS' : 'reduce overall CPA' }} and
                    {{ isUsingROAS ? 'reduce' : 'decrease' }}
                    wasted spend.
                </Text>
                <Spacer height="1rem" />
                <Text v-if="conversionValueLoss > 0 && isUsingROAS" as="p">
                    Pushing this improvement should reduce monthly spend by
                    <b><Money :value="costSavings" /></b>
                    <Text as="span" color="green" weight="600">
                        (<Percent :value="costSavingPercent" include-sign />)</Text
                    >. As a result of this reduction in spending, your overall ROAS should increase
                    from
                    <b><Roas :value="roasBefore" /> </b>
                    to
                    <b><Roas :value="roasAfter" /> </b>
                    <Text as="span" color="green" weight="600">
                        (<Percent :value="roasIncreasePercent" include-sign />)</Text
                    >
                    with a marginal loss of
                    <b><Money :value="conversionValueLoss" /></b> per month.
                </Text>
                <Text v-else-if="conversionLoss > 0 && !isUsingROAS" as="p">
                    Pushing this improvement should reduce monthly spend by
                    <b><Money :value="costSavings" /></b>
                    <Text as="span" color="green" weight="600">
                        (<Percent :value="costSavingPercent" include-sign />)</Text
                    >. As a result of this reduction in spending, your overall CPA should decrease
                    from <b><Money :value="cpaBefore" /></b> to
                    <b><Money :value="cpaAfter" /></b>

                    <Text as="span" color="green" weight="600">
                        (<Percent :value="cpaDecreasePercent" include-sign />)</Text
                    >
                    with a marginal loss of
                    <b><Number :value="Math.round(conversionLoss * 100) / 100" /></b>
                    <span v-if="conversionLoss === 1"> conversion</span>
                    <span v-else> conversions</span> per month.
                </Text>
                <Text v-else-if="isUsingROAS" as="p">
                    Pushing this improvement should reduce monthly spend by
                    <b><Money :value="costSavings" /></b>
                    <Text as="span" color="green" weight="600">
                        (<Percent :value="costSavingPercent" include-sign />)</Text
                    >. As a result of this reduction in spending, your overall ROAS should increase
                    from <b><Money :value="roasBefore" /></b> to
                    <b><Money :value="roasAfter" /> </b>
                    <Text as="span" color="green" weight="600">
                        (<Percent :value="roasIncreasePercent" include-sign />)</Text
                    >
                    with <b>no expected loss</b> of conversion value.
                </Text>
                <Text v-else as="p">
                    Pushing this improvement should reduce monthly spend by
                    <b><Money :value="costSavings" /></b>
                    <Text as="span" color="green" weight="600">
                        (<Percent :value="costSavingPercent" include-sign />)</Text
                    >. As a result of this reduction in spending, your overall CPA should decrease
                    from <b><Money :value="cpaBefore" /></b> to
                    <b><Money :value="cpaAfter" /> </b>
                    <Text as="span" color="green" weight="600">
                        (<Percent :value="cpaDecreasePercent" include-sign />)</Text
                    >
                    with <b>no expected loss</b> of conversions.
                </Text>
                <Spacer height="1rem" />
                <Text v-if="single">
                    Click
                    <Text as="span" color="green" weight="600">{{ pushActionText }}</Text>
                    to prevent ads in this campaign from being shown on Google Search Partner sites.
                    If you consider this recommendation to be a false positive, or wish to continue
                    showing ads on search partner sites, click
                    <b>Dismiss</b>.
                </Text>
                <Text v-if="!single">
                    Click
                    <Text as="span" color="green" weight="600">{{ pushActionText }}</Text>
                    to prevent ads in the following campaigns from being shown on Google Search
                    Partner sites. If you consider this recommendation to be a false positive, or
                    wish to continue using search partner sites, click
                    <b>Dismiss</b>.
                    <Spacer height="1.75rem" />
                    <EntityPillLinkList :items="entityPillList" />
                </Text>
            </ImprovementHeader>
            <ImprovementHelpLink path="/articles/900511-disable-search-partner-sites">
                Click here to learn more about disabling Search Partner sites in Opteo.
            </ImprovementHelpLink>
        </ImprovementContent>

        <ImprovementContent v-if="showBarChart">
            <Text v-if="isUsingROAS" as="h3" size="f-4" weight="600">ROAS Comparison</Text>
            <Text v-else as="h3" size="f-4" weight="600">CPA Comparison</Text>
            <Spacer height="2rem" />
            <div class="graph-container">
                <BarChart
                    :items="barChartItems"
                    :metric="barChartMetric"
                    :horizontal-markers="barChartMarkers"
                    disableFallback
                />
            </div>
        </ImprovementContent>
        <ImprovementContent>
            <Text as="h3" size="f-4" weight="600">Cost Comparison</Text>
            <Spacer height="2rem" />
            <div class="chart-container">
                <DonutChart :items="donutChartItems" :metric="donutChartMetric" :show-keys="true" />
            </div>
        </ImprovementContent>

        <ImprovementContent>
            <Text as="h3" size="f-4" weight="600">Comparison Table</Text>
            <Spacer height="2rem" />
            <oTable
                :headers="tableHeaders"
                :items="tableItems"
                responsive-mode="columns"
                responsive-col-size="60% 1fr"
                responsive-breakpoint="768px"
            >
                <!-- Search Column -->
                <template v-slot:[`column.search`]="cellData">
                    <!-- CPA vs. Average Row Formatting -->
                    <div v-if="cellData.row.id === 'vsAverage'">
                        <Text
                            v-if="
                                (cellData.value < 0 && !isUsingROAS) ||
                                (cellData.value > 0 && isUsingROAS)
                            "
                            color="green"
                            size="f-8"
                            weight="500"
                        >
                            <Percent :value="cellData.value" include-sign />
                        </Text>
                        <Text
                            v-else-if="
                                (cellData.value > 0 && !isUsingROAS) ||
                                (cellData.value < 0 && isUsingROAS)
                            "
                            color="red"
                            size="f-8"
                            weight="500"
                        >
                            <Percent :value="cellData.value" include-sign />
                        </Text>
                        <Text v-else size="f-8">
                            <Percent :value="cellData.value" include-sign />
                        </Text>
                    </div>
                </template>
                <!-- Partners Column -->
                <template v-slot:[`column.partners`]="cellData">
                    <!-- CPA vs. Average Row Formatting -->
                    <div v-if="cellData.row.id === 'vsAverage'">
                        <Text
                            v-if="
                                (cellData.value < 0 && !isUsingROAS) ||
                                (cellData.value > 0 && isUsingROAS)
                            "
                            color="green"
                            size="f-8"
                            weight="500"
                        >
                            <Percent :value="cellData.value" include-sign />
                        </Text>
                        <Text
                            v-else-if="
                                (cellData.value > 0 && !isUsingROAS) ||
                                (cellData.value < 0 && isUsingROAS)
                            "
                            color="red"
                            size="f-8"
                            weight="500"
                        >
                            <Percent :value="cellData.value" include-sign />
                        </Text>
                        <Text v-else size="f-8">
                            <Percent :value="cellData.value" include-sign />
                        </Text>
                    </div>
                </template>
            </oTable>
            <Spacer height="2rem" />
            <ImprovementNote v-if="isUsingROAS">
                If
                <span v-if="partnerConversionsValue > 0">
                    you consider <b>{{ partnerRoas }}</b> an acceptable ROAS,
                </span>
                <span v-else>
                    you consider spending
                    <b>{{ partnerCost }}</b> for <b><Money :value="0" /></b> acceptable,
                </span>
                consider <span v-if="!group.target_roas_set">setting a</span
                ><span v-else>adjusting the</span> Target ROAS for the parent campaign group in
                Campaign Groups. This should help Opteo generate higher quality improvements more
                suited to your objectives.
            </ImprovementNote>
            <ImprovementNote v-else>
                If
                <span v-if="partnerConversions > 0">
                    you consider <b>{{ partnerCpa }}</b> an acceptable CPA,
                </span>
                <span v-else>
                    you consider spending
                    <b>{{ partnerCost }}</b> for <b>0</b> conversions acceptable,
                </span>
                consider <span v-if="!group.target_cpa_set">setting a</span
                ><span v-else>adjusting the</span> Target CPA for the parent campaign group in
                Campaign Groups. This should help Opteo generate higher quality improvements more
                suited to your objectives.
            </ImprovementNote>
        </ImprovementContent>

        <ImprovementContent>
            <ImprovementCreatedDate :last-updated="lastUpdated" :total-days="lookbackWindow" />
        </ImprovementContent>
    </ImprovementView>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import {
    ImprovementHeader,
    ImprovementContent,
    ImprovementStatistics,
    ImprovementCreatedDate,
    ImprovementNote,
    Percent,
    Spacer,
    Text,
    oTable,
    DonutChart,
    BarChart,
    Number,
    Roas,
} from '@opteo/components-next'

import ImprovementView from '@/components/improvement/ImprovementView.vue'
import ImprovementHelpLink from '@/components/improvement/ImprovementHelpLink.vue'
import Ent from '@/components/util/Ent.vue'
import Money from '@/components/global/oMoney.vue'
import EntityPillLink from '@/components/global/Entity/EntityPillLink.vue'
import EntityPillLinkList from '@/components/global/Entity/EntityPillLinkList.vue'

import { useDisableSearchPartners } from '@/composition/improvement/types/useDisableSearchPartners'
import useMediaQuery from '@/composition/global/useMediaQuery'

export default defineComponent({
    name: 'DisableSearchPartners',
    components: {
        ImprovementHeader,
        ImprovementContent,
        ImprovementView,
        ImprovementHelpLink,
        ImprovementStatistics,
        ImprovementCreatedDate,
        ImprovementNote,
        EntityPillLink,
        EntityPillLinkList,
        Money,
        Percent,
        Spacer,
        Text,
        oTable,
        DonutChart,
        BarChart,
        Number,
        Ent,
        Roas,
    },
    setup() {
        return {
            ...useMediaQuery(),
            ...useDisableSearchPartners(),
        }
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.graph-container {
    @include container;
    @include pa-32;
}

@media (min-width: $mq-768-min) {
    .chart-container {
        @include container;
        @include pa-24;
    }
}
</style>
