import { Ref, computed, inject, onBeforeUnmount, provide, ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import { isBefore, sub } from 'date-fns'
import { StorageSerializers, useLocalStorage } from '@vueuse/core'
import formatDistanceToNowStrict from 'date-fns/formatDistanceToNowStrict'
import sum from 'lodash-es/sum'
import sumBy from 'lodash-es/sumBy'
import sortBy from 'lodash-es/sortBy'

import { Account, Api, Billing, Platform, Team, User } from '@opteo/types'
import { showSnackbar, hideSnackbar, oButton } from '@opteo/components-next'
import { delay } from '@opteo/promise'

import { useUser } from '@/composition/user/useUser'
import { useAPI, Endpoint, authRequest } from '@/composition/api/useAPI'
import { useAccountList } from '@/composition/user/useAccountList'

import {
    LS_LINKED_ACCOUNTS_LAST_REFRESHED,
    LS_UNLINKED_LAST_VISITED,
    LS_UNLINKED_LAST_VISITED_UNMOUNT,
} from '@/lib/cookies'
import { API_URL } from '@/lib/env'
import { Routes } from '@/router/routes'

export type OpteoButton = typeof oButton

export function provideLinkedAccounts() {
    const { currentRoute, push } = useRouter()

    const platform = computed(() => {
        if (currentRoute.value.name === Routes.LinkedAccountsGoogle) {
            return Platform.Platform.GoogleAds
        }
        return Platform.Platform.MicrosoftAds
    })

    const queuedLinkedAccounts = ref<{ [accountId: Account.ID]: number }>({})
    const queuedUnlinkedAccounts = ref<{ [accountId: Account.ID]: number }>({})

    const { currentPlan, userId, mutateUserInfo, groupId, userInfo, profileImageUrl } = useUser()

    const linkedTableHeaders = [
        {
            key: 'name',
            text: 'Account Name',
            sortable: true,
            noPadding: true,
        },
        { key: 'spend30d', text: 'Spend/mo', sortable: true, width: 128, noPadding: true },
        {
            key: 'connectionStatus',
            text: 'Connection Status',
            sortable: true,
            width: 180,
            noPadding: true,
        },
        { key: 'availableConnections', text: 'Changelog Email', width: 200 },
        { key: 'teamMembers', text: 'Team Members', width: 174 },
    ]
    const unlinkedTableHeaders = [
        {
            key: 'name',
            text: 'Account Name',
            sortable: true,
            noPadding: true,
        },
        { key: 'spend30d', text: 'Spend/mo', sortable: true, width: 160, noPadding: true },
        {
            key: 'connectedToOpteoTs',
            text: 'Date Added',
            sortable: true,
            width: 148,
            noPadding: true,
        },
        { key: 'availableConnections', text: 'Changelog Email', width: 200 },
        { key: 'teamMembers', text: 'Team Members', width: 174 },
    ]

    const {
        data: teamAccounts,
        mutate: mutateLinkedAccounts,
        loading: teamAccountsLoading,
    } = useAPI<Api.GetTeamAccounts.Response>(Endpoint.GetTeamAccounts, {
        body: () => ({ teamId: groupId.value, platform: platform.value }),
        waitFor: () => groupId.value,
        uniqueId: () => groupId.value + ',' + platform.value,
    })

    const allAccounts = computed(
        () =>
            // sort team members so that the current user is always first
            teamAccounts.value?.accounts.map(a => {
                return {
                    ...a,
                    teamMembers: sortBy(a.teamMembers, u => u.userId !== userId.value),
                }
            }) ?? []
    )

    const teamMembers = computed(() =>
        // sort team members so that the current user is always first
        sortBy(teamAccounts.value?.team, u => u.userId !== userId.value)
    )

    const linkedAccounts = computed(() => allAccounts.value?.filter(account => account.active))
    const unlinkedAccounts = computed(() => allAccounts.value?.filter(account => !account.active))

    const linkedAccountsCount = computed(() => linkedAccounts.value.length ?? 0)

    const projectedLinkedAccountsCount = computed(
        () =>
            (linkedAccounts.value.length ?? 0) -
            Object.keys(queuedLinkedAccounts.value).length +
            Object.keys(queuedUnlinkedAccounts.value).length
    )

    const unlinkedAccountsCount = computed(() => unlinkedAccounts.value.length ?? 0)

    const accountsCountRemaining = computed(() => {
        if (platform.value === Platform.Platform.MicrosoftAds) return Infinity // MS is uncapped

        if (!currentPlan.value?.account_limit) return Infinity

        return Math.max(
            0,
            (currentPlan.value?.account_limit ?? 0) - (linkedAccountsCount.value ?? 0)
        )
    })

    const linkedAccounts30dSpend = computed(() => sumBy(linkedAccounts.value, 'spend30d'))

    const projectedLinkedAccounts30dSpend = computed(
        () =>
            sumBy(linkedAccounts.value, 'spend30d') -
            sum(Object.values(queuedLinkedAccounts.value)) +
            sum(Object.values(queuedUnlinkedAccounts.value))
    )

    const accounts30dSpendRemaining = computed(() => {
        if (platform.value === Platform.Platform.MicrosoftAds) return Infinity // MS is uncapped

        if (!currentPlan.value?.spend_limit) return Infinity
        return Math.max(
            0,
            (currentPlan.value?.spend_limit ?? 0) - (linkedAccounts30dSpend.value ?? 0)
        )
    })

    const currentView = ref('linked')
    function updateView(destination: string) {
        currentView.value = destination
    }

    const tabLinks = computed(() => [
        {
            key: 'linked',
            name: 'Linked',
            count: linkedAccountsCount.value,
        },
        {
            key: 'unlinked',
            name: 'Unlinked',
            count: unlinkedAccountsCount.value,
        },
    ])

    const linkingAccounts = computed(() => currentView.value === 'unlinked')

    const unlinkAccountsModalOpen = ref(false)

    const connectGoogleAds = async () => {
        const redirectUrl = `${document.location.origin}${currentRoute.value.fullPath}`

        const googleAdsUrl = await authRequest(Endpoint.GetGoogleAdsOauthUrl)

        if (!userId.value) {
            throw new Error('User ID not found')
        }

        const state: Api.OAuth.State = {
            userId: userId.value,
            returnUrl: redirectUrl,
            errorUrl: redirectUrl,
        }

        const connectUrl = new URL(googleAdsUrl)
        connectUrl.searchParams.append('state', JSON.stringify(state))

        window.location.href = connectUrl.toString()
    }

    const connectMicrosoftAds = async () => {
        const microsoftAdsUrl = await authRequest<string>(Endpoint.GetMicrosoftAdsOauthUrl)

        window.location.href = microsoftAdsUrl
    }

    // Over Limit Logic
    const planLimitWarning = ref(false)

    const overLimit = computed(() => {
        if (
            !userInfo.value ||
            !currentPlan.value ||
            !currentPlan.value.enforced ||
            platform.value === Platform.Platform.MicrosoftAds
        )
            return false

        const enterprisePlans = [
            Billing.StandardPlan.Enterprise,
            Billing.StandardPlan.YearlyEnterprise,
        ]

        const trialers = [
            Team.OpteoLifeCycleStage.TRIALER,
            Team.OpteoLifeCycleStage.UNLOCKED_TRIALER,
            Team.OpteoLifeCycleStage.RETRIALER,
        ]

        const isEnterpriseTrialer =
            enterprisePlans.includes(currentPlan.value.id as Billing.StandardPlan) &&
            trialers.includes(userInfo.value.opteo_lifecycle_stage)

        if (isEnterpriseTrialer) return false

        return (
            (currentPlan.value.account_limit &&
                projectedLinkedAccountsCount.value > currentPlan.value.account_limit) ||
            (currentPlan.value.spend_limit &&
                projectedLinkedAccounts30dSpend.value > currentPlan.value.spend_limit)
        )
    })

    // Linking/Unlinking API call and logic
    const { mutateDomainList } = useAccountList()
    const unlinkAccountsRef = ref<OpteoButton>()
    const linkAccountsRef = ref<OpteoButton>()

    const updatingLinkedAccounts = ref(false)

    async function updateLinkedAccountStatus(newStatus: boolean, onlyCurrentUser: boolean = false) {
        if (!newStatus) {
            updatingLinkedAccounts.value = true
        }
        const accountIds = Object.keys(
            newStatus ? queuedUnlinkedAccounts.value : queuedLinkedAccounts.value
        ) as Account.ID[]

        if (accountIds.length === 0 || !userId.value) {
            updatingLinkedAccounts.value = false
            return
        }

        if (overLimit.value && newStatus) {
            planLimitWarning.value = true

            updatingLinkedAccounts.value = false
            flashButton(linkAccountsRef, false)

            return
        }

        // When linking accounts, we show the assign team modal and return
        if (!overLimit.value && newStatus) {
            assignTeamModalOpen.value = true
            assignedTeamMembers.value = [userId.value]
            return
        }

        const buttonRef = newStatus ? linkAccountsRef : unlinkAccountsRef
        const userIds = onlyCurrentUser
            ? [userId.value]
            : teamMembers.value.map(member => member.userId)

        try {
            await authRequest(Endpoint.UpdateLinkedAccountStatus, {
                body: {
                    userIds,
                    accountIds,
                    newStatus,
                },
            })

            await mutateAllDomains()

            clearSelectedAccounts()

            if (!newStatus && unlinkAccountsModalOpen.value) {
                unlinkAccountsModalOpen.value = false
            }

            updatingLinkedAccounts.value = false
            flashButton(buttonRef, true)
        } catch (error) {
            updatingLinkedAccounts.value = false
            flashButton(buttonRef, false)
        }
    }

    async function updateLinkedAccountStatusWithTeamMembers() {
        updatingLinkedAccounts.value = true

        const accountIds = Object.keys(queuedUnlinkedAccounts.value) as Account.ID[]
        assignTeamModalOpen.value = false

        try {
            await authRequest(Endpoint.UpdateLinkedAccountStatus, {
                body: {
                    userIds: assignedTeamMembers.value,
                    accountIds,
                    newStatus: true,
                },
            })

            await mutateAllDomains()

            clearSelectedAccounts()

            updatingLinkedAccounts.value = false
            assignedTeamMembers.value = []
            flashButton(linkAccountsRef, true)
        } catch (error) {
            updatingLinkedAccounts.value = false
            assignedTeamMembers.value = []
            flashButton(linkAccountsRef, false)
        }
    }

    // Generic function to flash oButton success/error states
    function flashButton(buttonRef: Ref<OpteoButton | undefined>, success: boolean) {
        if (success) {
            buttonRef.value?.flashSuccess()
        } else {
            buttonRef.value?.flashError()
        }
    }

    const selectedLinkedAccountsCount = computed(
        () => Object.keys(queuedLinkedAccounts.value).length
    )
    const allLinkedAccountsSelected = computed(() => {
        if (filteredLinkedAccounts.value.length === 0) return false
        return filteredLinkedAccounts.value.length === selectedLinkedAccountsCount.value
    })
    const someLinkedAccountsSelected = computed(() => selectedLinkedAccountsCount.value > 0)

    const selectedUnlinkedAccountsCount = computed(
        () => Object.keys(queuedUnlinkedAccounts.value).length
    )
    const allUnlinkedAccountsSelected = computed(() => {
        if (filteredUnlinkedAccounts.value.length === 0) return false
        return filteredUnlinkedAccounts.value.length === selectedUnlinkedAccountsCount.value
    })
    const someUnlinkedAccountsSelected = computed(() => selectedUnlinkedAccountsCount.value > 0)

    function selectAllAccounts(linked: boolean) {
        if (linked) {
            const selected = !allLinkedAccountsSelected.value
            filteredLinkedAccounts.value.forEach(account => {
                selectAccount(account, linked, selected)
            })

            return
        }

        const selected = !allUnlinkedAccountsSelected.value

        filteredUnlinkedAccounts.value.forEach(account => {
            selectAccount(account, linked, selected)
        })
    }

    function selectAccount(account: Team.Account, linked: boolean, selected: boolean) {
        const accountId = account.accountId as Account.ID
        if (linked) {
            if (selected) {
                queuedLinkedAccounts.value[accountId] = account.spend30d

                return
            }
            delete queuedLinkedAccounts.value[accountId]
            return
        }

        if (selected) {
            queuedUnlinkedAccounts.value[accountId] = account.spend30d

            return
        }
        delete queuedUnlinkedAccounts.value[accountId]
    }

    const unlinkedVisitedTS = useLocalStorage<Date>(
        LS_UNLINKED_LAST_VISITED(platform.value),
        new Date('2010-01-01'), // Just a date in the past to make sure the storage serializer doesn't throw
        {
            serializer: StorageSerializers.date,
        }
    )

    const unlinkedVisitedUnmountTS = useLocalStorage<Date>(
        LS_UNLINKED_LAST_VISITED_UNMOUNT(platform.value),
        new Date('2010-01-01'),
        {
            serializer: StorageSerializers.date,
        }
    )

    const newUnlinkedAccounts = computed(() => {
        const newAccounts = unlinkedAccounts.value.find(account =>
            isBefore(unlinkedVisitedTS.value, new Date(account.connectedToOpteoTs))
        )

        return !!newAccounts
    })

    const unlinkedOrderBy = computed(() => {
        const newAccountsLastVisitedUnmount = unlinkedAccounts.value.find(account =>
            isBefore(unlinkedVisitedUnmountTS.value, new Date(account.connectedToOpteoTs))
        )
        return !!newAccountsLastVisitedUnmount ? 'connectedToOpteoTs' : 'spend30d'
    })

    const inititalLinkedAccountsCount = ref(allAccounts.value?.length)

    if (typeof inititalLinkedAccountsCount.value === 'undefined') {
        watch(allAccounts, () => {
            // Only update inititalLinkedAccountsCount.value the first time inititalLinkedAccountsCount changes
            if (typeof inititalLinkedAccountsCount.value === 'undefined') {
                inititalLinkedAccountsCount.value = allAccounts.value?.length
            }
        })
    }

    const currentUserIsConnectedToAnyQueuedAccounts = computed(() => {
        const accountIds = Object.keys(queuedLinkedAccounts.value) as Account.ID[]

        return allAccounts.value
            .filter(account => accountIds.includes(account.accountId))
            .some(
                account =>
                    account.teamMembers.find(member => member.userId === userId.value)?.active
            )
    })

    // Connect/Disconnect Single User from table cell dropdown
    async function updateCurrentUserLinkedStatus(accountId: Account.ID, isLinked: boolean) {
        await authRequest(Endpoint.UpdateLinkedAccountStatus, {
            body: {
                userIds: [userId.value],
                accountIds: [accountId],
                newStatus: isLinked,
            },
        })
        await mutateAllDomains()
    }

    const assignTeamMembersRef = ref()

    function clearSelectedAccounts() {
        queuedLinkedAccounts.value = {}
        queuedUnlinkedAccounts.value = {}
        planLimitWarning.value = false
    }

    // Search Functionality
    const searchedLinkedAccounts = ref('')
    const searchedUnlinkedAccounts = ref('')

    const filteredLinkedAccounts = computed(() => {
        if (!searchedLinkedAccounts.value) return linkedAccounts.value
        return linkedAccounts.value.filter(
            account =>
                account.name.toLowerCase().includes(searchedLinkedAccounts.value.toLowerCase()) ||
                account.platformId.toString().includes(searchedLinkedAccounts.value.toLowerCase())
        )
    })
    const filteredUnlinkedAccounts = computed(() => {
        if (!searchedUnlinkedAccounts.value) return unlinkedAccounts.value
        return unlinkedAccounts.value.filter(
            account =>
                account.name.toLowerCase().includes(searchedUnlinkedAccounts.value.toLowerCase()) ||
                account.platformId.toString().includes(searchedUnlinkedAccounts.value.toLowerCase())
        )
    })

    // Refresh data logic
    const lastRefresh = useLocalStorage<Date>(
        LS_LINKED_ACCOUNTS_LAST_REFRESHED(platform.value),
        new Date('2010-01-01'),
        {
            serializer: StorageSerializers.date,
        }
    )

    // Only refresh the data once every 2 hours
    const needsRefresh = computed(() => isBefore(lastRefresh.value, sub(new Date(), { hours: 2 })))

    const { isValidating: refreshingData } = useAPI(
        platform.value === Platform.Platform.GoogleAds
            ? Endpoint.SyncGoogleAccountsToDb
            : Endpoint.SyncMicrosoftAccountsToDb,
        {
            body: () => ({ teamId: groupId.value }),
            waitFor: () => needsRefresh.value && groupId.value,
            uniqueId: () => groupId.value,
        }
    )

    if (needsRefresh.value) {
        showSnackbar({
            message: `Fetching latest data from ${
                platform.value === Platform.Platform.GoogleAds ? 'Google' : 'Microsoft'
            } Ads..`,
            indefiniteTimeout: true,
        })

        watch(refreshingData, async () => {
            if (!refreshingData.value) {
                await mutateAllDomains()

                lastRefresh.value = new Date()

                hideSnackbar()
            }
        })
    }

    async function mutateAllDomains() {
        await Promise.all([mutateLinkedAccounts(), mutateUserInfo(), mutateDomainList()])
    }

    // Reset state on page change
    watch(currentView, () => {
        clearSelectedAccounts()
        searchedLinkedAccounts.value = ''
        searchedUnlinkedAccounts.value = ''
    })

    onBeforeUnmount(() => {
        if (needsRefresh.value) {
            hideSnackbar()
        }
    })

    function goToBillingCentre() {
        push({ name: Routes.BillingCentre })
    }

    // Assign Team Members Logic
    const assignTeamModalOpen = ref(false)

    const allTeamMembersAssigned = computed(
        () => assignedTeamMembers.value.length === teamMembers.value.length
    )

    const assignedTeamMembers = ref<User.ID[]>([])

    const searchedTeamMember = ref('')

    const filteredTeamMembers = computed(() => {
        return teamMembers.value.filter(user => {
            return (
                user.name.toLowerCase().includes(searchedTeamMember.value.toLowerCase()) ||
                user.email.toLowerCase().includes(searchedTeamMember.value.toLowerCase())
            )
        })
    })

    function assignTeamMember(userId: User.ID) {
        if (assignedTeamMembers.value.includes(userId)) {
            assignedTeamMembers.value = assignedTeamMembers.value.filter(id => id !== userId)
            return
        }
        assignedTeamMembers.value.push(userId)
    }

    function assignAllTeamMembers() {
        if (allTeamMembersAssigned.value) {
            assignedTeamMembers.value = []
            return
        }
        assignedTeamMembers.value = teamMembers.value.map(user => user.userId)
    }

    const addingTeamMembers = ref(false)
    const addTeamMembersButton = ref<OpteoButton>()

    const removingTeamMembers = ref(false)
    const removeTeamMembersButton = ref<OpteoButton>()

    async function assignTeamMembersToAccounts(newStatus: boolean) {
        const assigningTeamMembers = newStatus ? addingTeamMembers : removingTeamMembers
        const buttonRef = newStatus ? addTeamMembersButton : removeTeamMembersButton
        const accountIds = Object.keys(queuedLinkedAccounts.value) as Account.ID[]

        const userIds = assignedTeamMembers.value

        if (userIds.length === 0) {
            assignedTeamMembers.value = []
            return
        }

        assigningTeamMembers.value = true

        try {
            await authRequest(Endpoint.UpdateLinkedAccountStatus, {
                body: {
                    userIds,
                    accountIds,
                    newStatus,
                },
            })

            await mutateAllDomains()

            assigningTeamMembers.value = false
            flashButton(buttonRef, true)
            clearSelectedAccounts()
            await delay(1000)
            assignedTeamMembers.value = []
            assignTeamModalOpen.value = false

            showSnackbar({
                message: `Team members ${newStatus ? 'added' : 'removed'} successfully`,
                timeout: 4000,
                actionHandler: () => {},
            })
        } catch (error) {
            assigningTeamMembers.value = false

            flashButton(buttonRef, false)

            showSnackbar({
                message: `Something went wrong assigning team members`,
                timeout: 4000,
                actionText: 'Contact Support',
                actionHandler: () => {},
            })
        }
    }

    const toProvide = {
        linkedAccountsCount,
        unlinkedAccountsCount,
        linkedAccounts30dSpend,
        accountsCountRemaining,
        accounts30dSpendRemaining,
        linkedTableHeaders,
        unlinkedTableHeaders,
        currentPlan,
        connectGoogleAds,
        connectMicrosoftAds,
        formatDistanceToNowStrict,
        assignTeamModalOpen,
        teamMembers,
        linkingAccounts,
        updateLinkedAccountStatus,
        updateLinkedAccountStatusWithTeamMembers,
        updatingLinkedAccounts,
        allLinkedAccountsSelected,
        someLinkedAccountsSelected,
        allUnlinkedAccountsSelected,
        someUnlinkedAccountsSelected,
        selectedLinkedAccountsCount,
        selectedUnlinkedAccountsCount,
        selectAllAccounts,
        selectAccount,
        newUnlinkedAccounts,
        planLimitWarning,
        assignTeamMembersRef,
        unlinkAccountsRef,
        linkAccountsRef,
        queuedLinkedAccounts,
        queuedUnlinkedAccounts,
        clearSelectedAccounts,
        searchedLinkedAccounts,
        searchedUnlinkedAccounts,
        filteredLinkedAccounts,
        filteredUnlinkedAccounts,
        flashButton,
        overLimit,
        goToBillingCentre,
        projectedLinkedAccountsCount,
        projectedLinkedAccounts30dSpend,
        teamAccountsLoading,
        allTeamMembersAssigned,
        assignedTeamMembers,
        searchedTeamMember,
        filteredTeamMembers,
        assignTeamMember,
        assignAllTeamMembers,
        removeTeamMembersButton,
        addTeamMembersButton,
        mutateLinkedAccounts,
        teamAccounts,
        unlinkedVisitedTS,
        unlinkedVisitedUnmountTS,
        unlinkedOrderBy,
        unlinkAccountsModalOpen,
        mutateAllDomains,
        removingTeamMembers,
        addingTeamMembers,
        assignTeamMembersToAccounts,
        currentUserIsConnectedToAnyQueuedAccounts,
        updateCurrentUserLinkedStatus,
        currentView,
        updateView,
        tabLinks,
        platform,
        userId,
        userInfo,
        profileImageUrl,
    }

    provide('LinkedAccounts', toProvide)

    return toProvide
}

export function useLinkedAccounts() {
    const injected = inject<ReturnType<typeof provideLinkedAccounts>>('LinkedAccounts')

    if (!injected) {
        throw new Error(
            `LinkedAccounts not yet injected, something is wrong. useLinkedAccounts() can only be called in a /linkedaccounts/ route.`
        )
    }

    return injected
}
