<template>
    <CampaignGroupSection>
        <template #header-left>
            <Popout
                v-model="nameUpdateModalShown"
                :trapFocus="true"
                :initialFocus="true"
                :externalToggleButton="true"
                :offset="[-26, 16]"
                :zIndex="9999999999"
                max-width="24.25rem"
                placement="bottom-start"
                :on-click-outside="
                    () => {
                        newName = group.name
                    }
                "
            >
                <Text as="h4" weight="600">{{ group.name }}</Text>
                <template v-slot:content>
                    <div class="campaign-group-edit-popout">
                        <oForm @submitted="renameGroup" ref="renameGroupForm">
                            <oInput
                                id="update-name"
                                name="newName"
                                :validator="nameIsAlreadyTaken"
                                v-model="newName"
                                :max-characters="25"
                                ref="renameGroupInput"
                                type="text"
                                label="Campaign Group Name"
                                required
                                style="width: 16.25rem"
                                autofocus
                            />
                            <Spacer height="28px" />
                            <oButton style="width: 100%" type="submit">Save Changes</oButton>
                        </oForm>
                        <Spacer height="0.75rem" />
                        <Tooltip :content="tooltipContent" placement="top" :offset="[0, 18]">
                            <oButton
                                :disabled="!!tooltipContent"
                                style="width: 100%"
                                color="red"
                                @clicked="deleteGroup(group.id)"
                            >
                                Delete Campaign Group
                            </oButton>
                        </Tooltip>
                    </div>
                </template>
            </Popout>
            <Spacer width="0.75rem" />
            <oButton
                v-if="
                    group &&
                    group.campaignGroupType !== CampaignGroupType.BRAND &&
                    group.campaignGroupType != CampaignGroupType.EXCLUDED
                "
                color="white"
                :circle="true"
                size="x-small"
                @clicked="nameUpdateModalShown = !nameUpdateModalShown"
                style="transform: translateY(1px)"
            >
                <TableEdit style="transform: translateY(2px)" />
            </oButton>
        </template>
        <template v-slot:header-right>
            <!-- Target CPA/ROAS/Profit -->
            <button
                v-if="group.campaignGroupType !== CampaignGroupType.EXCLUDED"
                class="group-panel-button"
                @click="handleShowTargetPanel"
                style="width: 100%"
            >
                <Label class="label" noMargin>Target {{ isUsingCpa ? 'CPA' : 'ROAS' }}</Label>
                <Text as="h6">
                    <Money v-if="isUsingCpa" :value="group.performanceTarget" />
                    <!-- <span v-if="isUsingCpa">{{ currencySymbol }}{{ customTarget }}</span> -->
                    <Roas v-else :value="group.performanceTarget" />
                </Text>
                <div class="icon">
                    <TableEdit />
                </div>
            </button>
            <!-- Target Side Panel -->
            <Panel
                v-if="group.campaignGroupType !== CampaignGroupType.EXCLUDED"
                :modelValue="showTargetPanel"
                @close="showTargetPanel = false"
                :title="group.name"
                :width="820"
            >
                <template #title>
                    <!-- <div class="panel-subtitle">Target {{ isUsingCpa ? 'CPA' : 'ROAS' }}</div> -->
                    <ColorTag v-if="isUsingCpa" title="Target CPA" />
                    <ColorTag v-else title="Target ROAS" />
                </template>
                <template #content>
                    <OnboardingModal v-model="panelDescriptions" expandable :visible-lines="3">
                        <template #copy>
                            By default, campaign groups in Opteo are set to use
                            <b>Automatic Targeting</b>. Using your 90 Day Average
                            {{ isUsingCpa ? 'CPA' : 'ROAS' }} as a baseline, Opteo continuously
                            adjusts, optimises and improves performance over time. If you need more
                            control over your campaign group targets, you can set a custom target
                            using the form below.
                            <Spacer height="1rem" />
                            Performance targets help Opteo understand what a profitable conversion
                            looks like. If your target represents a profitable conversion, your
                            Opteo recommendations will
                            <b>help you generate profitable conversions</b>. Please ensure your
                            targets are reasonable, otherwise Opteo may produce some unexpected
                            recommendations.
                        </template>
                    </OnboardingModal>
                    <Spacer width="1.5rem" />
                    <div class="target-form">
                        <div class="wrapper">
                            <Label>
                                Target {{ isUsingCpa ? 'CPA' : 'ROAS' }} for {{ group.name }}
                            </Label>
                            <!-- 
                            Using two inputs here (as opposed to dynamic disabled/required)
                            simplifies some tricky validation behaviours.  
                        -->
                            <!-- Custom Target -->
                            <div v-if="autoTarget" class="target-mock-input">
                                <div v-if="isUsingCpa" class="prefix">
                                    {{ currencySymbol }}
                                </div>
                                <!-- .toFixed(2) is a temporary fix -->
                                {{ customTarget }}
                                <div v-if="!isUsingCpa" class="suffix">%</div>
                            </div>
                            <oInput
                                v-else
                                required
                                v-model="customTarget"
                                name="customTarget"
                                type="number"
                                :prefix="isUsingCpa ? currencySymbol : ''"
                                :suffix="isUsingCpa ? '' : '%'"
                                min="1"
                            />
                            <!-- Spacer -->
                            <Spacer height="0.75rem" />
                            <!-- Explainer -->
                            <Text as="p" size="f-9" color="gray" style="line-height: 1.25rem">
                                Please ensure your target is within a reasonable range, otherwise
                                Opteo may produce some unexpected recommendations.
                            </Text>
                            <!-- Spacer -->
                            <Spacer height="1rem" />
                            <!-- Auto Targeting -->
                            <oInput
                                v-model="autoTarget"
                                :button-mode="true"
                                type="checkbox"
                                name="autoTarget"
                                label="Use Automatic Targeting"
                            />
                        </div>
                    </div>
                </template>
                <template #footer>
                    <oButton size="extra-large" color="dark-blue" @clicked="updateTarget">
                        Save Target {{ isUsingCpa ? 'CPA' : 'ROAS' }}
                        <template v-slot:icon>
                            <svg
                                width="12"
                                height="12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M1 11 11 1M11 1H3M11 1v8"
                                    stroke="#fff"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                />
                            </svg>
                        </template>
                    </oButton>
                </template>
            </Panel>
            <Spacer v-if="group.name != 'Excluded Campaigns'" width="0.75rem" />
            <!-- Conversion Actions -->
            <button
                v-if="group.campaignGroupType !== CampaignGroupType.EXCLUDED"
                class="group-panel-button"
                @click="handleShowConversionActionPanel"
                style="width: 100%"
            >
                <Label class="label" noMargin>Conversion Actions</Label>
                <Text as="h6"> {{ enabledConversionsCount }} Selected </Text>
                <div class="icon">
                    <TableEdit />
                </div>
            </button>
            <!-- Conversion Actions Side Panel -->
            <Panel
                v-if="group.campaignGroupType !== CampaignGroupType.EXCLUDED"
                :modelValue="showConversionActionPanel"
                @close="showConversionActionPanel = false"
                :title="group.name"
                :width="820"
            >
                <template #title>
                    <!-- <div class="panel-subtitle">Conversion Actions</div> -->
                    <ColorTag title="Conversion Actions" />
                </template>
                <template #content>
                    <OnboardingModal v-model="panelDescriptions" expandable :visible-lines="3">
                        <template #copy>
                            <b>Select your primary conversion actions.</b> Primary conversion
                            actions represent the most important outcomes for your business and are
                            usually directly responsible for revenue. Opteo will optimise towards
                            the conversion actions selected below, attempting to generate more of
                            these conversions at a below-target rate.
                            <Spacer height="1rem" />
                            Secondary conversion actions represent goals for your business that are
                            <b>not</b> directly responsible for revenue. An example of a secondary
                            conversion action might be a website visitor adding their email address
                            to your marketing list. If your campaigns intend to generate email
                            signups, you may want to select these actions.
                        </template>
                    </OnboardingModal>
                    <Spacer height="2rem" />
                    <oTable
                        :headers="conversionActionTableHeaders"
                        :items="conversionActionTableItems"
                        :border-radius="20"
                        fixed-layout
                    >
                        <template v-slot:header.action>
                            <oInput
                                type="checkbox"
                                label="Conversion Action"
                                :modelValue="allConversionsChecked"
                                @click="toggleAllConversions"
                                class="header-checkbox"
                            />
                        </template>
                        <template v-slot:column.action="cellData">
                            <div class="table-cell">
                                <!-- <Tooltip
                                    placement="top-start"
                                    :offset="[44, -4]"
                                    :max-width="1200"
                                    :content="cellData.value?.length > 50 ? cellData.value : ''"
                                >
                                    <oInput
                                        type="checkbox"
                                        :label="cellData.value"
                                        :name="`checkbox${cellData.row.actionId}`"
                                        :modelValue="cellData.row.enabled"
                                        @updated="
                                            updateConversionAction(cellData.row.actionId, $event)
                                        "
                                        class="conv-actions-checkbox"
                                    />
                                </Tooltip> -->
                                <Tooltip
                                    placement="top-start"
                                    :offset="[94, -20]"
                                    :delay="[720, 0]"
                                    :max-width="1200"
                                    :content="cellData.value?.length > 44 ? cellData.value : ''"
                                >
                                    <oInput
                                        type="checkbox"
                                        :name="`checkbox${cellData.row.actionId}`"
                                        :modelValue="cellData.row.enabled"
                                        @updated="
                                            updateConversionAction(cellData.row.actionId, $event)
                                        "
                                        class="conv-actions-checkbox"
                                    />
                                    <Spacer width="2.625rem" />
                                    <EntityPill
                                        type="conversion-action"
                                        :tooltip="false"
                                        :content="cellData.value"
                                    />
                                </Tooltip>
                            </div>
                        </template>
                        <template v-slot:column.conversions="cellData">
                            <Number :value="Math.round(cellData.value ?? 0)" />
                        </template>
                        <template v-slot:column.conversionsValue="cellData">
                            <Money :value="cellData.value ?? 0" />
                        </template>
                    </oTable>
                </template>
                <template #footer>
                    <oButton size="extra-large" color="dark-blue" @clicked="saveConversionChanges">
                        Save Conversion Actions
                        <template v-slot:icon>
                            <svg
                                width="12"
                                height="12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M1 11 11 1M11 1H3M11 1v8"
                                    stroke="#fff"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                />
                            </svg>
                        </template>
                    </oButton>
                </template>
            </Panel>
            <!-- Excluded Campaigns -->
            <div v-else class="explainer-text">
                <HelpIcon style="flex-shrink: 0" />
                <Text as="span" size="f-8">
                    Campaigns in this group will be excluded from Opteo and no longer generate
                    improvements.
                </Text>
            </div>
        </template>

        <template v-slot:content>
            <div v-if="group.campaigns.length > 0">
                <oTable
                    :headers="campaignTableHeaders"
                    :items="filteredCampaigns"
                    :border-radius="20"
                    order-by="cost"
                    order="DESC"
                    fixed-layout
                    no-shadow
                    :per-page="6"
                >
                    <!-- Campaign header -->
                    <template v-slot:header.campaignName>
                        <oInput
                            v-if="filteredCampaigns.length"
                            type="checkbox"
                            label="Campaigns"
                            :modelValue="allCampaignsSelected"
                            @click="toggleAllCampaigns"
                            class="pl-24"
                        />
                        <oInput v-else type="checkbox" label="Campaigns" class="pl-24" disabled />
                        <Spacer width="1rem" />
                        <oInput
                            ref="searchInput"
                            v-model="campaignSearch"
                            type="text"
                            class="campaign-search-input"
                            name="campaignSearchInput"
                            autocomplete="off"
                        />
                    </template>

                    <!-- Campaign column -->
                    <template v-slot:column.campaignName="cellData">
                        <div class="table-cell">
                            <Tooltip
                                placement="top-start"
                                :offset="[56, -4]"
                                :max-width="1200"
                                :delay="[800, 0]"
                                :content="
                                    cellData.row.campaignName?.length > 36
                                        ? cellData.row.campaignName
                                        : ''
                                "
                            >
                                <oInput
                                    type="checkbox"
                                    :name="`checkbox${cellData.row.campaignId}`"
                                    class="campaigns-campaign-checkbox"
                                    :modelValue="isCampaignSelected(cellData.row.campaignId)"
                                    @updated="toggleCampaign(cellData.row, $event)"
                                />
                                <EntityPill
                                    type="campaign"
                                    :content="cellData.row.campaignName"
                                    class="table-entity"
                                />
                            </Tooltip>
                        </div>
                    </template>
                    <!-- Type column -->
                    <template v-slot:column.campaignType="cellData">
                        <span v-if="cellData.value === 'Performance Max'">Per. Max</span>
                        <span v-else-if="cellData.value === 'Multi Channel'">Multi Chan.</span>
                        <span v-else>{{ cellData.value }}</span>
                    </template>
                    <!-- Cost column -->
                    <template v-slot:column.cost="cellData">
                        <Money :value="cellData.value" />
                    </template>
                    <!-- Conversions column -->
                    <template v-slot:column.conversions="cellData">
                        <Number :value="Math.round(cellData.value)" />
                    </template>
                    <!-- Value column -->
                    <template v-slot:column.conversionsValue="cellData">
                        <Money :value="cellData.value" />
                    </template>
                    <template v-slot:column.campaignCpa="cellData">
                        <Money :value="cellData.value" />
                    </template>
                    <template v-slot:column.campaignRoas="cellData">
                        <Roas :value="cellData.value" />
                    </template>
                </oTable>
            </div>
            <div v-else class="no-campaigns-in-group">
                <Text as="p" size="f-8">There are no campaigns in this campaign group.</Text>
            </div>
            <div
                v-if="!filteredCampaigns.length && group.campaigns.length"
                class="no-campaigns-in-group"
            >
                <Text as="p" size="f-8">
                    There are no campaigns in this campaign group that match your search query.
                </Text>
            </div>
        </template>
    </CampaignGroupSection>
</template>

<script lang="ts">
import { PropType, defineComponent, ref } from 'vue'
import CampaignGroupSection from './CampaignGroupSection.vue'

import {
    oButton,
    Spacer,
    oInput,
    oForm,
    Number,
    Text,
    Label,
    ColorTag,
    Popout,
    oTable,
    SelectArrowIcon,
    Tooltip,
    Percent,
    TableEdit,
    Roas,
    EntityPill,
    TopRightArrow,
    Panel,
    OnboardingModal,
    HelpIcon,
} from '@opteo/components-next'

import Money from '@/components/global/oMoney.vue'
import { Targets } from '@opteo/types'
import { useCampaignGroup } from '@/composition/campaignGroups/useCampaignGroup'

export default defineComponent({
    name: 'CampaignGroup',
    components: {
        oButton,
        Spacer,
        Money,
        oInput,
        oForm,
        Number,
        Text,
        Label,
        ColorTag,
        Popout,
        oTable,
        SelectArrowIcon,
        Tooltip,
        Percent,
        TableEdit,
        Roas,
        EntityPill,
        TopRightArrow,
        Panel,
        OnboardingModal,
        HelpIcon,
        CampaignGroupSection,
    },
    props: {
        group: {
            type: Object as PropType<Targets.CampaignGroup>,
            required: true,
        },
    },
    setup(props) {
        const panelDescriptions = ref(true)

        return {
            panelDescriptions,
            CampaignGroupType: Targets.CampaignGroupType,
            ...useCampaignGroup(props),
        }
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.campaigns-campaign-checkbox {
    @include pl-24;
    height: 64px;
    @include flex;
    @include items-center;
    width: 100%;
    @include relative;
    @include z-2;
}
.conv-actions-checkbox {
    @include pl-24;
    height: 64px;
    @include flex;
    @include items-center;
}
.campaigns-campaign-checkbox::after,
.conv-actions-checkbox::after {
    content: '';
    background: white;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 62.5%);
    position: absolute;
    top: 0;
    right: -1.5rem;
    height: 100%;
    width: 6rem;
    z-index: 2;
}
.conv-actions-checkbox::after {
    right: 0;
}
thead .o-input--checkbox {
    @include flex;
}

:deep(.o-table-wrapper),
:deep(.o-table-container) {
    @include br-16;
}

.campaign-search-input {
    max-width: 12.5rem;
    @include relative;
}

.campaign-search-input :deep(input) {
    padding: 10px 14px 10px 38px;
}
.campaign-search-input::before {
    content: '';
    position: absolute;
    left: 14px;
    top: 0;
    bottom: 0;
    width: 12px;
    background: url('@/assets/img/searchIcon.svg') center / contain no-repeat;
}

.table-cell {
    overflow: hidden;
    cursor: pointer;
}
.table-cell .table-entity {
    @include absolute;
    left: 3.5rem;
}
.table-cell :deep(.tooltip) {
    @include flex;
    @include items-center;
    position: relative;
    gap: 0.875rem;
    height: 4.5rem;
}
.table-cell :deep(.o-input__label) {
    cursor: pointer;
}
.table-cell :deep(.o-input--checkbox),
.table-cell :deep(.o-input__checkbox) {
    height: 4.5rem;
    // checkbox + padding
    width: 100%;
    flex-shrink: 0;
}

.target-cpa-popout {
    @include ph-24;
    @include pb-24;
    @include pt-20;
}
.target-form {
    @include container;
    @include pv-64;
    @include br-20;
    @include flex-center;
}
.target-form .wrapper {
    max-width: 24rem;
}

.no-campaigns-in-group {
    @include pa-64;
    @include flex-center;
}
.no-campaigns-in-group p {
    color: rgba(0, 0, 0, 0.24);
}

.campaign-group-edit-popout {
    @include pa-28;
}
.campaign-group-edit-popout .divider {
    width: 100%;
    height: 1px;
    background: $opteo-light-gray;
}

.date-stamp {
    transform: translateY(-16px);
}

.panel-subtitle {
    padding: 0 0.875rem;
    height: 2.375rem;
    @include flex-center;
    font-size: 0.8125rem;
    letter-spacing: -0.0075rem;
    border: 1px solid rgba(2, 2, 12, 0.04);
    border-radius: 0.625rem;
}

.target-mock-input {
    height: 2.75rem;
    @include f-8;
    @include container;
    border-radius: 0.875rem;
    @include flex;
    @include items-center;
    padding: 0 0.875rem;
    gap: 0.875rem;
    color: rgba(0, 0, 0, 0.24);
    cursor: default;
    user-select: none;
    @include relative;
}
.target-mock-input .prefix {
    border-right: 1px solid $opteo-light-gray;
    @include flex-center;
    padding-right: 0.875rem;
    height: 100%;
}
.target-mock-input .suffix {
    border-left: 1px solid $opteo-light-gray;
    @include flex-center;
    padding: 0 0.875rem 0 0.875rem;
    height: 100%;
    position: absolute;
    right: 0;
}

// Group Panel Button
.group-panel-button {
    @include container;
    @include bg-opteo-white;
    border-radius: 0.875rem;
    outline: none;
    border: none;
    @include flex-center;
    height: 2.75rem;
    padding: 0 0.9375rem;
    transition:
        transform 0.25s cubic-bezier(0.19, 1, 0.22, 1),
        background 0.25s cubic-bezier(0.19, 1, 0.22, 1),
        box-shadow 0.25s cubic-bezier(0.19, 1, 0.22, 1),
        opacity 0.25s cubic-bezier(0.19, 1, 0.22, 1),
        color 0.25s cubic-bezier(0.19, 1, 0.22, 1);
    cursor: pointer;
}
.group-panel-button:focus {
    outline: none;
    box-shadow: $opteo-shadow-focus;
}

.group-panel-button:active {
    outline: none;
    box-shadow: $opteo-shadow-focus;
    transform: translate3d(0, 1px, 0);
}
.group-panel-button h6 {
    font-size: 0.9375rem;
    font-weight: 600;
    line-height: 1.5rem;
    letter-spacing: -0.0175rem;
    transform: translateY(-1px);
    margin-left: 0.625rem;
    margin-right: 0.75rem;
    white-space: nowrap;
}
.group-panel-button .icon {
    @include container;
    width: 1.5rem;
    height: 1.5rem;
    @include br-999;
    @include flex-center;
}

.conv-actions-checkbox {
    @include pl-24;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    @include absolute;
    @include flex;
    @include items-center;
    @include z-9;
}

.conv-actions-checkbox :deep(.o-input--checkbox),
.conv-actions-checkbox :deep(.o-input__checkbox) {
    width: 100%;
    height: 100%;
}

.conv-actions-checkbox::after {
    content: '';
    background: white;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 62.5%);
    position: absolute;
    top: 0;
    right: -1.5rem;
    height: 100%;
    width: 6rem;
    z-index: 2;
    right: 0;
}

.header-checkbox {
    height: 1.125rem;
    @include pl-24;
}
.header-checkbox :deep(.o-input__text) {
    margin-left: 0.875rem;
}

// Explainer Text
.explainer-text {
    background: $opteo-dark-blue-translucent;
    max-width: 23.375rem;
    @include flex;
    @include items-center;
    line-height: 1.25rem;
    @include ph-20;
    @include pv-16;
    @include br-20;
    gap: 0.9375rem;
}
.explainer-text span {
    color: $opteo-dark-blue;
}
</style>
