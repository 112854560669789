<template>
    <div
        ref="campaignsTableWrapper"
        tabindex="-1"
        class="campaigns-table-wrapper popout"
        :style="{ minWidth: popoutWidth }"
    >
        <oTable
            :headers="campaignTableHeaders"
            :items="campaignTableItems"
            order-by="cost"
            order="DESC"
            paginationMethod="intersection"
            :perPage="5"
            :border-radius="24"
            class="campaigns-table"
            :maxHeight="384"
            :no-shadow="true"
        >
            <template v-if="searchQuery && campaignTableItems.length === 0" #empty-state>
                <div class="campaigns-empty-state-query-container">
                    <Text as="h2" size="f-2">
                        {{ searchQuery }}
                    </Text>

                    <Text size="f-8" align="center">No campaigns match search query.</Text>
                </div>
            </template>
            <template v-if="!searchQuery && campaignTableItems.length === 0" #empty-state>
                <div class="campaigns-empty-state-container">
                    <Text size="f-8" align="center">There are no campaigns.</Text>
                </div>
            </template>
            <template #header.campaignName>
                <Spacer width="1.875rem" />
                <Tooltip content="Select All" placement="top">
                    <Checkbox @click="selectAllCampaigns" :model-value="allCampaignsSelected" />
                </Tooltip>
                <Spacer width="1.25rem" />
                <Text size="f-8" weight="500">Campaign</Text>
                <Spacer width="1.25rem" />
                <oInput ref="campaignSearchInput" class="search-input" v-model="searchQuery" />
            </template>

            <template #column.campaignName="{ value, row }">
                <!-- add click to the table cell for a larger clickable area -->
                <div class="campaign-cell" @click="toggleCampaign(row.campaignId)">
                    <Spacer width="1.875rem" />
                    <div v-if="addingNgramsToNegative" class="spinner-container">
                        <Spinner />
                    </div>
                    <Checkbox v-else :model-value="!excludedCampaigns?.includes(row.campaignId)" />
                    <Spacer width="1.25rem" />
                    <EntityPill :type="Improvement.LocationEntity.Campaign" :content="value" />
                </div>
            </template>
            <template #column.cost="{ value }">
                <Money :value="value" />
            </template>
            <template #column.conversions="{ value }">
                <Number :value="value" />
            </template>
            <template #column.conversionValue="{ value }">
                <Money :value="value" />
            </template>
            <template #column.cpa="{ value }">
                <Money :value="value" />
            </template>
            <template #column.roas="{ value }">
                <Percent :value="value" />
            </template>
            <template #column.campaignGroupName="{ value }">
                <div class="campaign-name">
                    <EntityPill :type="Improvement.LocationEntity.CampaignGroup" :content="value" />
                </div>
            </template>
        </oTable>
    </div>
</template>

<script setup lang="ts">
import { Ref, onMounted, ref, nextTick, onUnmounted } from 'vue'
import { Improvement } from '@opteo/types'
import { useNGramTool } from '@/composition/toolkit/nGramTool/useNGramTool'
import {
    oTable,
    EntityPill,
    Spacer,
    Checkbox,
    Text,
    Tooltip,
    oInput,
    Spinner,
    Money,
    Number,
    Percent,
    HelpIcon,
    useShortKey,
} from '@opteo/components-next'
import { useFocusTrap } from '@vueuse/integrations/useFocusTrap'
import { onClickOutside } from '@vueuse/core'
import { useNGramFilters } from '@/composition/toolkit/nGramTool/useNGramFilters'

const props = defineProps<{
    buttonRef: Ref<HTMLElement>
    popoutWidth: any
}>()

const emit = defineEmits(['clickOutside'])

const {
    searchQuery,
    allCampaignsSelected,
    selectAllCampaigns,
    toggleCampaign,
    applyCampaignSelection,
    campaignTableHeaders,
    campaignTableItems,
    addingNgramsToNegative,
} = useNGramTool()

const { excludedCampaigns } = useNGramFilters()

const campaignsTableWrapper = ref<HTMLDivElement>()
const campaignSearchInput = ref()

const { activate: activateFocus, deactivate: deactivateFocus } = useFocusTrap(
    campaignsTableWrapper,
    {
        initialFocus: false,
        allowOutsideClick: true,
        setReturnFocus: () => {
            return props.buttonRef.elementRef
        },
    }
)

onClickOutside(campaignsTableWrapper, event => {
    if ((event.target as HTMLElement).closest('.campaigns-table-button')) return
    emit('clickOutside', event)
})

useShortKey({
    keys: ['esc'],
    onPressCallBack: event => {
        emit('clickOutside', event)
    },
})

onMounted(async () => {
    await nextTick()
    campaignSearchInput.value?.inputRef.focus()
    document.body.classList.add('no-scroll')
    activateFocus()
})

onUnmounted(() => {
    applyCampaignSelection()

    searchQuery.value = ''
    document.body.classList.remove('no-scroll')
    deactivateFocus()
})
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.campaigns-table-wrapper {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -0.5rem);
    z-index: 99999;
    box-shadow: $opteo-shadow-xl;
    @include br-24;
}

:deep(.campaigns-table th) {
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 99;
    // as we have border-collapse: collapse; set on our oTables we have to define bottom border using box-shadow
    box-shadow:
        inset 0 0px 0 hsl(240, 20%, 97%),
        inset 0 -1px 0 hsl(240, 20%, 97%);
}

.search-input {
    max-width: 12.5rem;
    @include relative;
}
.search-input :deep(input) {
    padding: 10px 14px 10px 38px;
}
.search-input::before {
    content: '';
    position: absolute;
    left: 14px;
    top: 0;
    bottom: 0;
    width: 16px;
    background: url('@/assets/img/icons/search-icon.svg') center / contain no-repeat;
}

.campaigns-empty-state-query-container {
    @include br-24;
    @include flex-center;
    flex-direction: column;
    gap: 1.5rem;
    width: 100%;
    padding: 6rem 0;
}
.campaigns-empty-state-container {
    @include br-24;
    @include flex-center;
    flex-direction: column;
    gap: 1.5rem;
    width: 100%;
    padding: 7rem 0;
}

.campaigns-empty-state-text {
    @include f-8;
    @include lh-150;
    @include tc;
    width: 26rem;
}

.campaign-cell {
    display: flex;
    align-items: center;
    cursor: pointer;
    overflow: hidden;
    position: relative;
}
.campaign-cell::after {
    content: '';
    background: linear-gradient(270deg, #fff 1.5rem, rgba(255, 255, 255, 0) 100%);
    @include absolute;
    top: 0;
    right: -1rem;
    width: 6rem;
    bottom: 0;
}

:deep(.campaign-name) {
    overflow: hidden;
    position: relative;
}
:deep(.campaign-name::after) {
    content: '';
    background: linear-gradient(270deg, #fff 1.5rem, rgba(255, 255, 255, 0) 100%);
    @include absolute;
    top: 0;
    right: -2rem;
    width: 4rem;
    bottom: 0;
}
</style>
