<template>
    <PageHeader page-title="Improvements" :no-margin="!aboveMobile">
        <!-- Header Middle -->
        <template #middle-content>
            <!-- Tabs -->
            <Tabs :links="pageSelectorLinks">
                <!-- Active -->
                <template #active>
                    <div class="improvements-header-tab">
                        Active
                        <span
                            :class="[
                                (accountInfo?.tasksAvailable ?? 0) > 99 ? 'wide' : '',
                                'improvement-tab-count mobile-hide',
                            ]"
                        >
                            {{ accountInfo?.tasksAvailable ?? 0 }}
                        </span>
                    </div>
                </template>
                <!-- Completed -->
                <template #completed>
                    <div class="improvements-header-tab">
                        Completed
                        <span
                            :class="[
                                (accountInfo?.tasksCompleted ?? 0) > 99 ? 'wide' : '',
                                'improvement-tab-count mobile-hide',
                            ]"
                        >
                            {{ accountInfo?.tasksCompleted ?? 0 }}
                        </span>
                    </div>
                </template>
                <!-- Dismissed -->
                <template #dismissed>
                    <div class="improvements-header-tab">
                        Dismissed
                        <span
                            :class="[
                                (accountInfo?.tasksDismissed ?? 0) > 99 ? 'wide' : '',
                                'improvement-tab-count mobile-hide',
                            ]"
                        >
                            {{ accountInfo?.tasksDismissed ?? 0 }}
                        </span>
                    </div>
                </template>
            </Tabs>
        </template>
        <!-- Header Right -->
        <template v-slot:right-content>
            <router-link :to="{ name: Routes.CampaignGroups }">
                <oButton color="white">
                    Campaign Groups
                    <transition name="bubble-fade">
                        <WarnIcon
                            class="campaign-groups-onboarding-bubble"
                            v-if="campaignGroupsOnboardingVisible"
                        />
                    </transition>
                </oButton>
            </router-link>
            <Spacer width="0.75rem" />
        </template>

        <template #end-content>
            <ExpandableSidebarButton @clicked="improvementsSidebar = !improvementsSidebar" />
        </template>
    </PageHeader>
    <!-- Sidebar -->
    <ExpandableSidebar v-model="improvementsSidebar" title="Improvements">
        <!-- Sort Improvements By -->
        <div class="sort-improvements-by">
            <Label>Sort Improvements By</Label>
            <oInput
                :modelValue="improvementSort"
                :button-mode="true"
                @updated="updateImprovementSort"
                :items="[
                    {
                        value: 'priority',
                        label: `Priority <span style='opacity: 0.3; margin-left: 0.25rem'>Sort Improvements by priority.</span>`,
                    },
                    {
                        value: 'type',
                        label: `Type <span style='opacity: 0.3; margin-left: 0.25rem'>Organise Improvements by type.</span>`,
                    },
                    {
                        value: 'created',
                        label: `Date <span style='opacity: 0.3; margin-left: 0.25rem'>Show recent Improvements first.</span>`,
                    },
                    {
                        value: 'batch_only',
                        label: `Batchable <span style='opacity: 0.3; margin-left: 0.25rem'>Can be pushed via queue.</span>`,
                    },
                ]"
                layout="vertical"
                name="radio"
                type="radio"
            />
        </div>
        <Spacer height="2rem" />
        <!-- Active Improvement Types -->
        <div class="active-improvement-types">
            <Label>Active Improvement Types</Label>
            <div v-for="pref in recActionPreferences" :key="pref.static_title">
                <oInput
                    type="checkbox"
                    :button-mode="true"
                    :label="pref.static_title"
                    v-model="pref.selected"
                    @updated="savePreferences"
                    class="w-100"
                />
                <Spacer height="0.625rem" />
            </div>
        </div>
    </ExpandableSidebar>

    <!-- Improvements onboarding -->
    <OnboardingTour
        step-name="tour_inbox"
        :buttonText="'Continue to Improvements'"
        contentMaxWidth="512px"
    >
        <template #header>Improvements</template>
        <template #copy>
            Improvements are smart recommendations that can be pushed live to Google Ads in seconds.
            Open an improvement, review the changes, and then click the green button to push live.
            You can also push batches of improvements using the icons to the left of each
            improvement row.
        </template>
    </OnboardingTour>

    <DismissFeedbackModal />

    <router-view></router-view>
</template>

<script lang="ts">
import { computed, defineComponent, ref, provide } from 'vue'
import {
    oButton,
    Tabs,
    oInput,
    Label,
    Spacer,
    ExpandableSidebarButton,
    ExpandableSidebar,
    Modal,
    WarnIcon,
    Text,
} from '@opteo/components-next'
import OnboardingTour from '@/components/User/OnboardingTour.vue'

import { useImprovementPreferences } from '@/composition/improvement/useImprovementPreferences'
import { useDomain } from '@/composition/domain/useDomain'

import PageHeader from '@/layouts/PageHeader.vue'
import PageContent from '@/layouts/PageContent.vue'
import FullScreenOverlay from '@/components/util/FullScreenOverlay.vue'

import { useRouter } from 'vue-router'

import { provideBatchBox } from '@/composition/improvement/useBatchBox'
import useMediaQuery from '@/composition/global/useMediaQuery'
import DismissFeedbackModal from '@/components/improvement/DismissFeedbackModal.vue'
import { Routes } from '@/router/routes'
import { useOnboardingTour } from '@/composition/user/useOnboardingTour'
import { useAccount } from '@/composition/account/useAccount'

export default defineComponent({
    components: {
        PageHeader,
        PageContent,
        Tabs,
        oButton,
        oInput,
        Label,
        Spacer,
        ExpandableSidebarButton,
        ExpandableSidebar,
        Modal,
        WarnIcon,
        Text,
        FullScreenOverlay,
        DismissFeedbackModal,
        OnboardingTour,
    },
    name: 'Improvements',
    setup() {
        const { accountInfo } = useAccount()
        const { currentRoute, push } = useRouter()
        const { aboveMobile } = useMediaQuery()

        const improvementsSidebar = ref(false)
        const modalOpen = ref(false)

        const pageSelectorLinks = computed(() => [
            {
                key: 'active',
                to: { name: Routes.ImprovementsActive },
            },
            {
                key: 'completed',
                to: { name: Routes.ImprovementsCompleted },
            },
            {
                key: 'dismissed',
                to: { name: Routes.ImprovementsDismissed },
            },
        ])

        const handleCampaignGroupsClose = () => {
            push({ name: Routes.ImprovementsActive })
        }

        const modalState = ref<'campaign-groups' | 'algorithm-settings'>('campaign-groups')

        const setModalState = (state: 'campaign-groups' | 'algorithm-settings') => {
            modalState.value = state
            document.getElementsByClassName('ps modal-content')[0].scrollTop = 0
        }

        const { recActionPreferences, updateActiveImpTypes } = useImprovementPreferences()

        const savePreferences = () => {
            updateActiveImpTypes()
        }

        const showSortingSelect = computed(
            () => currentRoute.value.name === Routes.ImprovementsActive
        )

        const { improvementSort, updateImprovementSort } = provideBatchBox()

        const { modalVisible: campaignGroupsOnboardingVisible } = useOnboardingTour({
            stepName: 'tour_campaigngroups',
        })

        return {
            Routes,
            improvementsSidebar,
            modalOpen,
            modalState,
            aboveMobile,
            setModalState,
            pageSelectorLinks,
            accountInfo,
            improvementSort,
            showSortingSelect,
            recActionPreferences,
            savePreferences,
            handleCampaignGroupsClose,
            updateImprovementSort,
            campaignGroupsOnboardingVisible,
        }
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';
.improvements-header-tab {
    @include flex;
    @include items-center;
}
.improvements-header-tab .improvement-tab-count {
    box-shadow: $opteo-shadow;
    width: 1.4375rem;
    height: 1.4375rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 999px;
    font-size: 0.6875rem;
    margin-left: 0.625rem;
    transition: transform cubic-bezier(0.19, 1, 0.22, 1) 0.25s;
    outline: none;
    letter-spacing: -0.02rem;
}
.improvements-header-tab .improvement-tab-count.wide {
    width: auto;
    @include ph-8;
}
.sort-improvements-by .button-mode,
.active-improvement-types .button-mode {
    width: 100%;
}
.campaign-groups-seen {
    @include absolute;
    top: -0.25rem;
    right: -0.25rem;
}
.campaign-groups-onboarding-bubble {
    @include absolute;
    top: -1.25rem;
    right: -1.75rem;
}
// Modal class
.modal-z-max {
    z-index: 9999999998;
}

:deep(.o-input__checkbox.button-mode) {
    @include w-100;
}

// Transitions

.bubble-fade-enter-active {
    transition:
        transform 0.75s cubic-bezier(0.68, -0.6, 0.32, 1.6),
        opacity 0.75s cubic-bezier(0.68, -0.6, 0.32, 1.6);
}
.bubble-fade-leave-active {
    transition:
        transform 0.75s cubic-bezier(0.68, -0.6, 0.32, 1.6),
        opacity 0.75s cubic-bezier(0.68, -0.6, 0.32, 1.6);
}
.bubble-fade-enter-from,
.bubble-fade-leave-to {
    transform: scale(20%);
    opacity: 0;
}

// Responsive

@media screen and (max-width: $mq-1279-max) {
    .improvements-header-tab .improvement-tab-count {
        display: none;
    }
}

@media screen and (max-width: $mq-480-max) {
    .campaign-groups-onboarding-bubble {
        display: none;
    }
}
</style>
