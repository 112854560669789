<template>
    <ImprovementView
        :push-messages="pushMessages"
        :push-action-text="pushActionText"
        :steps="adjustSteps"
        :on-push="onPush"
    >
        <ImprovementContent>
            <ImprovementHeader :title="title">
                <div v-if="aboveMobile">
                    <EntityPillLink
                        :entityPillData="{ type: 'campaign', content: campaignName }"
                        :deepLinkParams="locationEntityIds"
                    />
                    <Spacer height="2rem" />
                </div>
                <div v-if="campaignMode">
                    <Text as="p">
                        The campaign listed above accounts for a significant portion of your spend.
                        Opteo recommends having at least <b>4</b> custom sitelink extensions for
                        high-spend campaigns. Campaign specific sitelinks should help to increase ad
                        relevance and conversions.
                    </Text>
                    <Spacer height="1rem" />
                    <Text as="p">
                        Click
                        <Text as="span" weight="600" color="blue">{{ adjustSteps[0] }}</Text> to
                        write, preview and add <b>{{ totalMissingFeedItems }}</b> more
                        campaign-specific sitelink<span v-if="totalMissingFeedItems !== 1">s</span>
                        to this campaign. Existing sitelinks for this campaign are highlighted
                        below.
                    </Text>
                </div>
                <div v-else>
                    <Text as="p">
                        The campaign listed above accounts for a significant portion of your spend.
                        Opteo recommends adding custom sitelink extensions for high-spend campaigns.
                        Adding campaign specific sitelinks should help to increase ad relevance and
                        conversions.
                    </Text>
                    <Spacer height="1rem" />
                    <Text as="p">
                        Click
                        <Text as="span" weight="600" color="blue">{{ adjustSteps[0] }}</Text> to
                        write, preview and add campaign-specific sitelinks to this campaign,
                        replacing the highlighted account-level sitelinks below.
                    </Text>
                </div>
            </ImprovementHeader>
            <ImprovementHelpLink path="/improvements/extensions/add-extensions-to-campaign">
                Click here to learn more about the benefits of campaign-level extensions.
            </ImprovementHelpLink>
        </ImprovementContent>

        <ImprovementContent>
            <Text v-if="campaignMode" as="h3" size="f-4" weight="600">Existing Sitelinks</Text>
            <Text v-else as="h3" size="f-4" weight="600">Account Level Sitelinks</Text>
        </ImprovementContent>

        <ImprovementContent :ad-width="true">
            <!-- 
            NOTE: Always display to be the highest clicked ad in this campaign.
            Changes on the adjust step should not be reflected here.
         -->
            <TextAd
                :ad="highestClickedAdStatic"
                highlight="sitelinks"
                :title-tag="adTitleTag"
                v-if="highestClickedAd.sitelinks.length"
            />
        </ImprovementContent>

        <ImprovementContent>
            <Spacer height="3rem" />
            <ImprovementCreatedDate :last-updated="lastUpdated" :total-days="30" />
        </ImprovementContent>

        <template v-slot:adjust-step-1>
            <ImprovementContent>
                <ImprovementHeader
                    :title="
                        campaignMode ? 'Input Sitelink Extensions' : 'Customise Sitelink Extensions'
                    "
                >
                    <div v-if="aboveMobile">
                        <EntityPillLink
                            :entityPillData="{ type: 'campaign', content: campaignName }"
                            :deep-link-params="locationEntityIds"
                        />
                        <Spacer height="2rem" />
                    </div>
                    <Text v-if="campaignMode" as="p">
                        Use the form below to add campaign-level sitelink extensions. Add at least
                        <b>{{ totalMissingFeedItems }}</b> more campaign-specific
                        {{ totalMissingFeedItems > 1 ? 'sitelinks' : 'sitelink' }}
                        to the campaign above. Use the preview of your highest clicked ad to see how
                        your sitelinks will appear on the search engine results page.
                    </Text>
                    <Text v-else as="p">
                        Use the form below to add campaign-level sitelink extensions to replace the
                        account level sitelink extensions highlighted below. Use the preview of your
                        highest clicked ad to see how your sitelinks will appear on the search
                        engine results page.
                    </Text>
                    <Spacer height="1rem" />
                    <Text as="p">
                        Click
                        <Text as="span" weight="600" color="green">{{ pushActionText }}</Text> to
                        push your sitelink extensions to Google Ads.
                    </Text>
                </ImprovementHeader>
            </ImprovementContent>

            <ImprovementContent :ad-width="true">
                <!-- Dynamic preview based on inputs -->
                <TextAd :ad="highestClickedAd" highlight="sitelinks" :title-tag="adTitleTag" />
            </ImprovementContent>

            <Spacer height="3rem" />
            <ImprovementContent>
                <div class="form-container">
                    <oForm ref="sitelinkForm">
                        <div v-for="(extension, index) in sitelinkExtensions" v-bind:key="index">
                            <div
                                p
                                :class="
                                    index + 1 < sitelinkExtensions.length
                                        ? 'form-section'
                                        : 'form-section-no-bb'
                                "
                            >
                                <Text as="h6" weight="600">
                                    Sitelink {{ getNumberWord(index + 1) }}
                                </Text>

                                <Spacer height="2rem" />
                                <oInput
                                    v-model="extension.anchor"
                                    ref="anchorRefs"
                                    :name="`anchor${index}`"
                                    label="Sitelink Anchor"
                                    placeholder="Sitelink Anchor"
                                    :max-characters="MAXIMUM_ANCHOR_LENGTH"
                                    required
                                />
                                <Spacer height="1.5rem" />
                                <oInput
                                    v-model="extension.line1"
                                    :name="`line1-${index}`"
                                    label="Sitelink Line One"
                                    placeholder="Sitelink Line One"
                                    :max-characters="MAXIMUM_DESCRIPTION_LENGTH"
                                />
                                <Spacer height="1.5rem" />
                                <oInput
                                    v-model="extension.line2"
                                    :name="`line2-${index}`"
                                    label="Sitelink Line Two"
                                    placeholder="Sitelink Line Two"
                                    :max-characters="MAXIMUM_DESCRIPTION_LENGTH"
                                />
                                <Spacer height="1.5rem" />

                                <oInput
                                    v-model="extension.url"
                                    :name="`url${index}`"
                                    label="URL"
                                    placeholder="www.example.com"
                                    :validator="urlValidator"
                                    required
                                />
                            </div>
                        </div>
                        <div class="button-container">
                            <oButton
                                :size="aboveMobile ? 'small' : 'medium'"
                                color="white"
                                @clicked="resetSitelinkExtensions"
                                :fullWidth="aboveMobile ? false : true"
                            >
                                Reset Form
                            </oButton>
                            <Spacer v-if="aboveMobile" width="0.5rem" />
                            <Spacer v-else height="0.75rem" />
                            <oButton
                                :size="aboveMobile ? 'small' : 'medium'"
                                color="white"
                                @clicked="resetSitelinkExtensions(true)"
                                :fullWidth="aboveMobile ? false : true"
                            >
                                Clear Form
                            </oButton>
                        </div>
                    </oForm>
                </div>
            </ImprovementContent>
        </template>
    </ImprovementView>
</template>
<script lang="ts">
import {
    ImprovementHeader,
    ImprovementContent,
    ImprovementCreatedDate,
    Text,
    Spacer,
    TextAd,
    oInput,
    oForm,
    oButton,
} from '@opteo/components-next'
import { getNumberWord, urlValidator } from '@/lib/globalUtils'

import ImprovementView from '@/components/improvement/ImprovementView.vue'
import ImprovementHelpLink from '@/components/improvement/ImprovementHelpLink.vue'
import EntityPillLink from '@/components/global/Entity/EntityPillLink.vue'
import CircleButton from '@/components/global/CircleButton.vue'
import Skeleton from '@/components/util/Skeleton.vue'
import useMediaQuery from '@/composition/global/useMediaQuery'

import { useAddCampaignLevelSitelinkExtension } from '@/composition/improvement/types/useAddCampaignLevelSitelinkExtension'
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'AddCampaignLevelSitelinkExtension',
    components: {
        ImprovementHeader,
        ImprovementContent,
        ImprovementCreatedDate,
        ImprovementView,
        ImprovementHelpLink,
        EntityPillLink,
        Text,
        Spacer,
        TextAd,
        oInput,
        oForm,
        CircleButton,
        Skeleton,
        oButton,
    },
    setup() {
        const { aboveMobile } = useMediaQuery()
        return {
            ...useAddCampaignLevelSitelinkExtension(),
            getNumberWord,
            urlValidator,
            aboveMobile,
        }
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.form-container {
    @include container;
    @include relative;
}

.form-section {
    @include pa-36;
    border-bottom: 1px solid;
    @include opteo-border;
}
.section-header {
    @include flex;
    @include justify-between;
    @include items-center;
}

.form-section-no-bb {
    @include pa-36;
}

.button-container {
    border-top: 1px solid;
    @include opteo-border;
    @include flex;
    @include items-center;
    @include ph-36;
    @include pv-28;
}

@media screen and (max-width: $mq-767-max) {
    .button-container {
        @include block;
        @include pa-24;
    }
    .form-section,
    .form-section-no-bb {
        @include pa-24;
    }
}
</style>
