<template>
    <ImprovementView
        :push-messages="pushMessages"
        :push-action-text="pushActionText"
        :on-push="onPush"
        :steps="adjustSteps"
        :title="title"
        :push-data-still-loading="sourceKeywordBidLoading"
        @back-clicked="resetState"
    >
        <ImprovementContent>
            <ImprovementHeader :title="title">
                <span v-if="defaultDestinationIsInactive">
                    <div class="keyword-destination-inactive-warning">
                        <Text as="p" size="f-8">
                            It appears this campaign or ad group recently became inactive. You are
                            unlikely to continue spending on this search term, so you may want to
                            <b>Dismiss</b> this improvement.
                        </Text>
                    </div>
                    <Spacer height="2rem" />
                </span>
                <div v-if="aboveMobile">
                    <EntityPillLink
                        :entityPillData="{ type: 'search-term', content: searchTerm }"
                        :deepLinkParams="{ campaignId: +campaignId, adGroupId: +adGroupId }"
                    />
                    <Spacer height="2rem" />
                </div>
                <Text as="p">
                    The search term listed above is triggering your ads, but <b>has not</b> been
                    added as an individual keyword. The following (existing) keyword is responsible
                    for this activity:
                </Text>
                <Spacer height="2rem" />
                <EntityPillLinkList :items="entityPillList" :scrollToRight="true" />
                <Spacer height="2rem" />

                <!-- ROAS copy -->
                <Text as="p" v-if="isUsingRoas">
                    Over the last 365 days, you spent
                    <b><Money :value="metrics.cost.value" /></b> on this search term. During this
                    period, this search term generated
                    <b>
                        <Money :value="metrics.conversionsValue.value" :compact="true" />
                    </b>
                    in conversion value at a ROAS of <b><Roas :value="metrics.roas.value" /></b>.
                </Text>

                <!-- CPA copy -->
                <Text as="p" v-else>
                    Over the last 365 days, you spent
                    <b><Money :value="metrics.cost.value" /></b> on this search term. During this
                    period, this search term generated
                    <b><Number :value="metrics.clicks.value" /> {{ metrics.clicks.copy }}</b>
                    and
                    <b>
                        <Number :value="metrics.conversions.value" :compact="true" />
                        {{ metrics.conversions.copy }}
                    </b>
                    <span v-if="metrics.conversions.value">
                        at a CPA of <b><Money :value="metrics.cpa.value" /></b></span
                    >.
                </Text>

                <!-- Low Search Volume note -->
                <!-- Needs improved conditions, some matrix of clicks, impressions, etc. -->
                <template v-if="metrics.impressions.value < 30">
                    <Spacer height="1.5rem" />
                    <ImprovementNote>
                        Please note, this search term has a relatively low number of clicks and
                        impressions, and would likely be considered <b>Low Search Volume</b> if
                        added as a keyword in Google Ads.
                    </ImprovementNote>
                    <Spacer height="0.5rem" />
                </template>

                <Spacer height="1rem" />
                <Text as="p">
                    Click <Text as="span" weight="600" color="blue">{{ adjustSteps[0] }}</Text> to
                    add this search term as a
                    <Text as="span" color="green" weight="600">keyword</Text> or a
                    <Text as="span" color="red" weight="600">negative keyword</Text>. If your
                    existing keyword covers this traffic adequately, feel free to click
                    <b>Dismiss</b>.
                </Text>
            </ImprovementHeader>
            <ImprovementHelpLink path="/en/articles/900607-add-keyword-from-search-term">
                Click here to learn more about adding new keywords in Opteo.
            </ImprovementHelpLink>
        </ImprovementContent>

        <ImprovementContent>
            <SerpPreview :title="serpImage.imageTitle" :image-url="serpImage.url" />
        </ImprovementContent>

        <!-- Improvement Statistics -->
        <ImprovementContent>
            <ImprovementStatistics :items="improvementStatistics">
                <template v-slot:cost>
                    You spent
                    <b>{{ improvementStatistics[0].value }}</b> on this search term over the last
                    <b>365</b> days.
                </template>
                <template v-slot:impressions>
                    Over the last <b>365</b> days, this search term generated
                    <b>{{ improvementStatistics[1].value }}</b> impressions.
                </template>
                <template v-if="isUsingRoas" v-slot:conversionsValue>
                    Over the last <b>365</b> days, this search term generated
                    <b>{{ improvementStatistics[2].value }}</b> conversion value.
                </template>
                <template v-else v-slot:conversions>
                    Over the last <b>365</b> days, this search term generated
                    <b>{{ improvementStatistics[2].value }}</b> conversions.
                </template>
                <template v-if="isUsingRoas" v-slot:roas>
                    Over the last <b>365</b> days, this search term has a ROAS of
                    <b>{{ improvementStatistics[3].value }}</b
                    >.
                </template>
                <template v-else v-slot:cpa>
                    Over the last <b>365</b> days, this search term has an average CPA of
                    <b>{{ improvementStatistics[3].value }}</b
                    >.
                </template>
            </ImprovementStatistics>
        </ImprovementContent>

        <ImprovementContent>
            <ImprovementCreatedDate :last-updated="lastUpdated" :total-days="365" />
        </ImprovementContent>

        <template v-slot:adjust-step-1>
            <ImprovementContent>
                <ImprovementHeader title="Create Keyword">
                    <span v-if="defaultDestinationIsInactive">
                        <div class="keyword-destination-inactive-warning">
                            <Text as="p" size="f-8">
                                It appears this campaign or ad group recently became inactive. You
                                are unlikely to continue spending on this search term, so you may
                                want to
                                <b>Dismiss</b> this improvement.
                            </Text>
                        </div>
                        <Spacer height="1.875rem" />
                    </span>
                    <Text as="p">
                        Use this form to create your new keyword. You can add this search term as
                        either a <Text as="span" color="green" weight="600">keyword</Text> or a
                        <Text as="span" color="red" weight="600">negative keyword</Text>. Click
                        <b>Modify Keyword</b> to make adjustments.
                    </Text>
                    <Spacer height="1.875rem" />
                    <div class="create-keyword-form">
                        <Spacer :height="aboveMobile ? '2rem' : '1.5rem'" />
                        <Tabs
                            :router="false"
                            :links="[{ key: KeywordType.POSITIVE }, { key: KeywordType.NEGATIVE }]"
                            :active="addingNegativeKeyword"
                            @clicked="changeKeywordType"
                        >
                            <template v-slot:new-keyword>
                                <Text
                                    as="span"
                                    :color="
                                        addingNegativeKeyword === KeywordType.POSITIVE
                                            ? 'green'
                                            : 'black'
                                    "
                                >
                                    Keyword
                                </Text>
                            </template>
                            <template v-slot:negative-keyword>
                                <Text
                                    as="span"
                                    :color="
                                        addingNegativeKeyword === KeywordType.NEGATIVE
                                            ? 'red'
                                            : 'black'
                                    "
                                >
                                    {{ aboveSmallMobile ? 'Negative Keyword' : 'Negative' }}
                                </Text>
                            </template>
                        </Tabs>
                        <div v-if="previewKeyword" class="create-keyword-form-preview">
                            <EntityPill
                                v-if="addingNegativeKeyword === KeywordType.POSITIVE"
                                :content="previewKeyword"
                                type="keyword"
                            />
                            <EntityPill
                                v-if="addingNegativeKeyword === KeywordType.NEGATIVE"
                                :content="previewKeyword"
                                type="negative-keyword"
                            />
                        </div>
                        <div v-else class="create-keyword-form-preview">
                            <Text
                                as="span"
                                size="f-6"
                                weight="600"
                                style="line-height: 2.75rem; opacity: 0.16"
                            >
                                Please select at least one word
                            </Text>
                        </div>
                        <div class="modify-keyword-button">
                            <oButton
                                color="white"
                                size="small"
                                @clicked="toggleShowKeywordSelection"
                            >
                                {{ showKeywordSelection ? 'Hide Section' : 'Modify Keyword' }}
                            </oButton>
                        </div>
                        <Spacer :height="aboveMobile ? '2rem' : '1.5rem'" />
                        <div
                            v-show="showKeywordSelection"
                            :class="[
                                addingNegativeKeyword === KeywordType.NEGATIVE ? 'negative' : '',
                                'modify-keyword-section',
                            ]"
                        >
                            <Label>Select Words</Label>
                            <div
                                v-for="word in searchTermWords"
                                v-bind:key="word.index"
                                class="search-term-word-checkbox"
                            >
                                <oInput
                                    @clicked="toggleWordSelection(word.index)"
                                    :label="word.word"
                                    v-model="word.selected"
                                    type="checkbox"
                                    :button-mode="true"
                                    style="display: inline-flex"
                                />
                            </div>
                            <Spacer height="0.75rem" />
                            <Label>Select Match Type</Label>
                            <div
                                v-for="matchType in keywordMatchTypes"
                                v-bind:key="matchType.matchType"
                                class="match-type-special-toggle"
                            >
                                <div
                                    class="match-type-special-toggle-content"
                                    @click="toggleMatchType(matchType.matchType)"
                                    tabindex="0"
                                >
                                    <div
                                        class="match-type-special-toggle-radio"
                                        :class="[matchType.selected ? 'active' : '']"
                                    />
                                    <Spacer width="0.75rem" height="0.25rem" />
                                    <Text as="span" size="f-8">
                                        {{ KeywordMatchType[matchType.matchType].toLowerCase() }}
                                    </Text>
                                </div>
                            </div>
                            <div
                                v-if="
                                    !sourceKeywordBidLoading &&
                                    sourceKeywordBid > 0 &&
                                    addingNegativeKeyword === KeywordType.POSITIVE
                                "
                            >
                                <Spacer height="0.75rem" />
                                <Label>{{ sourceKeywordBidMessage }}</Label>
                                <div class="source-keyword-bid-container">
                                    <Money :value="sourceKeywordBid" />
                                </div>
                            </div>
                            <div
                                v-else-if="
                                    !sourceKeywordBidLoading &&
                                    !sourceKeywordBid &&
                                    addingNegativeKeyword === KeywordType.POSITIVE
                                "
                            >
                                <Spacer height="0.75rem" />
                                <Label>{{ sourceKeywordBidMessage }}</Label>
                                <div class="source-keyword-bid-container">N/A</div>
                                <Spacer height="0.75rem" />
                                <Text size="f-9" color="gray">
                                    Note: A specific bid cannot be applied to this keyword. This is
                                    usually because the bid has been set at the ad group level, or
                                    because the parent ad group uses smart bidding.
                                </Text>
                            </div>
                        </div>
                    </div>
                </ImprovementHeader>
            </ImprovementContent>

            <ImprovementContent>
                <Text as="h3" size="f-4" weight="600">Keyword Destination</Text>
                <Spacer height="1.625rem" />
                <Text>
                    Please note, you can select more than one destination for your
                    <span v-if="addingNegativeKeyword === KeywordType.POSITIVE">new</span>
                    <span v-else>negative</span> keyword.
                </Text>
                <Spacer height="1.875rem" />

                <KeywordDestinationSelector
                    :destinations="activeDestinations"
                    v-model:entities="activeEntities"
                    :error="onPushError"
                >
                    <template #custom-entity-negative-list="{ selector }">
                        <KeywordDestinationEntity
                            v-if="isEntityVisible(accountLevelEntity, selector.searchQuery)"
                            v-model:entity="accountLevelEntity"
                            color="red"
                        />
                    </template>
                    <template #empty-state-negative-list>
                        <KeywordDestinationEmptyState>
                            <template #text>
                                Opteo could not find any shared negative lists in your account. To
                                create one, click the button below. Otherwise, you can add this
                                negative to a campaign (or ad group) by switching to the
                                <b>Campaigns</b> tab.
                            </template>
                            <template #button>
                                <oButton color="white" @clicked="negativeListModalOpen = true">
                                    Create Negative List
                                </oButton>
                            </template>
                        </KeywordDestinationEmptyState>
                    </template>
                    <template #bottom-row-negative-list="{ selector }">
                        <oButton
                            v-if="!selector.empty"
                            color="white"
                            @clicked="negativeListModalOpen = true"
                        >
                            Create Negative List
                        </oButton>
                    </template>
                </KeywordDestinationSelector>

                <Spacer v-if="addingNegativeKeyword === KeywordType.POSITIVE" height="3rem" />

                <!-- Custom URL -->
                <div v-if="addingNegativeKeyword === KeywordType.POSITIVE">
                    <Text as="h3" size="f-4" weight="600">Custom Final URL</Text>
                    <Spacer height="1.625rem" />
                    <Text>
                        To add a Custom Final URL to your keyword, paste it in the field below.
                    </Text>
                    <Spacer height="1.875rem" />
                    <div class="custom-final-url-container">
                        <oInput
                            ref="customFinalUrlRef"
                            label="Custom Final URL"
                            v-model="customFinalURL"
                            :button-mode="true"
                        />
                        <Spacer height="0.75rem" />
                        <Text size="f-9" color="gray">
                            Add a Custom Final URL to direct searchers to a page related to your
                            keyword. Relevant landing pages increase the chances of conversion, and
                            can help boost Quality Scores.
                        </Text>
                    </div>
                </div>
            </ImprovementContent>

            <NewNegativeListModal
                v-model="negativeListModalOpen"
                :campaigns="campaigns"
                :existing-shared-sets="sharedSets?.map(set => set.label)"
                @update:newListData="value => mutateNegativeList(value)"
            />
        </template>
    </ImprovementView>
</template>
<script lang="ts">
import { defineComponent } from 'vue'

import {
    ImprovementHeader,
    ImprovementContent,
    ImprovementCreatedDate,
    ImprovementNote,
    ImprovementStatistics,
    EntityPill,
    oInput,
    oForm,
    Keyword,
    SerpPreview,
    oButton,
    TextAd,
    Text,
    Spacer,
    Tooltip,
    Popout,
    Tabs,
    CircleKeywordIcon,
    CircleNegativeKeywordIcon,
    Label,
    Number,
    Modal,
    Checkbox,
    Roas,
} from '@opteo/components-next'
import EntityPillLink from '@/components/global/Entity/EntityPillLink.vue'
import EntityPillLinkList from '@/components/global/Entity/EntityPillLinkList.vue'
import KeywordDestinationSelector from '@/components/improvement/shared-components/keyword-destination/KeywordDestinationSelector.vue'
import KeywordDestinationEntity from '@/components/improvement/shared-components/keyword-destination/KeywordDestinationEntity.vue'
import KeywordDestinationEmptyState from '@/components/improvement/shared-components/keyword-destination/KeywordDestinationEmptyState.vue'
import { isEntityVisible } from '@/components/improvement/shared-components/keyword-destination/utils'
import NewNegativeListModal from '@/components/improvement/shared-components/NewNegativeListModal.vue'
import ImprovementView from '@/components/improvement/ImprovementView.vue'
import ImprovementHelpLink from '@/components/improvement/ImprovementHelpLink.vue'
import Money from '@/components/global/oMoney.vue'
import Ent from '@/components/util/Ent.vue'
import useMediaQuery from '@/composition/global/useMediaQuery'

import { useCheckQueryRelevance } from '@/composition/improvement/types/useCheckQueryRelevance'

export default defineComponent({
    name: 'CheckQueryRelevance',
    components: {
        ImprovementView,
        ImprovementHeader,
        ImprovementContent,
        ImprovementHelpLink,
        ImprovementCreatedDate,
        ImprovementNote,
        ImprovementStatistics,
        KeywordDestinationSelector,
        KeywordDestinationEntity,
        KeywordDestinationEmptyState,
        NewNegativeListModal,
        EntityPill,
        EntityPillLinkList,
        EntityPillLink,
        Money,
        oInput,
        oForm,
        Ent,
        Keyword,
        SerpPreview,
        oButton,
        TextAd,
        Text,
        Spacer,
        Tooltip,
        Popout,
        Tabs,
        CircleKeywordIcon,
        CircleNegativeKeywordIcon,
        Label,
        Number,
        Roas,
        Modal,
        Checkbox,
    },
    setup() {
        return { ...useCheckQueryRelevance(), ...useMediaQuery(), isEntityVisible }
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.keyword-destination-inactive-warning {
    @include container;
    @include ph-24;
    @include pv-20;
}

// Create Keyword Form
.create-keyword-form {
    @include container;
    @include relative;
    min-height: 16.875rem;
    @include flex;
    @include items-center;
    @include justify-center;
    flex-direction: column;
    @include br-24;
}
.create-keyword-form-preview {
    @include flex;
    @include items-center;
    @include mv-48;
}
.modify-keyword-button {
    @include flex-center;
}
.modify-keyword-section {
    border-top: 1px solid $opteo-light-gray;
    width: 100%;
    @include pa-32;
}
.modify-keyword-section.negative {
    @include pb-20;
}
.search-term-word-checkbox {
    display: inline-block;
    @include mr-12;
    @include mb-12;
}

.match-type-special-toggle {
    box-shadow: $opteo-shadow;
    @include inline-block;
    @include mr-12;
    @include mb-12;
    cursor: pointer;
    @include br-12;
}
.match-type-special-toggle-content {
    @include flex;
    @include items-center;
    @include ph-20;
    @include pv-16;
    text-transform: capitalize;
    @include br-12;
    transition: box-shadow cubic-bezier(0.19, 1, 0.22, 1) 0.25s;
}
.match-type-special-toggle-content:focus {
    box-shadow: $opteo-shadow-focus-ring;
}
.match-type-special-toggle-radio {
    width: 18px;
    height: 18px;
    box-shadow: $opteo-shadow;
    @include bg-opteo-white;
    @include br-999;
    @include relative;
}
.match-type-special-toggle-radio.active {
    @include bg-opteo-blue;
}
.match-type-special-toggle-radio.active::after {
    content: '';
    width: 0.375rem;
    height: 0.375rem;
    @include absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    @include bg-opteo-white;
    @include br-999;
}

.source-keyword-bid-container {
    @include inline-flex;
    @include items-center;
    @include pl-20;
    @include pr-32;
    @include pv-16;
    @include br-12;
    background-color: #f7f7f9;
    box-shadow: $opteo-shadow;
    line-height: 1.125rem;
    color: rgba(01, 01, 05, 0.24);
    @include f-8;
}

.shared-negative-list-container,
.keyword-destination-container {
    @include container;
    @include pa-32;
}

// Custom Final URL styles
.custom-final-url-container {
    @include container;
    @include pa-32;
    @include br-24;
}
.custom-final-url-text-container {
    @include container;
    @include pa-24;
    @include br-20;
}

// CREATE NEGATIVE KEYWORD MODAL
.create-negative-list-modal-form {
    max-width: 400px;
    padding: 1rem 0rem;
    margin: 0 auto;
}
.negative-list-campaigns {
    @include container;
    border-radius: 1.25rem;
    overflow: hidden;
}
.negative-list-campaign-item {
    display: flex;
    align-items: center;
    padding: 1rem 1.5rem;
    overflow: hidden;
    position: relative;
    width: 100%;
}
.negative-list-campaign-item:after {
    content: '';
    background: #fff;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0, rgb(255, 255, 255) 80%);
    width: 6rem;
    right: 0;
    height: calc(100% - 8px);
    top: 4px;
    position: absolute;
    pointer-events: none;
}
.negative-list-campaign-item:not(:last-child) {
    border-bottom: 1px solid;
    @include opteo-border;
}

@media screen and (max-width: $mq-767-max) {
    .modify-keyword-section {
        @include pa-24;
    }
    .keyword-destination-container {
        @include pa-24;
    }
    .shared-negative-list-container {
        @include pa-24;
    }
}
.no-bid-info-icon {
    position: absolute;
    top: 50%;
    right: 1rem;
    transform: translate(0%, -50%);
    z-index: 4;
}
</style>
