<template>
    <oTable
        :headers="tableHeaders"
        :items="items"
        order-by="cost"
        order="DESC"
        fixed-layout
        :border-radius="24"
    >
        <template #header.ngram>
            <Spacer width="1.875rem" />
            <Checkbox />
            <Spacer width="1.25rem" />
            <Text size="f-8" weight="500">N-Gram</Text>
        </template>
        <template #header.vpi>
            <Text as="span" size="f-8" weight="500">VPI</Text>
            <img
                class="info-circle-icon"
                style="margin-left: 0.5rem"
                src="@/assets/img/icon-info.svg"
            />
        </template>
        <template #column.ngram>
            <!-- add click to the table cell for a larger clickable area -->
            <div class="ngram-cell">
                <Checkbox />
                <Spacer width="1.25rem" />
                <EntityPill :type="Improvement.LocationEntity.NGram">
                    <Skeleton
                        width="6rem"
                        color="hsla(174, 100%, 22%, 0.2)"
                        shimmer-color="hsla(174, 100%, 22%, 0.06)"
                    />
                </EntityPill>
            </div>
        </template>
        <template #column.cost>
            <Skeleton width="3rem" />
        </template>
        <template #column.impressions>
            <Skeleton width="3rem" />
        </template>
        <template #column.conversions>
            <Skeleton width="3rem" />
        </template>
        <template #column.conversionValue>
            <Skeleton width="3rem" />
        </template>
        <template #column.cpa>
            <Skeleton width="3rem" />
        </template>
        <template #column.roas>
            <Skeleton width="3rem" />
        </template>
        <template #column.cpi>
            <Skeleton width="3rem" />
        </template>
        <template #column.vpi>
            <Skeleton width="3rem" />
        </template>
        <template #column.vsAvg>
            <Skeleton width="3rem" />
        </template>
        <template #column.nscoreSortValue="{ row }">
            <NScoreCell v-bind="row" nScoreColor="#e6e6e6" />
        </template>
        <template #column.keywordConflicts>
            <EntityPill :type="Improvement.LocationEntity.Keyword">
                <Skeleton
                    width="4rem"
                    color="hsla(174, 100%, 22%, 0.2)"
                    shimmer-color="hsla(174, 100%, 22%, 0.06)"
                />
            </EntityPill>
        </template>
        <template #column.actions>
            <oButton size="small" color="white" :disabled="true"> Detailed View </oButton>
        </template>
    </oTable>
</template>

<script setup lang="ts">
import { oTable, Checkbox, EntityPill, Spacer, Text, oButton } from '@opteo/components-next'
import Skeleton from '@/components/util/Skeleton.vue'
import { Improvement } from '@opteo/types'

import { useNGramTool } from '@/composition/toolkit/nGramTool/useNGramTool'
import NScoreCell from './NScoreCell.vue'

const props = withDefaults(defineProps<{ numberOfRows?: number }>(), {
    numberOfRows: 20,
})

const { tableHeaders } = useNGramTool()

const items: {}[] = new Array(props.numberOfRows).fill({})
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.conflicts-cell {
    display: flex;
    align-items: center;
}
</style>
