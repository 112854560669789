<template>
    <ImprovementView :push-messages="pushMessages" :push-action-text="pushActionText">
        <ImprovementContent>
            <ImprovementHeader :title="title">
                <div v-if="aboveMobile">
                    <EntityPillLinkList :items="entityPillList" :scrollToRight="true" />
                    <Spacer width="100%" height="1.5rem" />
                </div>

                <!-- CPA Copy -->
                <Text as="p" v-if="isUsingCpa">
                    The keyword listed above spends a significant amount, but converts few
                    searchers. This keyword has an average CPA of
                    <b><Money :value="keywordCpa" /></b>. In comparison, the parent campaign group
                    has an average CPA of <b><Money :value="campaignGroupTargetCpa" /> </b>. Opteo
                    recommends pausing this keyword.
                    <Spacer height="1rem" />
                    Click
                    <Text as="span" weight="600" color="green">{{ pushActionText }}</Text> to pause
                    this keyword. If you consider this improvement a false positive and this keyword
                    has acceptable performance, feel free to click <b>Dismiss</b>.
                </Text>

                <!-- ROAS Copy -->
                <Text as="p" v-else>
                    The keyword listed above spends a significant amount, but converts few
                    searchers. This keyword has an average ROAS of
                    <b><Roas :value="keywordRoas" /></b>. In comparison, the parent campaign group
                    has an average ROAS of <b><Roas :value="campaignGroupTargetRoas" /> </b>. Opteo
                    recommends pausing this keyword.
                    <Spacer height="1rem" />
                    Click
                    <Text as="span" weight="600" color="green">{{ pushActionText }}</Text> to pause
                    this keyword. If you consider this improvement a false positive and this keyword
                    has acceptable performance, feel free to click <b>Dismiss</b>.
                </Text>
            </ImprovementHeader>
            <ImprovementHelpLink path="/articles/900649-pause-keyword">
                Click here to learn how Opteo identifies underperforming keywords.
            </ImprovementHelpLink>
        </ImprovementContent>

        <ImprovementContent>
            <ImprovementStatistics :items="statistics">
                <template #cost>
                    <b><Money :value="keywordCost" /></b> was spent on this keyword over the last
                    <b>{{ lookbackWindow }}</b> days.
                </template>

                <template #conversions>
                    This keyword generated
                    <b><Number :value="keywordConversions" /></b> conversion<span
                        v-if="keywordConversions !== 1"
                        >s</span
                    >
                    over the last <b>{{ lookbackWindow }}</b> days. Please note,
                    {{ keywordConversions !== 1 ? 'these conversions are' : 'this conversion is' }}
                    based on your conversion types set in <b>Campaign Groups</b>.
                </template>

                <template #conversionsValue>
                    This keyword generated
                    <b><Money :value="keywordConversionsValue" /></b> in conversion value over the
                    last <b>{{ lookbackWindow }}</b> days. Please note, this conversion value is
                    based on your conversion types set in <b>Campaign Groups</b>.
                </template>

                <template #averageCpa>
                    The parent campaign group has an average CPA of
                    <b><Money :value="campaignGroupTargetCpa" /></b>.
                </template>

                <template #averageRoas>
                    The parent campaign group has an average ROAS of
                    <b><Roas :value="campaignGroupTargetRoas" /></b>.
                </template>

                <template #keywordCpa>
                    This keyword has an average CPA of <b><Money :value="keywordCpa" /></b> over the
                    last <b>{{ lookbackWindow }}</b> days.
                </template>

                <template #keywordRoas>
                    This keyword has an average ROAS of <b><Roas :value="keywordRoas" /></b> over
                    the last <b>{{ lookbackWindow }}</b> days.
                </template>
            </ImprovementStatistics>
        </ImprovementContent>

        <ImprovementContent>
            <ImprovementCreatedDate :last-updated="lastUpdated" :total-days="lookbackWindow" />
        </ImprovementContent>
    </ImprovementView>
</template>

<script lang="ts" setup>
import { usePauseKeywordV2 } from '@/composition/improvement/types/usePauseKeywordV2'

import {
    ImprovementHeader,
    ImprovementContent,
    ImprovementStatistics,
    ImprovementCreatedDate,
    Spacer,
    Number,
    Text,
    Roas,
} from '@opteo/components-next'

import EntityPillLinkList from '@/components/global/Entity/EntityPillLinkList.vue'
import Money from '@/components/global/oMoney.vue'
import ImprovementView from '@/components/improvement/ImprovementView.vue'
import ImprovementHelpLink from '@/components/improvement/ImprovementHelpLink.vue'
import useMediaQuery from '@/composition/global/useMediaQuery'

const {
    title,
    pushActionText,
    pushMessages,
    lastUpdated,
    campaignName,
    adgroupName,
    formattedKeyword,
    statistics,
    keywordCost,
    keywordConversions,
    keywordConversionsValue,
    campaignGroupTargetCpa,
    campaignGroupTargetRoas,
    lookbackWindow,
    keywordCpa,
    keywordRoas,
    isUsingCpa,
    entityPillList,
} = usePauseKeywordV2()

const { aboveMobile } = useMediaQuery()
</script>
