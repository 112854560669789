<template>
    <Popout
        v-if="keywords.length > 0"
        v-model="showPopout"
        trigger="mouseenter"
        placement="top-end"
        :maxWidth="720"
        :borderRadius="28"
        :offset="[48, 16]"
        :delay="[400, 0]"
        :zIndex="9999999999999"
        wrapperClasses="keyword-source-popout-wrapper"
    >
        <div class="keyword-source-cell">
            <EntityPill
                :type="Improvement.LocationEntity.Keyword"
                size="all"
                :content="`${keywords.length} Keyword${keywords.length > 1 ? 's' : ''}`"
                style="margin-right: 0.5rem"
            />
            <img class="info-circle-icon" src="@/assets/img/icon-info.svg" />
        </div>

        <template #content>
            <perfect-scrollbar class="keywords-list">
                <KeywordInfo
                    v-for="keyword in keywords"
                    :keyword="keyword.keywordText"
                    :campaign-name="keyword.campaignName"
                    :adGroup-name="keyword.adGroupName"
                    :loading="metricsLoading"
                >
                    <template #keyword-info>
                        <div class="keyword-metrics">
                            <div class="metric-box">
                                <Text size="f-9" weight="500" color="gray"> Cost </Text>
                                <Text
                                    v-if="typeof keyword.cost === 'number'"
                                    size="f-6"
                                    weight="600"
                                >
                                    <Money :value="keyword.cost" />
                                </Text>
                                <Skeleton
                                    style="margin-top: 0.625rem"
                                    :width="60"
                                    height="1.25rem"
                                    v-else
                                />
                            </div>
                            <div
                                v-if="performanceMode === Targets.PerformanceMode.CPA"
                                class="metric-box"
                            >
                                <Text size="f-9" weight="500" color="gray"> Conv. </Text>
                                <Text
                                    v-if="typeof keyword.conversions === 'number'"
                                    size="f-6"
                                    weight="600"
                                >
                                    <Number :value="keyword.conversions" />
                                </Text>

                                <Skeleton
                                    style="margin-top: 0.625rem"
                                    :width="60"
                                    height="1.25rem"
                                    v-else
                                />
                            </div>
                            <div v-else class="metric-box">
                                <Text size="f-9" weight="500" color="gray"> Value </Text>
                                <Text
                                    v-if="typeof keyword.conversionValue === 'number'"
                                    size="f-6"
                                    weight="600"
                                >
                                    <Money :value="keyword.conversionValue" />
                                </Text>

                                <Skeleton
                                    :width="60"
                                    style="margin-top: 0.625rem"
                                    height="1.25rem"
                                    v-else
                                />
                            </div>
                            <div
                                v-if="performanceMode === Targets.PerformanceMode.CPA"
                                class="metric-box"
                            >
                                <Text size="f-9" weight="500" color="gray"> CPA </Text>
                                <Text
                                    size="f-6"
                                    weight="600"
                                    v-if="typeof keyword.cpa === 'number'"
                                >
                                    <Money :value="keyword.cpa" />
                                </Text>

                                <Skeleton
                                    :width="60"
                                    style="margin-top: 0.625rem"
                                    height="1.25rem"
                                    v-else
                                />
                            </div>
                            <div v-else class="metric-box">
                                <Text size="f-9" weight="500" color="gray"> ROAS </Text>
                                <Text
                                    size="f-6"
                                    weight="600"
                                    v-if="typeof keyword.roas === 'number'"
                                >
                                    <Percent :value="keyword.roas" />
                                </Text>

                                <Skeleton
                                    :width="60"
                                    style="margin-top: 0.625rem"
                                    height="1.25rem"
                                    v-else
                                />
                            </div>
                        </div>
                    </template>
                </KeywordInfo>
            </perfect-scrollbar>
        </template>
    </Popout>

    <EntityPill v-else :type="Improvement.LocationEntity.Keyword">
        <Skeleton
            width="4.5rem"
            color="hsla(174, 100%, 22%, 0.2)"
            shimmer-color="hsla(174, 100%, 22%, 0.06)"
        />
    </EntityPill>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue'
import orderBy from 'lodash-es/orderBy'

import { Text, EntityPill, Popout, Number, Percent } from '@opteo/components-next'
import { Improvement, Targets } from '@opteo/types'

import Money from '@/components/global/oMoney.vue'
import Skeleton from '@/components/util/Skeleton.vue'
import { Endpoint, useAPI } from '@/composition/api/useAPI'
import { useNGramFilters } from '@/composition/toolkit/nGramTool/useNGramFilters'
import KeywordInfo from './KeywordInfo.vue'
import { useAccount } from '@/composition/account/useAccount'

interface Keyword {
    resourceName: string
    campaignName: string
    adGroupName: string
    keywordText: string
    impressions?: number
    cost?: number
    conversions?: number
    conversionValue?: number
    cpa?: number
    roas?: number
}

const props = defineProps<{
    keywordResourceNames: string[]
}>()

const { performanceMode, accountId } = useAccount()
const { lookbackWindow } = useNGramFilters()

const showPopout = ref(false)

const { data: keywordMetrics, loading: metricsLoading } = useAPI<
    {
        resourceName: string
        campaignName: string
        adGroupName: string
        keywordText: string
        impressions: number
        cost: number
        conversions: number
        conversionValue: number
    }[]
>(Endpoint.GetKeywordMetrics, {
    body: () => ({
        accountId: accountId.value,
        keywordResourceNames: props.keywordResourceNames,
        lookbackWindow: lookbackWindow.value.value,
    }),
    uniqueId: () => `${JSON.stringify(props.keywordResourceNames)}`,
    waitFor: () => showPopout.value,
})

const keywords = computed<Keyword[]>(
    () =>
        orderBy(
            props.keywordResourceNames?.map(keywordResourceName => {
                const metrics = keywordMetrics.value?.find(
                    keyword => keyword.resourceName === keywordResourceName
                )

                if (!metrics)
                    return {
                        resourceName: keywordResourceName,
                        campaignName: ' ',
                        adGroupName: ' ',
                        keywordText: ' ',
                    }

                const cpa = metrics.cost ? metrics.cost / metrics.conversions : 0
                const roas = metrics.conversionValue ? metrics.conversionValue / metrics.cost : 0

                return {
                    ...metrics,
                    resourceName: keywordResourceName,
                    cpa,
                    roas,
                }
            }),
            'cost',
            'desc'
        ) ?? []
)
</script>

<style scoped lang="scss">
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.keyword-source-cell {
    display: flex;
    align-items: center;
    cursor: pointer;
}
:deep(.keyword-source-popout-wrapper) {
    display: flex;
    align-items: center;
}
.info-circle-icon {
    width: 1rem;
    cursor: pointer;
}

.keywords-list {
    display: flex;
    flex-direction: column;
    @include pa-20;
    max-height: 362px;
    @include br-20;
    gap: 1rem;
}

.keyword-metrics {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 0.625rem;
}

.metric-box {
    @include flex-center;
    flex-direction: column;
}
</style>
