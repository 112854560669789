<template>
    <Modal
        v-model="showNgramFiltersModal"
        title="N-Gram Filters"
        width="800px"
        content-max-height="540px"
    >
        <template #content>
            <div class="ngram-filters-modal">
                <div v-for="(type, typeIndex) in newFilters" :key="type.label">
                    <Label>{{ type.label }}</Label>
                    <div class="checkbox-group">
                        <div
                            v-for="(filter, filterKey) in type.state"
                            role="button"
                            class="checkbox-group-item"
                            @click.prevent="setFilter(typeIndex, filterKey, filter.checked)"
                        >
                            <Checkbox
                                v-if="!filter.filterActive || filter.filterActive(activeTab)"
                                :model-value="filter.checked"
                            >
                                <template #label>
                                    <Text size="f-8">{{ filter.label }}</Text>
                                    <Text
                                        v-if="filter.subLabel"
                                        size="f-8"
                                        color="gray"
                                        style="margin-left: 0.75rem"
                                        >{{ filter.subLabel }}</Text
                                    >
                                </template>
                            </Checkbox>
                        </div>
                    </div>
                    <Spacer v-if="typeIndex !== newFilters.length - 1" height="2.25rem" />
                </div>
            </div>
        </template>
        <template #buttons>
            <oButton @clicked="showNgramFiltersModal = false" color="white">Cancel</oButton>
            <Spacer width="0.625rem" />
            <oButton @clicked="handleApplyFilterClick"
                >Apply Filters<template #icon><RightArrowIcon hex="#fff" /></template
            ></oButton>
        </template>
    </Modal>
</template>

<script lang="ts" setup>
import { ref, unref } from 'vue'

import {
    Spacer,
    Text,
    Checkbox,
    Label,
    Modal,
    oButton,
    RightArrowIcon,
} from '@opteo/components-next'
import { useNGramFilters, FilterKey } from '@/composition/toolkit/nGramTool/useNGramFilters'
import { useNGramTool } from '@/composition/toolkit/nGramTool/useNGramTool'

const {
    excludeNGramFilters,
    excludeSearchTermFilters,
    excludeKeywordFilters,
    excludeMatchTypeFilters,
    shinyFilters,
    showNgramFiltersModal,
    activeTab,
} = useNGramFilters()

const newFilters = ref(
    [
        { label: 'Exclude N-Grams', state: { ...unref(excludeNGramFilters.value) } },
        { label: 'Exclude Search Term Data', state: { ...unref(excludeSearchTermFilters.value) } },
        { label: 'Exclude Keywords', state: { ...unref(excludeKeywordFilters.value) } },
        { label: 'Exclude Match Types', state: { ...unref(excludeMatchTypeFilters.value) } },
        { label: 'Filter by N-Gram Insights', state: { ...unref(shinyFilters.value) } },
    ]
        .filter(
            f =>
                Object.values(f.state).filter(
                    f => !f.filterActive || f.filterActive(activeTab.value)
                ).length > 0
        )
        .map(f => {
            f.state = Object.fromEntries(
                Object.entries(f.state).filter(
                    ([, filter]) => !filter.filterActive || filter.filterActive(activeTab.value)
                )
            )
            return f
        })
)

function setFilter(typeIndex: number, filterKey: FilterKey, checked: boolean) {
    newFilters.value[typeIndex].state[filterKey].checked = !checked
}

const { applyFilters } = useNGramTool()

function handleApplyFilterClick() {
    applyFilters()
    showNgramFiltersModal.value = false
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.ngram-filters-modal {
    max-width: 400px;
    margin: 0 auto;
}
.checkbox-group {
    @include container;
    border-radius: 1.125rem;
}
.checkbox-group-item {
    padding: 1.125rem 1.5rem;
    cursor: pointer;
}
.checkbox-group-item:not(:last-child) {
    border-bottom: 1px solid;
    @include opteo-border;
}
</style>
