<template>
    <div class="nscore-cell">
        <ScorecardDonut
            :score="nscore * 100"
            :width="24"
            :stroke-width="2"
            :custom-color="nScoreColor"
            hide-score
            compact
        />
        <Spacer width="0.5rem" />
        <Popout
            v-if="showPopout"
            trigger="mouseenter"
            placement="top"
            wrapper-classes="nscore-popout-wrapper"
            max-width="23.25rem"
            :zIndex="9999999999999"
            :offset="[0, 20]"
        >
            <template #content>
                <div class="nscore-popout">
                    <ScorecardDonut
                        :score="isNull(nscore) ? 'N/A' : nscore * 100"
                        :custom-color="nScoreColor"
                        :strokeWidth="3"
                        :width="56"
                        compact
                        compact-text-size="1.25rem"
                    />
                    <div class="content" v-if="isNull(nscore)">
                        <Text as="p" size="f-8"
                            >Unable to calculate an nScore due to insufficient
                            {{ performanceMaxActive ? 'impressions' : 'cost' }}.</Text
                        >
                    </div>
                    <div class="content" v-else>
                        <Text as="p" size="f-8">
                            This ngram performs
                            <b
                                ><Text as="span" :color="vsAverageColor">{{
                                    usePercent({ value: vsAvg ?? 0, stripped: true }).displayValue
                                        .value
                                }}</Text></b
                            >
                            {{ performanceComparison }}
                            than the average {{ averageMetric }} of
                            <b>{{ formattedAverage }}</b></Text
                        >
                    </div>
                </div>
            </template>
            <img class="info-circle-icon" src="@/assets/img/icon-info.svg" />
        </Popout>
        <img v-if="!showPopout" class="info-circle-icon" src="@/assets/img/icon-info.svg" />
    </div>
</template>

<script lang="ts" setup>
import isNull from 'lodash-es/isNull'
import { computed } from 'vue'

import { Spacer, ScorecardDonut, Popout, usePercent, Text } from '@opteo/components-next'
import { Targets } from '@opteo/types'

import { useDomainMoney } from '@/composition/domain/useDomainMoney'
import { useNGramTool } from '@/composition/toolkit/nGramTool/useNGramTool'
import { useAccount } from '@/composition/account/useAccount'

const props = defineProps<{
    ngram?: string
    impressions?: number
    conversionValue?: number
    cost?: number
    vsAvg?: number
    nscore: number | null
    nScoreColor: string
    vsAverageColor?: 'red' | 'green'
}>()

const { performanceMode } = useAccount()
const { ngramAverage, performanceMaxActive, formatCpi, formatVpi } = useNGramTool()

const showPopout = computed(() => typeof props.nscore !== 'undefined')

const formattedAverage = computed(() => {
    if (performanceMaxActive.value) {
        if (performanceMode.value === Targets.PerformanceMode.CPA) {
            return formatCpi(ngramAverage.value) // CPI
        }
        return formatVpi(ngramAverage.value) // VPI
    }

    if (performanceMode.value === Targets.PerformanceMode.CPA) {
        return useDomainMoney({ value: ngramAverage.value }).value.displayValue.value // CPA
    }

    return usePercent({ value: ngramAverage.value }).displayValue.value // ROAS
})

const averageMetric = computed(() => {
    if (performanceMaxActive.value) {
        if (performanceMode.value === Targets.PerformanceMode.CPA) {
            return 'CPI'
        }
        return 'VPI'
    }

    if (performanceMode.value === Targets.PerformanceMode.CPA) {
        return 'CPA'
    }

    return 'ROAS'
})

const performanceComparison = computed(() => {
    const vsAvg = props.vsAvg ?? 0
    const isCPA = performanceMode.value === Targets.PerformanceMode.CPA

    if (!performanceMaxActive.value && isCPA) {
        return vsAvg >= 0 ? 'worse' : 'better'
    }

    return vsAvg < 0 ? 'worse' : 'better'
})
</script>

<style scoped lang="scss">
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

:deep(.nscore-popout-wrapper) {
    display: flex;
    align-items: center;
}

.nscore-cell {
    display: flex;
    align-items: center;
}

.info-circle-icon {
    width: 1rem;
    cursor: pointer;
}

.nscore-popout {
    @include ph-28;
    @include pv-24;
    @include flex;
    @include items-center;
    gap: 1rem;
}
</style>
