<template>
    <div class="keyword-destination-selector">
        <header>
            <Tabs
                :links="links"
                :router="false"
                :active="activeDestination"
                @clicked="setActiveDestination"
            />
            <oInput class="search-input" v-model="searchQuery" />
        </header>
        <Spacer />
        <div v-if="error?.status" class="error-message-wrapper">
            <ErrorMessage :content="error.message" />
        </div>
        <Spacer v-if="error?.status" />
        <div v-if="$slots[`custom-entity-${activeDestination}`]" class="custom-entity-container">
            <slot :name="`custom-entity-${activeDestination}`" :selector="api" />
        </div>
        <KeywordDestinationEntity
            v-if="!empty"
            v-for="(entity, index) in visibleEntities"
            :key="entity.id"
            v-model:entity="visibleEntities[index]"
        />
        <div v-else-if="empty && searchQuery.length" class="empty-state">
            <KeywordDestinationEmptyState>
                <template #text>
                    <Text size="f-8">
                        No {{ activeDestinationName }} matching your search query.
                    </Text>
                </template>
            </KeywordDestinationEmptyState>
        </div>
        <div v-else-if="$slots[`empty-state-${activeDestination}`]" class="empty-state">
            <slot :name="`empty-state-${activeDestination}`" :selector="api" />
        </div>
        <div v-else class="empty-state">
            <KeywordDestinationEmptyState>
                <template #text>
                    <Text size="f-8">No {{ activeDestinationName }} available.</Text>
                </template>
            </KeywordDestinationEmptyState>
        </div>
        <div v-if="$slots[`bottom-row-${activeDestination}`]" class="bottom-row-container">
            <slot :name="`bottom-row-${activeDestination}`" :selector="api" />
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed, ref, watch, provide, reactive } from 'vue'
import { Tabs, oInput, Spacer, Text, ErrorMessage } from '@opteo/components-next'
import KeywordDestinationEntity from './KeywordDestinationEntity.vue'
import KeywordDestinationEmptyState from './KeywordDestinationEmptyState.vue'
import { Destination, DestinationType, Entity, Entities } from './keyword-destination-types'

// The key in a destination must match a key on the entities prop
const props = defineProps<{
    destinations: Destination[]
    entities: Entities
    error?: { status: boolean; message: string }
}>()

defineEmits<{
    (e: 'update:entities', entities: Entities): void
}>()

// TODO: add a "to" property (see TabsLink type from Tabs component in components-next)
const links = computed(() => {
    return props.destinations.map(d => {
        const entityType = d.key
        const entityCount = countCheckedItems(props.entities?.[entityType]) ?? 0
        return { ...d, count: (d.count ?? 0) + entityCount }
    })
})

const activeDestination = ref(findActiveDestination() ?? [])
const activeDestinationName = computed(
    () => props.destinations.find(d => d.key === activeDestination.value)?.name.toLowerCase()
)

const searchQuery = ref('')

const expandedIds = ref(getSelectedEntities().map(i => i.id))

const visibleEntities = computed(() => {
    if (!searchQuery.value.length) return props?.entities?.[activeDestination.value]

    const query = searchQuery.value.toLowerCase()
    return (
        props.entities[activeDestination.value]
            .filter(item => {
                const isParentMatch = item.label.toLowerCase().includes(query) && !item.uncheckable
                const isChildrenMatch =
                    item.children &&
                    item.children.some(child => child.label.toLowerCase().includes(query))
                return isParentMatch || isChildrenMatch
            })
            .map(item => {
                if (item.children) {
                    return {
                        ...item,
                        children: item.children.filter(child => {
                            if (child.label.toLowerCase().includes(query)) {
                                // open parent if child is found
                                toggleExpanded(item.id, true)
                                return true
                            }
                        }),
                    }
                }
                return item
            }) ?? []
    )
})

const empty = computed(() => !visibleEntities.value?.length)

function toggleExpanded(id: string, value?: boolean) {
    const index = expandedIds.value.indexOf(id)

    // if entity item is already expanded
    if (index > 0 && value) {
        return
    }
    if (index === -1) {
        expandedIds.value.push(id)
    } else {
        expandedIds.value.splice(index, 1)
    }
}

function findActiveDestination() {
    return props.destinations.find(d => d.active)?.key ?? props.destinations[0].key
}
function resetDestination() {
    activeDestination.value = findActiveDestination()
}
function setActiveDestination(destination: DestinationType) {
    activeDestination.value = destination
}

function getSelectedEntities() {
    return (
        props?.entities?.[activeDestination.value]
            ?.filter(item => item.checked || item.children?.some(child => child.checked))
            .map(item => item) ?? []
    )
}

function countCheckedItems(items: Entity[]) {
    let count = 0

    if (!items) {
        return count
    }

    for (let item of items) {
        if (item.checked) {
            count++
        }
        if (item.children) {
            count += countCheckedItems(item.children)
        }
    }
    return count
}

const api = reactive({
    searchQuery,
    empty,
})

provide('selector', {
    expandedIds,
    toggleExpanded,
})

watch(
    () => props.destinations,
    (newValue, oldValue) => {
        const oldKeys = oldValue.map(item => item.key)
        const newKeys = newValue.map(item => item.key)

        const keysChanged =
            oldKeys.length !== newKeys.length ||
            oldKeys.some((key, index) => key !== newKeys[index])

        if (keysChanged) {
            resetDestination()
        }
    }
)
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.keyword-destination-selector {
    @include container;
    @include pa-20;
    @include br-24;
}

.keyword-destination-selector header {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 1rem;
    justify-content: center;
}
.keyword-destination-entity {
    @include container;
    @include br-20;
    overflow: hidden;
}

.custom-entity-container,
.keyword-destination-entity:not(:last-child) {
    @include mb-16;
}
.custom-entity-container:empty {
    @include mb-0;
}

.bottom-row-container:not(:empty) {
    @include mt-24;
}

.parent-entity {
    @include flex;
    @include items-center;
    @include justify-between;
    padding: 1rem 1.5rem;
    cursor: pointer;
    position: relative;
    // @include opteo-focus;
}
.parent-entity.expanded {
    border-bottom: 1px solid;
    @include opteo-border;
}

.expand-button-container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    background-color: white;
    @include flex-center;
    padding-right: 1.5rem;
    padding-left: 0rem;
}

.expand-button-container:before {
    content: '';
    background: #fff;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0, rgb(255, 255, 255) 80%);
    width: 6rem;
    left: -6rem;
    height: calc(100% - 8px);
    top: 4px;
    position: absolute;
    pointer-events: none;
}

:deep(.o-button.o-button--circle.x-small) {
    height: 28px;
    width: 28px;
}
:deep(.o-button.o-button--circle.x-small svg) {
    transform: translateY(1px);
    transition: transform cubic-bezier(0.19, 1, 0.22, 1) 0.25s;
}
:deep(.o-button.o-button--circle.x-small svg.rotate-arrow) {
    transform: translateY(0px) translateX(0.5px) rotate(-90deg);
}

.child-entities {
    max-height: 304px;
}
.child-entity {
    padding: 1rem 1.5rem;
    cursor: pointer;
    // @include opteo-focus;
}
.child-entity:not(.last) {
    border-bottom: 1px solid;
    @include opteo-border;
}

.entity-item {
    @include flex;
    @include items-center;
}
.empty-state {
    @include container;
}

.error-message-wrapper {
    @include container;
    @include pr-24;
    @include pl-16;
    @include pv-20;
    @include br-20;
}

// search input
.search-input {
    height: 100%;
    @include relative;
}
:deep(.search-input label),
:deep(.search-input .o-input__wrapper) {
    display: block;
    height: 100%;
}

:deep(.search-input input) {
    padding: 10px 14px 10px 38px;
    height: 100%;
}
.search-input::before {
    content: '';
    position: absolute;
    left: 14px;
    top: 0;
    bottom: 0;
    width: 16px;
    background: url('@/assets/img/icons/search-icon.svg') center / contain no-repeat;
}
</style>
