import { Account } from '@opteo/types'

export function searchForAccountMatcher(searchQuery: string, account: Account.Info): boolean {
    const nameLower = account.name.toLowerCase()
    const searchLower = searchQuery.toLowerCase()

    const nameMatches = nameLower.includes(searchLower)

    const idMatches = account.idOnPlatform.includes(searchLower)

    return nameMatches || idMatches
}
