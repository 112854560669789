<template>
    <ImprovementView
        :push-messages="pushMessages"
        :push-action-text="pushActionText"
        :is-adjustable="true"
        :on-push="onPush"
    >
        <ImprovementContent>
            <ImprovementHeader :title="title">
                <div v-if="aboveMobile">
                    <EntityPillLink
                        :entityPillData="{ type: 'campaign', content: body.campaign_label }"
                        :deepLinkParams="locationEntityIds"
                    />
                    <Spacer height="2rem" />
                </div>
                <Text as="p">
                    Click
                    <Text as="span" color="green" weight="600">{{ pushActionText }}</Text>
                    to prevent your ads from being displayed on sites in these categories. If your
                    site is directly related to any of the categories below, click <b>Adjust</b> to
                    select each of the categories you wish to exclude for this campaign.
                </Text>
            </ImprovementHeader>
        </ImprovementContent>

        <ImprovementContent>
            <oTable :headers="exclusionsTableHeaders" :items="exclusionsTableItems">
                <template v-slot:[`column.category`]="ctx">
                    <div class="category-row">
                        <img style="width: 24px; height: 24px" :src="ctx.row.image" />
                        <Spacer width="1rem" />
                        <Text as="p" size="f-8">{{ ctx.row.name }}</Text>
                    </div>
                </template>
                <template v-slot:[`column.status`]="ctx">
                    <div class="status-row">
                        <Text color="green" size="f-8" weight="500" v-if="!ctx.value">Allowed</Text>
                        <Text color="red" size="f-8" weight="500" v-if="ctx.value">Blocked</Text>
                    </div>
                </template>
            </oTable>
        </ImprovementContent>

        <ImprovementContent>
            <ImprovementHelpLink path="/en/articles/900481-exclude-site-categories">
                Click here to learn more about excluding site categories in Opteo.
            </ImprovementHelpLink>
        </ImprovementContent>

        <ImprovementContent>
            <ImprovementCreatedDate :last-updated="lastUpdated" :total-days="90" />
        </ImprovementContent>

        <template v-slot:adjust-step-1>
            <ImprovementContent>
                <ImprovementHeader title="Select Categories to Exclude">
                    <div v-if="aboveMobile">
                        <EntityPillLink
                            :entityPillData="{ type: 'campaign', content: body.campaign_label }"
                            :deepLinkParams="locationEntityIds"
                        />
                        <Spacer height="2rem" />
                    </div>
                    <Text as="p">
                        Individually select all of the categories you wish to <b>exclude</b> for the
                        above campaign. Your ads may be displayed on websites in these categories if
                        they are not excluded.
                    </Text>
                </ImprovementHeader>
            </ImprovementContent>
            <ImprovementContent>
                <oTable :headers="excludeTableHeaders" :items="categoriesToExclude">
                    <template v-slot:[`column.category`]="ctx">
                        <div class="category-row">
                            <img style="width: 24px; height: 24px" :src="ctx.row.image" />
                            <Spacer width="1rem" />
                            <Text as="p" size="f-8">{{ ctx.row.name }}</Text>
                        </div>
                    </template>
                    <template v-slot:[`column.status`]="ctx">
                        <div class="status-row">
                            <oInput
                                type="checkbox"
                                label="Exclude Category"
                                :model-value="ctx.row.status"
                                @updated="updateCategoryStatus(ctx.row.id, $event)"
                                :key="ctx.row.id"
                            />
                        </div>
                    </template>
                </oTable>
            </ImprovementContent>
        </template>
    </ImprovementView>
</template>

<script lang="ts">
import {
    ImprovementHeader,
    ImprovementContent,
    ImprovementStatistics,
    ImprovementCreatedDate,
    Money,
    oTable,
    Text,
    Spacer,
    oInput,
    ErrorMessage,
} from '@opteo/components-next'

import ImprovementView from '@/components/improvement/ImprovementView.vue'
import ImprovementHelpLink from '@/components/improvement/ImprovementHelpLink.vue'
import useMediaQuery from '@/composition/global/useMediaQuery'

import { useAddSiteExclusions } from '@/composition/improvement/types/useAddSiteExclusions'
import EntityPillLink from '@/components/global/Entity/EntityPillLink.vue'

export default {
    name: 'AddSiteExclusions',
    components: {
        ImprovementHeader,
        ImprovementContent,
        ImprovementView,
        ImprovementHelpLink,
        ImprovementStatistics,
        ImprovementCreatedDate,
        EntityPillLink,
        Money,
        oTable,
        Text,
        Spacer,
        oInput,
        ErrorMessage,
    },
    setup() {
        return { ...useAddSiteExclusions(), ...useMediaQuery() }
    },
}
</script>
<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.category-row {
    @include flex;
    @include items-center;
}

.adjust-container {
    @include container;
    @include pt-32;
    @include ph-32;
    @include pb-24;
}
:deep(.o-input__checkbox) {
    @include flex;
}

@media screen and (max-width: $mq-480-max) {
    .category-row {
        @include pt-8;
    }
    .status-row {
        @include pt-8;
    }
}
</style>
