<template>
    <ImprovementView
        :push-messages="pushMessages"
        :push-action-text="pushActionText"
        :on-push="onPush"
    >
        <ImprovementContent>
            <ImprovementHeader :title="title">
                <EntityPillList
                    v-if="!aboveMobile && placementName.length > 30"
                    :items="[{ type: 'placement', content: placementName }]"
                />
                <div v-if="aboveMobile">
                    <EntityPill type="placement" :content="placementName" />
                    <Spacer height="2rem" />
                </div>

                <!-- 
                    Applied at Account Level, includes only the following types:
                    mobile_app / youtube_channel / website
                -->
                <div v-if="isAccountLevelExclusion">
                    <!-- Has conversions -->
                    <Text v-if="hasConversions" as="p">
                        <!-- CPA -->
                        <span v-if="performanceMode === PerformanceModeEnum.Cpa">
                            Over the last <b>{{ lookbackWindow }}</b> days, you have spent
                            <b><Money :value="placementMetrics.cost" /></b> on the placement above.
                            Over this time period, this placement has generated
                            <b><Number :value="conversions" :decimalPlaces="2" /></b>
                            {{ conversions === 1 ? 'conversion' : 'conversions' }} with an average
                            CPA of <b><Money :value="placementCpa" /></b>. For context, the average
                            CPA for {{ genericPlacementCopyParagraph }} placement in your account is
                            <b><Money :value="environmentCpa" /></b>. This means the CPA for this
                            placement is
                            <Text as="span" color="red" weight="600">
                                <Percent :value="performanceDiff" :compact="true" />
                            </Text>
                            worse than average. Excluding this placement should help improve your
                            overall CPA.
                        </span>
                        <!-- ROAS -->
                        <span v-else>
                            Over the last <b>{{ lookbackWindow }}</b> days, you have spent
                            <b><Money :value="placementMetrics.cost" /></b> on the placement above.
                            Over this time period, this placement has generated
                            <b>{{ conversionValue }}</b> conversion value with a ROAS of
                            <b><Roas :value="placementRoas" /></b>. For context, the average ROAS
                            for {{ genericPlacementCopyParagraph }} placement in your account is
                            <b><Roas :value="environmentRoas" /></b>. This means the ROAS for this
                            placement is
                            <Text as="span" color="red" weight="600">
                                <Percent :value="performanceDiff" :compact="true" />
                            </Text>
                            worse than average. Excluding this placement should help improve your
                            overall ROAS.
                        </span>
                    </Text>
                    <!-- Zero conversions -->
                    <Text v-else>
                        <!-- CPA -->
                        <span v-if="performanceMode === PerformanceModeEnum.Cpa">
                            Over the last <b>{{ lookbackWindow }}</b> days, you have spent
                            <b><Money :value="placementMetrics.cost" /></b> on the placement above.
                            Over this time period, this placement has generated
                            <b>0</b> conversions. For context, the average CPA for
                            {{ genericPlacementCopyParagraph }} placement in your account is
                            <b><Money :value="environmentCpa" /></b>. Preventing your ads from being
                            shown via this placement should help reduce your overall CPA.
                        </span>
                        <!-- ROAS -->
                        <span v-else>
                            Over the last <b>{{ lookbackWindow }}</b> days, you have spent
                            <b><Money :value="placementMetrics.cost" /></b> on the placement above.
                            Over this time period, this placement has generated
                            <b>$0.00</b> conversion value with a ROAS of <b>0.00%</b>. For context,
                            the average ROAS for {{ genericPlacementCopyParagraph }} placement in
                            your account is <b><Roas :value="environmentRoas" /></b>. Excluding this
                            placement should improve your overall ROAS.
                        </span>
                    </Text>
                    <Spacer height="1rem" />
                    <Text>
                        Opteo recommends creating an exclusion for
                        <b>this placement</b>.
                    </Text>
                    <Spacer height="1rem" />
                    <Text>
                        Click
                        <Text as="span" weight="600" color="green">{{ pushActionText }}</Text> to
                        apply this exclusion to all campaigns.
                    </Text>
                </div>

                <!-- 
                    Applied to Display Campaigns, includes only the following types:
                    mobile_all / youtube_all / gmail
                -->
                <div v-else>
                    <!-- Has conversions -->
                    <Text v-if="hasConversions" as="p">
                        <!-- CPA -->
                        <span v-if="performanceMode === PerformanceModeEnum.Cpa">
                            Over the last <b>{{ lookbackWindow }}</b> days, you have spent
                            <b><Money :value="placementMetrics.cost" /></b> on
                            {{ genericPlacementCopyParagraph }} placements. Over this time period,
                            these placements have generated
                            <b><Number :value="conversions" :decimalPlaces="2" /></b>
                            {{ conversions === 1 ? 'conversion' : 'conversions' }} with an average
                            CPA of <b><Money :value="placementCpa" /></b>. For context, your display
                            campaigns have an average CPA of
                            <b><Money :value="environmentCpa" /></b>. This means the CPA for
                            {{ genericPlacementCopyParagraph }} placements is
                            <Text as="span" color="red" weight="600">
                                <Percent :value="performanceDiff" :compact="true" />
                            </Text>
                            worse than average. Excluding these ad placements should improve your
                            overall CPA.
                        </span>
                        <!-- ROAS -->
                        <span v-else>
                            Over the last <b>{{ lookbackWindow }}</b> days, you have spent
                            <b><Money :value="placementMetrics.cost" /></b> on
                            {{ genericPlacementCopyParagraph }} placements. Over this time period,
                            these placements have generated <b>{{ conversionValue }}</b> conversion
                            value with a ROAS of <b><Roas :value="placementRoas" /></b>. For
                            context, your display campaigns have an average ROAS of
                            <b><Roas :value="environmentRoas" /></b>. This means the ROAS for
                            {{ genericPlacementCopyParagraph }} placements is
                            <Text as="span" color="red" weight="600">
                                <Percent :value="performanceDiff" :compact="true" />
                            </Text>
                            worse than average. Excluding these ad placements should improve your
                            overall ROAS.
                        </span>
                    </Text>
                    <!-- Zero conversions -->
                    <Text v-else>
                        <!-- CPA -->
                        <span v-if="performanceMode === PerformanceModeEnum.Cpa">
                            Over the last <b>{{ lookbackWindow }}</b> days, you have spent
                            <b><Money :value="placementMetrics.cost" /></b> on
                            {{ genericPlacementCopy }} ad placements. Over this time period, these
                            placements have generated <b>0</b> conversions. For context, your
                            display campaigns have an average CPA of
                            <b><Money :value="environmentCpa" /></b>. Preventing your ads from being
                            shown via these placements should help reduce your overall CPA.
                        </span>
                        <!-- ROAS -->
                        <span v-else>
                            Over the last <b>{{ lookbackWindow }}</b> days, you have spent
                            <b><Money :value="placementMetrics.cost" /></b> on
                            {{ genericPlacementCopy }} ad placements. Over this time period, these
                            placements have generated <b>$0.00</b> conversion value with a ROAS of
                            <b>0.00%</b>. For context, your display campaigns have an average ROAS
                            of <b><Roas :value="environmentRoas" /></b>. Excluding these ad
                            placements should improve your overall ROAS.
                        </span>
                    </Text>
                    <Spacer height="1rem" />
                    <Text>
                        Opteo recommends creating an exclusion for
                        <b>all {{ genericPlacementCopy }} placements</b>.
                    </Text>
                    <Spacer height="1rem" />
                    <Text>
                        Click
                        <Text as="span" weight="600" color="green">{{ pushActionText }}</Text> to
                        apply this exclusion to all display campaigns.
                    </Text>
                    <Spacer height="2rem" />
                    <!-- Campaigns -->
                    <div v-if="campaignPillList.length === 1">
                        <EntityPillLinkList
                            v-if="
                                !aboveMobile && campaignPillList[0].entityPillData.type.length > 30
                            "
                            :items="campaignPillList"
                        />
                        <EntityPillLink
                            v-else
                            :entity-pill-data="campaignPillList[0].entityPillData"
                            :deep-link-params="campaignPillList[0].deepLinkParams"
                        />
                    </div>
                    <EntityPillLinkList v-else :items="campaignPillList" />
                </div>
            </ImprovementHeader>
            <ImprovementHelpLink path="/en/articles/902702-exclude-site-placement">
                Click here to learn more about the benefits of content exclusions.
            </ImprovementHelpLink>
        </ImprovementContent>

        <ImprovementContent>
            <!-- Website Placement Preview -->
            <SerpPreview
                v-if="exclusionType === ExclusionTypeEnum.WEBSITE"
                :image-url="placementPreviewUrl"
                title="Website Placement"
            />
            <!-- Gmail Placement Preview -->
            <GmailPlacementPreview v-if="exclusionType === ExclusionTypeEnum.GMAIL" />

            <!-- YouTube Placement Preview -->
            <YoutubePlacementPreview v-if="exclusionType === ExclusionTypeEnum.YOUTUBE_ALL" />

            <!-- YouTube Channel Placement Preview -->
            <div v-if="exclusionType === ExclusionTypeEnum.YOUTUBE_CHANNEL">
                <!-- Opportunity: Swap out for YouTubeChannel component -->
                <YoutubeChannelOverview
                    :title="youtubeChannelMetadata.title"
                    :description="youtubeChannelMetadata.description"
                    :subscribers="youtubeChannelMetadata.subscriber_count"
                    :thumbnailUrl="youtubeChannelMetadata.thumbnail_url"
                    :topics="youtubeChannelMetadata.topics"
                    :channelUrl="youtubeChannelMetadata.channelUrl"
                />
            </div>

            <!-- Single Mobile App Placement Preview -->
            <div v-if="exclusionType === ExclusionTypeEnum.MOBILE_APP">
                <MobileAppGrid title="Mobile App Placement" :items="mobileApps" />
            </div>

            <!-- All Mobile Apps Placement Preview -->
            <div v-if="exclusionType === ExclusionTypeEnum.MOBILE_ALL">
                <MobileAppGrid :items="mobileApps" />
            </div>

            <!-- Scatter Point Chart (Top 50 Placements) -->
            <div v-if="isAccountLevelExclusion">
                <Spacer height="3rem" />
                <Text as="h4" weight="600">Placement {{ performanceModeCopy }} Comparison</Text>
                <Spacer height="2rem" />
                <ScatterPointChart
                    v-if="performanceMode === PerformanceModeEnum.Cpa"
                    :items="scatterPointChartItems"
                    :graphKeys="['Needs Attention', 'Above CPA Average', 'Below CPA Average']"
                    :cutOff="environmentCpa"
                    :axis="{
                        x: { label: 'Conversions' },
                        y: { label: 'Cost', dataType: 'money', currency: currencyCode },
                    }"
                    :metric="{
                        label: 'CPA',
                        dataType: 'money',
                        currency: currencyCode,
                        inverted: true,
                    }"
                    chartTooltipMaxWidth="14.5rem"
                />
                <ScatterPointChart
                    v-if="performanceMode === PerformanceModeEnum.Roas"
                    :items="scatterPointChartItems"
                    :graphKeys="['Above ROAS Average', 'Below ROAS Average', 'Needs Attention']"
                    :cutOff="environmentRoas"
                    :axis="{
                        x: { label: 'Cost', dataType: 'money', currency: currencyCode },
                        y: {
                            label: 'Conversion Value',
                            dataType: 'money',
                            currency: currencyCode,
                        },
                    }"
                    :metric="{
                        label: 'ROAS',
                        inverted: false,
                        dataType: 'percentage',
                    }"
                    chartTooltipMaxWidth="14.5rem"
                />
            </div>
        </ImprovementContent>

        <ImprovementContent>
            <ImprovementNote>
                <b>Note:</b> Opteo sums conversions and view-through conversions for the conversion
                types selected in campaign groups. View-through conversions are common for display
                ads.
            </ImprovementNote>
        </ImprovementContent>

        <ImprovementContent>
            <ImprovementStatistics :items="stats">
                <template v-slot:cost>
                    You spent <b><Money :value="placementMetrics.cost" /></b> on
                    {{
                        isAccountLevelExclusion
                            ? 'this placement'
                            : genericPlacementCopy + ' placements'
                    }}
                    over the last <b>{{ lookbackWindow }}</b> days.
                </template>

                <template v-slot:conversions>
                    <span v-if="isAccountLevelExclusion">This placement</span>
                    <span v-else>
                        <span v-if="exclusionType === 'mobile_all'">Mobile app placements</span>
                        <span v-else>{{ genericPlacementCopy }} placements</span>
                    </span>
                    generated
                    <b><Number :value="placementMetrics.combined_conv" /></b>
                    conversion<span v-if="placementMetrics.combined_conv !== 1">s</span> over the
                    last <b>{{ lookbackWindow }}</b> days.
                    <!-- and <b><Number :value="placementMetrics.clicks" :compact="true" /></b> clicks -->
                </template>

                <template v-slot:conversionsValue>
                    <span v-if="isAccountLevelExclusion">This placement</span>
                    <span v-else>
                        <span v-if="exclusionType === 'mobile_all'">Mobile app placements</span>
                        <span v-else>{{ genericPlacementCopy }} placements</span>
                    </span>
                    generated
                    <b><Number :value="placementMetrics.combined_conv" :compact="true" /></b>
                    conversion<span v-if="placementMetrics.combined_conv !== 1">s</span> with a
                    total conversion value of
                    <b><Money :value="placementMetrics.combined_conv_value" /></b> over the last
                    <b>{{ lookbackWindow }}</b> days.
                </template>

                <template v-slot:placementCpa>
                    <span v-if="isAccountLevelExclusion">This placement has</span>
                    <span v-else>
                        <span v-if="exclusionType === 'mobile_all'">
                            Mobile app placements have
                        </span>
                        <span v-else>{{ genericPlacementCopy }} placements have</span>
                    </span>
                    an average CPA of <b><Money :value="placementCpa" /></b> over the last
                    <b>{{ lookbackWindow }}</b> days.
                </template>

                <template v-slot:placementRoas>
                    <span v-if="isAccountLevelExclusion">This placement has</span>
                    <span v-else>
                        <span v-if="exclusionType === 'mobile_all'">
                            Mobile app placements have
                        </span>
                        <span v-else>{{ genericPlacementCopy }} placements have</span>
                    </span>
                    a ROAS of <b><Roas :value="placementRoas" /></b> over the last
                    <b>{{ lookbackWindow }}</b> days.
                </template>

                <template v-slot:envCpa>
                    Your
                    {{
                        isAccountLevelExclusion
                            ? genericPlacementCopy + ' placements'
                            : 'display campaigns'
                    }}
                    have an average CPA of <b><Money :value="environmentCpa" /></b> over the last
                    <b>{{ lookbackWindow }}</b> days.
                </template>

                <template v-slot:envRoas>
                    Your
                    {{
                        isAccountLevelExclusion
                            ? genericPlacementCopy + ' placements'
                            : 'display campaigns'
                    }}
                    have an average ROAS of <b><Roas :value="environmentRoas" /></b> over the last
                    <b>{{ lookbackWindow }}</b> days.
                </template>
            </ImprovementStatistics>
        </ImprovementContent>

        <ImprovementContent>
            <ImprovementCreatedDate :last-updated="lastUpdated" :total-days="lookbackWindow" />
        </ImprovementContent>
    </ImprovementView>
</template>

<script setup lang="ts">
import {
    ImprovementHeader,
    ImprovementContent,
    ImprovementCreatedDate,
    EntityPill,
    EntityPillList,
    Text,
    Spacer,
    SerpPreview,
    ImprovementStatistics,
    Number,
    MobileAppGrid,
    ImprovementNote,
    ScatterPointChart,
    Percent,
    Roas,
    YoutubeChannelOverview,
} from '@opteo/components-next'
import { EntityPillLink, EntityPillLinkList } from '@/components/global/Entity'
import YoutubePlacementPreview from '@/components/improvement/types/content-exclusions/YoutubePlacementPreview.vue'
import GmailPlacementPreview from '@/components/improvement/types/content-exclusions/GmailPlacementPreview.vue'
import ImprovementView from '@/components/improvement/ImprovementView.vue'
import ImprovementHelpLink from '@/components/improvement/ImprovementHelpLink.vue'
import Money from '@/components/global/oMoney.vue'
import useMediaQuery from '@/composition/global/useMediaQuery'

import { useExcludeContent } from '@/composition/improvement/types/useExcludeContent'
import { useDomain } from '@/composition/domain/useDomain'

const { currencyCode } = useDomain()
const { aboveMobile } = useMediaQuery()
const {
    pushMessages,
    pushActionText,
    onPush,
    lastUpdated,
    campaignPillList,
    stats,
    title,
    lookbackWindow,
    placementPreviewUrl,
    isAccountLevelExclusion,
    hasConversions,
    performanceModeCopy,
    exclusionType,
    ExclusionTypeEnum,
    placementName,
    placementMetrics,
    scatterPointChartItems,
    performanceDiff,
    genericPlacementCopy,
    genericPlacementCopyParagraph,
    mobileApps,
    youtubeChannelMetadata,
    performanceMode,
    PerformanceModeEnum,
    environmentCpa,
    placementCpa,
    environmentRoas,
    placementRoas,
    conversionValue,
    conversions,
} = useExcludeContent()
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';
</style>
