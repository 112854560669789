<template>
    <ImprovementView
        :push-messages="pushMessages"
        :push-action-text="pushActionText"
        :is-adjustable="true"
        :on-push="onPush"
        :title="title"
        @back-clicked="resetBudget"
    >
        <ImprovementContent>
            <ImprovementHeader :title="title">
                <div v-if="aboveMobile">
                    <EntityPillLink
                        :entityPillData="{ type: 'campaign', content: campaignName }"
                        :deep-link-params="locationEntityData.deepLinkParams"
                    />
                    <Spacer height="2rem" />
                </div>
                <!-- ROAS -->
                <Text v-if="isUsingRoas">
                    This campaign is limited by budget, but performing well relative to your target
                    ROAS. Opteo recommends raising your daily budget from
                    <b><Money :value="budget" /></b> to
                    <b><Money :value="budget * 1.3" /></b>
                    <span class="fw6 opteo-green"> (<Percent :value="0.3" include-sign />)</span>.
                    This should increase your campaign group ROAS from
                    <b><Roas :value="accountRoas" /></b> to
                    <b><Roas :value="accountRoasAfterChange" /></b>
                    <b class="opteo-green"> (<Percent :value="roasChangePercent" include-sign />)</b
                    >.
                    <Spacer height="1rem" />
                    Click
                    <Text as="span" color="green" weight="600">{{ pushActionText }}</Text> to raise
                    your campaign budget cap by <b><Percent :value="0.3" /></b>. Click
                    <b>Adjust</b> to set a specific budget amount. Otherwise, feel free to
                    <b>Dismiss</b> this improvement.
                </Text>
                <!-- CPA -->
                <Text v-else>
                    This campaign is limited by budget, but performing well relative to your target
                    CPA. Opteo recommends raising your daily budget from
                    <b><Money :value="budget" /></b> to <b><Money :value="budget * 1.3" /></b>
                    <span class="fw6 opteo-green"> (<Percent :value="0.3" include-sign />)</span>.
                    This should reduce your campaign group CPA from
                    <b><Money :value="accountCpa" /></b> to
                    <b><Money :value="accountCpaAfterChange" /></b>
                    <b class="opteo-green"> (<Percent :value="cpaChangePercent" />)</b>.
                    <Spacer height="1rem" />
                    Click
                    <Text as="span" color="green" weight="600">{{ pushActionText }}</Text> to raise
                    your campaign budget cap by <b><Percent :value="0.3" /></b>. Click
                    <b>Adjust</b> to set a specific budget amount. Otherwise, feel free to
                    <b>Dismiss</b> this improvement.
                </Text>
            </ImprovementHeader>
            <ImprovementHelpLink path="/improvements/campaigns/lift-campaign-budget-cap">
                Learn how increasing campaign budget caps can reduce overall
                {{ isUsingRoas ? 'ROAS' : 'CPA' }}.
            </ImprovementHelpLink>
        </ImprovementContent>

        <ImprovementContent>
            <Text as="h3" size="f-4" weight="600">Improvement Projections</Text>
            <Spacer height="2rem" />
            <Text as="p">
                Below are a series of key metrics from the
                <b>last {{ daysWindow }} days</b> compared with predictions for the
                <b>next {{ daysWindow }} days</b> if the budget cap is raised by the recommended
                amount.
            </Text>
            <Spacer height="2rem" />
            <ImprovementStatistics title="" :items="statistics">
                <template v-slot:costDelta>
                    Cost should rise proportionately with your budget.
                </template>
                <template v-slot:clicksDelta>
                    Clicks should rise proportionately with your budget.
                </template>
                <template v-slot:conversionsDelta>
                    Conversions should rise proportionately with your budget.
                </template>
                <template v-slot:campaignCpaDelta>
                    Campaign group CPA should decrease, resulting in more conversions for your
                    spend.
                </template>
                <template v-slot:conversionsValueDelta>
                    Conversion value should rise proportionately with your budget.
                </template>
                <template v-slot:campaignRoasDelta>
                    Campaign group ROAS should increase, resulting in higher value for your spend.
                </template>
            </ImprovementStatistics>
        </ImprovementContent>

        <template v-slot:adjust-step-1>
            <ImprovementContent>
                <ImprovementHeader title="Adjust Campaign Budget">
                    <div v-if="aboveMobile">
                        <EntityPillLink
                            :entityPillData="locationEntityData.entityPillData"
                            :deep-link-params="locationEntityData.deepLinkParams"
                        />
                        <Spacer height="2rem" />
                    </div>
                    <!-- ROAS -->
                    <Text v-if="isUsingRoas">
                        This campaign is limited by budget, but performing well relative to your
                        target ROAS. Opteo recommends raising your daily budget from
                        <b><Money :value="budget" /></b> to
                        <b><Money :value="budget * 1.3" /></b>
                        <span class="fw6 opteo-green"> (<Percent :value="0.3" include-sign />)</span
                        >. This should increase your campaign group ROAS from
                        <b><Roas :value="accountRoas" /></b> to
                        <b><Roas :value="accountRoasAfterChange" /></b>
                        <b class="opteo-green">
                            (<Percent :value="roasChangePercent" include-sign />)</b
                        >.
                    </Text>
                    <!-- CPA -->
                    <Text v-else>
                        This campaign is limited by budget, but performing well relative to your
                        target CPA. Opteo recommends raising your daily budget from
                        <b><Money :value="budget" /></b> to <b><Money :value="budget * 1.3" /></b>
                        <span class="fw6 opteo-green"> (<Percent :value="0.3" include-sign />)</span
                        >. This should reduce your campaign group CPA from
                        <b><Money :value="accountCpa" /></b> to
                        <b><Money :value="accountCpaAfterChange" /></b>
                        <b class="opteo-green"> (<Percent :value="cpaChangePercent" />)</b>.
                    </Text>
                </ImprovementHeader>

                <InlineInputContainer>
                    <template #mobile>
                        <Text as="span" size="f-8">Set your daily campaign budget to</Text>
                    </template>
                    <template #left-side>
                        <Text as="span" size="f-8">Set campaign budget to</Text>
                    </template>
                    <template #input>
                        <oInput
                            v-model="newBudget"
                            type="number"
                            :prefix="currencySymbol"
                            autofocus
                            class="adjustment-input"
                        />
                    </template>
                    <template #right-side>
                        <Text as="span" size="f-8" style="line-height: 2.8125rem">per day.</Text>
                    </template>
                </InlineInputContainer>
            </ImprovementContent>
        </template>

        <ImprovementContent>
            <ImprovementCreatedDate :last-updated="lastUpdated" :total-days="daysWindow" />
        </ImprovementContent>
    </ImprovementView>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import ImprovementView from '@/components/improvement/ImprovementView.vue'
import ImprovementHelpLink from '@/components/improvement/ImprovementHelpLink.vue'
import ImprovementCopy from '@/components/improvement/ImprovementCopy.vue'
import InlineInputContainer from '@/components/improvement/shared-components/InlineInputContainer.vue'
import Money from '@/components/global/oMoney.vue'
import { useLiftCampaignBudgetCap } from '@/composition/improvement/types/useLiftCampaignBudgetCap'
import useMediaQuery from '@/composition/global/useMediaQuery'

import {
    ImprovementHeader,
    ImprovementContent,
    Spacer,
    Percent,
    Text,
    ImprovementStatistics,
    ImprovementCreatedDate,
    oInput,
    Ent,
    Roas,
} from '@opteo/components-next'
import { EntityPillLink } from '@/components/global/Entity'

export default defineComponent({
    name: 'LiftCampaignBudgetCap',
    components: {
        ImprovementView,
        ImprovementContent,
        ImprovementHeader,
        ImprovementHelpLink,
        Spacer,
        Money,
        Percent,
        Text,
        ImprovementStatistics,
        ImprovementCopy,
        ImprovementCreatedDate,
        InlineInputContainer,
        oInput,
        EntityPillLink,
        Ent,
        Roas,
    },
    setup() {
        return { ...useLiftCampaignBudgetCap(), ...useMediaQuery() }
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

// Responsive

@media (min-width: 767px) {
    .adjustment-input {
        width: 8rem;
    }
}
</style>
