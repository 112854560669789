<template>
    <ImprovementView
        :push-messages="pushMessages"
        :push-action-text="pushActionText"
        :is-adjustable="true"
        :step="adjustSteps"
        :on-push="onPush"
        @adjust-reset="onReset"
    >
        <!-- Improvement Header -->
        <ImprovementContent>
            <ImprovementHeader :title="title">
                <div v-if="aboveMobile">
                    <EntityPillLink
                        :entity-pill-data="{ type: campaign.entityType, content: campaign.name }"
                        :deep-link-params="{ campaignId: campaign.id }"
                    />
                    <Spacer width="100%" height="1.5rem" />
                </div>
                <Text as="p">
                    The campaign above is using a Target {{ isCpaMode ? 'CPA' : 'ROAS' }} bid
                    strategy and could benefit from ad group specific target adjustments. This
                    should help to {{ isCpaMode ? 'reduce CPA' : 'increase ROAS' }} and allow for
                    smarter budget allocation. Targets will be applied to the ad groups listed
                    below.
                </Text>

                <Spacer height="1rem" />

                <Text as="p">
                    Click <Text as="span" weight="600" color="green">{{ pushActionText }}</Text> to
                    apply the recommended targets to the listed ad groups. If you need to use
                    different {{ isCpaMode ? 'CPA targets' : 'percentages' }} to those suggested,
                    click <b>Adjust</b>.
                </Text>
            </ImprovementHeader>

            <ImprovementHelpLink path="/articles/8102723-adjust-ad-group-targets">
                Click here to learn more about adjusting ad group targets in Opteo.
            </ImprovementHelpLink>
        </ImprovementContent>

        <!-- Improvement Table -->
        <ImprovementContent :fullWidth="true">
            <Spacer height="1rem" />
            <oTable
                :headers="table.headers"
                :items="table.items"
                order-by="cost"
                :order="'DESC'"
                :fixed-layout="true"
                :per-page="aboveMobile ? 8 : 1"
                responsive-mode="columns"
                responsive-breakpoint="1120px"
                :border-radius="24"
            >
                <template #header.adGroup>
                    <div class="adgroup-header">Ad Group</div>
                </template>
                <template
                    #column.adGroup="{
                        value: adGroupName,
                        row: { id: adGroupId, campaignId },
                    }: {
                        value: string
                        row: { id: number; campaignId: number }
                    }"
                >
                    <div class="adgroup-label-column">
                        <Tooltip
                            :content="adGroupName"
                            v-if="adGroupName.length > 40"
                            :offset="[0, 8]"
                            placement="top-start"
                        >
                            <EntityPillLink
                                :entity-pill-data="{
                                    type: 'adgroup',
                                    content: adGroupName,
                                    tooltip: false,
                                }"
                                :accountId="accountId"
                                :deep-link-params="{ adGroupId, campaignId }"
                            />
                        </Tooltip>
                        <EntityPillLink
                            v-else
                            :entity-pill-data="{
                                type: 'adgroup',
                                content: adGroupName,
                                tooltip: false,
                            }"
                            :accountId="accountId"
                            :deep-link-params="{ adGroupId, campaignId }"
                        />
                    </div>
                </template>

                <template #column.cost="cost">
                    <Money :value="cost.value" :currency="currencyCode" />
                </template>

                <template #column.difference="difference">
                    <Text
                        v-if="
                            getDifferenceResult({
                                differenceValue: difference.value,
                                isCpaMode,
                            }) === 'negative'
                        "
                        as="span"
                        color="red"
                        weight="500"
                    >
                        {{ isCpaMode ? '+' : ''
                        }}<Percent
                            :value="difference.value"
                            :decimal-places="setDecimalPlaces(difference.value)"
                        />
                    </Text>
                    <Text
                        v-else-if="
                            getDifferenceResult({
                                differenceValue: difference.value,
                                isCpaMode,
                            }) === 'neutral'
                        "
                        as="span"
                        color="black"
                        weight="400"
                    >
                        <Percent
                            :value="difference.value"
                            :decimal-places="setDecimalPlaces(difference.value)"
                        />
                    </Text>
                    <Text
                        v-else-if="
                            getDifferenceResult({
                                differenceValue: difference.value,
                                isCpaMode,
                            }) === 'positive'
                        "
                        as="span"
                        color="green"
                        weight="500"
                    >
                        {{ isCpaMode ? '' : '+'
                        }}<Percent
                            :value="difference.value"
                            :decimal-places="setDecimalPlaces(difference.value)"
                        />
                    </Text>
                    <Text
                        v-else-if="
                            getDifferenceResult({
                                differenceValue: difference.value,
                                isCpaMode,
                            }) === 'n/a'
                        "
                        as="span"
                        color="black"
                        weight="400"
                    >
                        N/A
                    </Text>
                </template>

                <template #column.newTarget="newTarget">
                    <template v-if="newTarget.value.isValid">
                        <div style="display: flex; align-items: center">
                            <Money
                                v-if="isCpaMode"
                                :value="newTarget.value.rawValue"
                                :currency="currencyCode"
                            />
                            <Roas v-else :value="newTarget.value.rawValue" :decimal-places="0" />
                            <Spacer width="0.25rem" height="0.25rem" />
                            <!-- CPA Mode Diff. -->
                            <div v-if="isCpaMode">
                                <Text
                                    :color="
                                        newTarget.value.rawValue >
                                        newTarget.value.rawExistingTargetValue
                                            ? 'green'
                                            : 'red'
                                    "
                                    as="span"
                                    size="f-8"
                                    weight="500"
                                >
                                    (<Percent
                                        :value="
                                            getNewTargetDifference({
                                                existingTargetValue:
                                                    newTarget.value.rawExistingTargetValue,
                                                newTargetValue: newTarget.value.rawValue,
                                            })
                                        "
                                        includeSign
                                    />)
                                </Text>
                            </div>
                            <!-- ROAS Mode Diff. -->
                            <div v-else>
                                <Text
                                    :color="
                                        newTarget.value.rawValue >
                                        newTarget.value.rawExistingTargetValue
                                            ? 'red'
                                            : 'green'
                                    "
                                    as="span"
                                    size="f-8"
                                    weight="500"
                                >
                                    (<Percent
                                        :value="
                                            getNewTargetDifference({
                                                existingTargetValue:
                                                    newTarget.value.rawExistingTargetValue,
                                                newTargetValue: newTarget.value.rawValue,
                                            })
                                        "
                                        includeSign
                                    />)
                                </Text>
                            </div>
                        </div>
                    </template>

                    <NotApplicablePill
                        v-else
                        :content="newTarget.value.invalidReason"
                        :max-width="newTarget.value.width"
                    />
                </template>
            </oTable>
            <Spacer height="1rem" />
        </ImprovementContent>

        <!-- Scatter Point Chart -->
        <ImprovementContent noMargin>
            <Text as="h3" size="f-4" weight="600">Ad Group Comparison</Text>
            <Spacer height="2rem" />
        </ImprovementContent>
        <ImprovementContent :fullWidth="true">
            <div class="chart-container">
                <div class="chart-wrapper">
                    <ScatterPointChart
                        :items="scatterPointChart.items"
                        :graphKeys="scatterPointChart.keys"
                        :cutOff="scatterPointChart.cutOff"
                        :axis="scatterPointChart.axis"
                        :metric="scatterPointChart.metric"
                    />
                </div>
            </div>
            <Spacer height="1rem" />
        </ImprovementContent>

        <!-- Improvement Statistics -->
        <ImprovementContent>
            <ImprovementStatistics :items="improvementStatistics">
                <template v-slot:spend>
                    The recommended adjustments affect
                    <b>{{ improvementStatistics[0].value }}</b> total spend.
                </template>
                <template v-slot:adjustments>
                    Opteo recommends adjusting targets for
                    <b>{{ improvementStatistics[1].value }}</b> ad groups in this campaign.
                </template>
                <template v-slot:significantAdjustment>
                    The most significant recommended adjustment is
                    <b>{{ improvementStatistics[2].value }}</b
                    >.
                </template>
                <template v-slot:potentialSavings>
                    The recommended adjustments could result in a potential saving of
                    <b>{{
                        improvementStatistics.find(stat => stat.key === 'potentialSavings')?.value
                    }}</b
                    >.
                </template>
                <template v-slot:potentialExtraPerformance>
                    The recommended adjustments could generate an extra
                    <b>{{
                        improvementStatistics.find(stat => stat.key === 'potentialExtraPerformance')
                            ?.value
                    }}</b>
                    {{ isCpaMode ? 'conversions' : 'conversion value' }}.
                </template>
            </ImprovementStatistics>
        </ImprovementContent>

        <!-- Improvement Created Date -->
        <ImprovementContent>
            <ImprovementCreatedDate :last-updated="lastUpdated" :total-days="lookbackWindow" />
        </ImprovementContent>

        <!-- Adjust Step -->
        <template #adjust-step-1>
            <ImprovementContent :no-margin="true">
                <ImprovementHeader title="Custom Target Adjustments">
                    <Text as="p">
                        Set custom ad group targets by clicking the pen icon next to the target
                        value in the table below. Click
                        <Text as="span" color="green" weight="600"> {{ pushActionText }}</Text> to
                        push your changes to Google Ads.
                    </Text>
                </ImprovementHeader>
            </ImprovementContent>

            <ImprovementContent :fullWidth="true">
                <Spacer height="1rem" />
                <oTable
                    :headers="adjustTableHeaders"
                    :items="table.items"
                    order-by="cost"
                    :order="'DESC'"
                    :fixed-layout="true"
                    :per-page="aboveMobile ? 8 : 1"
                    responsive-mode="columns"
                    responsive-breakpoint="1120px"
                    :border-radius="20"
                >
                    <template #header.adGroup>
                        <div class="adgroup-header">Ad Group</div>
                    </template>
                    <template
                        #column.adGroup="{
                            value: adGroupName,
                            row: { id: adGroupId, campaignId },
                        }: {
                            value: string
                            row: { id: number; campaignId: number }
                        }"
                    >
                        <div class="adgroup-label-column">
                            <Tooltip
                                :content="adGroupName"
                                v-if="adGroupName.length > 40"
                                :offset="[0, 8]"
                                placement="top-start"
                            >
                                <EntityPillLink
                                    :entity-pill-data="{
                                        type: 'adgroup',
                                        content: adGroupName,
                                        tooltip: false,
                                    }"
                                    :deep-link-params="{ adGroupId, campaignId }"
                                />
                            </Tooltip>
                            <EntityPillLink
                                v-else
                                :entity-pill-data="{
                                    type: 'adgroup',
                                    content: adGroupName,
                                    tooltip: false,
                                }"
                                :deep-link-params="{ adGroupId, campaignId }"
                            />
                        </div>
                    </template>

                    <template #column.cost="cost">
                        <Money :value="cost.value" :currency="currencyCode" />
                    </template>

                    <template #column.difference="difference">
                        <Text
                            v-if="
                                getDifferenceResult({
                                    differenceValue: difference.value,
                                    isCpaMode,
                                }) === 'negative'
                            "
                            as="span"
                            color="red"
                            weight="500"
                        >
                            {{ isCpaMode ? '+' : ''
                            }}<Percent
                                :value="difference.value"
                                :decimal-places="setDecimalPlaces(difference.value)"
                            />
                        </Text>
                        <Text
                            v-else-if="
                                getDifferenceResult({
                                    differenceValue: difference.value,
                                    isCpaMode,
                                }) === 'neutral'
                            "
                            as="span"
                            color="black"
                            weight="400"
                        >
                            <Percent
                                :value="difference.value"
                                :decimal-places="setDecimalPlaces(difference.value)"
                            />
                        </Text>
                        <Text
                            v-else-if="
                                getDifferenceResult({
                                    differenceValue: difference.value,
                                    isCpaMode,
                                }) === 'positive'
                            "
                            as="span"
                            color="green"
                            weight="500"
                        >
                            {{ isCpaMode ? '' : '+'
                            }}<Percent
                                :value="difference.value"
                                :decimal-places="setDecimalPlaces(difference.value)"
                            />
                        </Text>
                        <Text
                            v-else-if="
                                getDifferenceResult({
                                    differenceValue: difference.value,
                                    isCpaMode,
                                }) === 'n/a'
                            "
                            as="span"
                            color="black"
                            weight="400"
                        >
                            N/A
                        </Text>
                    </template>

                    <template #column.newTarget="newTarget">
                        <AdjustTargetCell
                            :new-target="newTarget.value"
                            :existing-target="
                                isCpaMode
                                    ? newTarget.value.rawExistingTargetValue
                                    : newTarget.value.rawExistingTargetValue * 100
                            "
                            :is-cpa-mode="isCpaMode"
                            :on-multi-target-update="onMultiTargetUpdate"
                            @update="onMultiTargetUpdate"
                        />
                    </template>
                </oTable>
            </ImprovementContent>
        </template>
    </ImprovementView>
</template>

<script lang="ts" setup>
import {
    useAdjustAdGroupMultiTargets,
    getNewTargetDifference,
    setDecimalPlaces,
} from '@/composition/improvement/types/useAdjustAdGroupMultiTargets'
import useMediaQuery from '@/composition/global/useMediaQuery'

import {
    ImprovementContent,
    ImprovementHeader,
    ImprovementCreatedDate,
    ImprovementStatistics,
    oTable,
    Spacer,
    Text,
    Money,
    Percent,
    Roas,
    ScatterPointChart,
    Tooltip,
} from '@opteo/components-next'
import { EntityPillLink } from '@/components/global/Entity'
import NotApplicablePill from '../../shared-components/NotApplicablePill.vue'
import AdjustTargetCell from '../../shared-components/AdjustTargetCell.vue'
import ImprovementView from '@/components/improvement/ImprovementView.vue'
import ImprovementHelpLink from '@/components/improvement/ImprovementHelpLink.vue'

const {
    onPush,
    onReset,
    getDifferenceResult,
    onMultiTargetUpdate,

    title,
    currencyCode,
    lookbackWindow,
    lastUpdated,
    pushActionText,
    pushMessages,
    adjustSteps,
    improvementStatistics,

    isCpaMode,
    campaign,
    table,
    adjustTableHeaders,
    scatterPointChart,
    accountId,
} = useAdjustAdGroupMultiTargets()

const { aboveMobile } = useMediaQuery()
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.chart-container {
    @include container;
    @include br-24;
    @include pv-64;
    width: 100%;
    @include flex-center;
}
.chart-wrapper {
    width: 100%;
    max-width: 48rem;
}

.adgroup-label-column {
    max-width: 100%;
    overflow: hidden;
    @include flex;
    @include items-center;
    height: 4.5rem;
    @include pl-24;
}
.adgroup-label-column::after {
    content: '';
    background: linear-gradient(270deg, rgba(255, 255, 255, 1) 1.5rem, rgba(255, 255, 255, 0) 100%);
    @include absolute;
    top: 0;
    right: 0;
    width: 4rem;
    bottom: 0;
}

.adgroup-header {
    @include pl-24;
}

@media screen and (max-width: 1120px) {
    .adgroup-header {
        @include pl-0;
    }
    .adgroup-label-column {
        display: block;
        height: auto;
        @include pl-0;
    }
    .adgroup-label-column::after {
        content: '';
        background: none;
    }
}
</style>
