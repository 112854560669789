<template>
    <oTable
        :headers="detailedViewSearchTermHeaders"
        :items="searchTerms"
        order-by="cost"
        order="DESC"
        fixed-layout
        :border-radius="24"
        paginationMethod="intersection"
        :intersectionOptions="{
            rootMargin: '0px',
            threshold: 1,
        }"
    >
        <template #column.searchTerm="{ value }">
            <EntityPill
                v-if="value"
                :type="Improvement.LocationEntity.SearchTerm"
                :content="value"
            />
            <EntityPill v-else :type="Improvement.LocationEntity.SearchTerm">
                <Skeleton
                    width="6rem"
                    color="hsla(174, 100%, 22%, 0.2)"
                    shimmer-color="hsla(174, 100%, 22%, 0.06)"
                />
            </EntityPill>
        </template>

        <template #column.cost="{ value }">
            <Money v-if="typeof value === 'number'" :value="value" />

            <Skeleton v-else width="3rem" />
        </template>
        <template #column.impressions="{ value }">
            <Number v-if="typeof value === 'number'" :value="value" />

            <Skeleton v-else width="3rem" />
        </template>
        <template #column.conversions="{ value }">
            <Number v-if="typeof value === 'number'" :value="value" />

            <Skeleton v-else width="3rem" />
        </template>
        <template #column.conversionValue="{ value }">
            <Money v-if="typeof value === 'number'" :value="value" />

            <Skeleton v-else width="3rem" />
        </template>
        <template #column.cpa="{ value }">
            <Money v-if="typeof value === 'number'" :value="value" />

            <Skeleton v-else width="3rem" />
        </template>
        <template #column.roas="{ value }">
            <Percent v-if="typeof value === 'number'" :value="value" />

            <Skeleton v-else width="3rem" />
        </template>
        <template #column.cpi="{ value }">
            <span v-if="typeof value === 'number'">
                {{ formatCpi(value) }}
            </span>

            <Skeleton v-else width="3rem" />
        </template>
        <template #column.vpi="{ value }">
            <span v-if="typeof value === 'number'">
                {{ formatVpi(value) }}
            </span>

            <Skeleton v-else width="3rem" />
        </template>
        <template #column.vsAvg="{ value, row }">
            <Text
                v-if="typeof value === 'number'"
                size="f-8"
                weight="500"
                :color="row.vsAverageColor"
            >
                <Percent :value="value" :decimal-places="2" include-sign />
            </Text>

            <Skeleton v-else width="3rem" />
        </template>

        <template #column.keywords="{ value }">
            <KeywordSourceCell :keyword-resource-names="value" />
        </template>
        <template #column.campaignName="{ value }">
            <EntityPill v-if="value" :type="Improvement.LocationEntity.Campaign" :content="value" />
            <EntityPill v-else :type="Improvement.LocationEntity.Campaign">
                <Skeleton
                    width="7rem"
                    color="hsla(214, 100%, 50%, 0.2)"
                    shimmer-color="hsla(214, 100%, 50%, 0.06)"
                />
            </EntityPill>
        </template>
        <template #column.adGroupName="{ value }">
            <EntityPill v-if="value" :type="Improvement.LocationEntity.AdGroup" :content="value" />
            <EntityPill v-else :type="Improvement.LocationEntity.AdGroup">
                <Skeleton
                    width="4.5rem"
                    color="hsla(256, 100%, 61%, 0.2)"
                    shimmer-color="hsla(256, 100%, 61%, 0.06)"
                />
            </EntityPill>
        </template>
    </oTable>
</template>

<script lang="ts" setup>
import { oTable, Text, EntityPill, Percent, Money, Number } from '@opteo/components-next'

import { Improvement } from '@opteo/types'
import { useNGramTool } from '@/composition/toolkit/nGramTool/useNGramTool'
import { computed } from 'vue'
import { Endpoint, useAPI } from '@/composition/api/useAPI'
import Skeleton from '@/components/util/Skeleton.vue'
import { useNGramFilters } from '@/composition/toolkit/nGramTool/useNGramFilters'
import KeywordSourceCell from './KeywordSourceCell.vue'
import { useAccount } from '@/composition/account/useAccount'

const {
    relevantHeaders,
    calculatePerformanceMetric,
    generateVsAvgColor,
    ngramAverage,
    formatCpi,
    formatVpi,
    performanceMaxActive,
    shoppingActive,
} = useNGramTool()

const { lookbackWindow } = useNGramFilters()

const props = defineProps<{
    searchTerms: string[]
}>()

const { accountId } = useAccount()

const { data } = useAPI<
    {
        resourceName: string
        searchTermText: string
        impressions: number
        cost: number
        conversions: number
        conversionValue: number
    }[]
>(Endpoint.GetSearchTermMetrics, {
    body: () => ({
        accountId: accountId.value,
        searchTermResourceNames: props.searchTerms,
        lookbackWindow: lookbackWindow.value.value,
    }),
    uniqueId: () => `${JSON.stringify(props.searchTerms)}`,
})

const searchTerms = computed(
    () =>
        data.value?.map(row => {
            const performanceMetric = calculatePerformanceMetric(
                row.impressions,
                row.cost,
                row.conversions,
                row.conversionValue
            )

            const vsAvg = (performanceMetric - ngramAverage.value) / ngramAverage.value

            const vsAverageColor = generateVsAvgColor(vsAvg)

            return {
                ...row,
                cpa: performanceMetric,
                roas: performanceMetric,
                cpi: performanceMetric,
                vpi: performanceMetric,
                vsAvg,
                vsAverageColor,
            }
        }) ?? props.searchTerms.map(term => ({ resourceName: term }))
)

const detailedViewSearchTermHeaders = computed(() => [
    {
        key: 'searchTerm',
        text: 'Search Term',
        vPadding: '0.875rem',
        sortable: true,
    },
    ...relevantHeaders.value.map(header => ({
        ...header,
        width: performanceMaxActive.value || shoppingActive.value ? 94 : 118,
    })),
    {
        key: 'vsAvg',
        text: 'vs Avg.',
        width: performanceMaxActive.value || shoppingActive.value ? 104 : 120,
        vPadding: '0.875rem',
        sortable: true,
    },
    performanceMaxActive.value
        ? {
              key: 'campaignName',
              text: 'Campaign Source',
              width: 312,
              vPadding: '0.5rem',
              sortable: true,
          }
        : shoppingActive.value
        ? {
              key: 'adGroupName',
              text: 'Ad Group Source',
              width: 312,
              vPadding: '0.5rem',
              sortable: true,
          }
        : {
              key: 'keywords',
              text: 'Keyword Source',
              width: 204,
              vPadding: '0.5rem',
              sortable: true,
              sortTransform: (value: string[]) => value.length,
          },
])
</script>

<style scoped lang="scss">
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

:deep(table td[data-label='Search Term']),
:deep(table td[data-label='Ad Group Source']),
:deep(table td[data-label='Campaign Source']) {
    overflow: hidden;
    position: relative;
}
:deep(table td[data-label='Search Term']::after) {
    content: '';
    background: linear-gradient(270deg, #fff 1.5rem, rgba(255, 255, 255, 0) 100%);
    @include absolute;
    top: 0;
    right: -1rem;
    width: 6rem;
    bottom: 0;
}

:deep(table td[data-label='Ad Group Source']::after),
:deep(table td[data-label='Campaign Source']::after) {
    content: '';
    background: linear-gradient(270deg, #fff 1.5rem, rgba(255, 255, 255, 0) 100%);
    @include absolute;
    top: 0;
    right: -0.5rem;
    width: 5rem;
    bottom: 0;
}

.info-circle-icon {
    width: 1rem;
}
</style>
