<template>
    <FullScreenOverlay @close="closeFunction">
        <transition name="page-fade" mode="out-in" appear>
            <div v-if="experimentDataLoading || loading" class="loader">
                <div class="loader-container">
                    <BiddingExperimentsLoaderAnimation />
                    <div class="progress-bar-container">
                        <!-- There are 10 steps coming from the backend -->
                        <ProgressBar :progress="progressStep * (1 / 10)" />
                    </div>
                </div>
            </div>
            <div
                v-else
                class="page-wrapper"
                :class="[isCreateFlow || isExperiment ? 'full-width' : '']"
            >
                <!-- Page Header -->
                <div class="page-header" v-if="isCreateFlow === false && isExperiment === false">
                    <!-- Avatar + title -->
                    <div class="left">
                        <Avatar
                            v-if="domainColour && domainInitials"
                            :platform="Platform.Platform.GoogleAds"
                            :color="domainColour"
                            :width="40"
                            :height="40"
                            showAccountType
                        >
                            {{ domainInitials }}
                        </Avatar>
                        <Skeleton v-else :width="40" :height="40" />
                        <Text v-if="domainColour && domainInitials" as="h1" weight="600">
                            {{ isCreateFlow ? 'Create Experiment' : 'Bidding Experiments' }}
                        </Text>
                        <Skeleton v-else :width="362" :height="32" />
                    </div>
                    <!-- Tabs -->
                    <Tabs v-if="!isCreateFlow" :links="pageSelectorLinks">
                        <template v-slot:create>Create Experiment</template>
                        <template v-slot:active>Active</template>
                        <template v-slot:completed>Completed</template>
                    </Tabs>
                </div>
                <!-- Spacer -->
                <Spacer v-if="isCreateFlow === false && isExperiment === false" height="4.5rem" />
                <!-- Router -->
                <router-view></router-view>
            </div>
        </transition>
    </FullScreenOverlay>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { Platform } from '@opteo/types'
import { useRouter } from 'vue-router'

import { provideExperiments } from '@/composition/toolkit/experiments/useExperiments'
import { Routes } from '@/router/routes'
import { Tabs, Text, Avatar, Spacer } from '@opteo/components-next'
import Skeleton from '@/components/util/Skeleton.vue'
import FullScreenOverlay from '@/components/util/FullScreenOverlay.vue'
import ProgressBar from '@/components/global/ProgressBar.vue'
import BiddingExperimentsLoaderAnimation from '@/components/biddingExperiments/BiddingExperimentsLoaderAnimation.vue'

const router = useRouter()

const isCreateFlow = computed(
    () => router.currentRoute.value.name === Routes.ToolkitSmartBiddingExperimentsCreateFlow
)
const isExperiment = computed(
    () => router.currentRoute.value.name === Routes.ToolkitSmartBiddingExperimentsExperiment
)

const handleExperimentClose = () => {
    router.push({ name: Routes.Toolkit })
}

const handleReturnToOrigin = () => {
    // If coming from inside Opteo, use router history
    if (window.history.state.back?.includes('/user/')) {
        clearSelectedCampaigns()
        router.go(-1)
    }
    // Any other situation, return to Bidding Experiments `Active` tab
    else {
        clearSelectedCampaigns()
        router.push({ name: Routes.ToolkitSmartBiddingExperimentsActive })
    }
}

const closeFunction = () => {
    if (isCreateFlow.value) {
        handleReturnToOrigin()
    } else if (isExperiment.value) {
        handleReturnToOrigin()
    } else {
        handleExperimentClose()
    }
}

const {
    activeExperimentsCount,
    completedExperimentsCount,
    domainColour,
    domainInitials,
    clearSelectedCampaigns,
    loading,
    experimentDataLoading,
    progressStep,
} = provideExperiments()

const pageSelectorLinks = computed(() => {
    return [
        {
            key: 'create',
            to: { name: Routes.ToolkitSmartBiddingExperimentsRecommendations },
        },
        {
            key: 'active',
            to: { name: Routes.ToolkitSmartBiddingExperimentsActive },
            count: activeExperimentsCount.value ?? 0,
        },
        {
            key: 'completed',
            to: { name: Routes.ToolkitSmartBiddingExperimentsCompleted },
            count: completedExperimentsCount.value ?? 0,
        },
    ]
})
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.loader {
    height: 100vh;
    @include flex-center;
}

.loader-container {
    max-width: 42.5rem;
    margin: 0 auto 0 auto;
}

.loader-container .progress-bar-container {
    @include ph-64;
    @include mt-64;
}

.page-wrapper {
    padding: 5rem 3rem;
    max-width: 80rem;
    margin: 0 auto;
}
.page-wrapper.full-width {
    max-width: unset;
    padding: 0;
}
.page-header {
    @include flex;
    @include items-center;
    @include justify-between;
    @include w-100;
    height: 2.75rem;
}
.page-header .left {
    @include flex;
    @include items-center;
    gap: 1.5rem;
}

// Transitions

.page-fade-enter-active,
.page-fade-leave-active {
    transition: opacity 0.48s ease;
}

.page-fade-enter-from,
.page-fade-leave-to {
    opacity: 0;
}

@media (max-width: 1280px) {
    .loader-container .progress-bar-container {
        @include mt-48;
    }
}
</style>
