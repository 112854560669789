<template>
    <Section v-if="currentStep === 0" :title="tab.name" :inner-shadow="false">
        <template #header-content>
            <div class="section-buttons">
                <!-- Campaigns Selection Table -->
                <SquareButton
                    ref="campaignsTableButton"
                    @click="campaignsPopoutOpen = !campaignsPopoutOpen"
                    class="campaigns-table-button"
                >
                    <template #prefix>
                        <CircleCampaignIcon :dark="true" :tooltip="false" />
                    </template>
                    <template #text>
                        <Text size="f-9" color="dark-blue" weight="600">
                            {{ campaignSelectionCount }}
                            {{ campaignSelectionCount === 1 ? 'Campaign' : 'Campaigns' }}</Text
                        >
                    </template>
                    <template #suffix>
                        <SelectArrowIcon />
                    </template>
                </SquareButton>
                <div class="select-ngram-buttons">
                    <NGramSpreadChart
                        :items="spreadChartItems"
                        :currency-code="currencyCode"
                        :performance-metric-type="performanceMetricType"
                        :loading="nGramDataLoading"
                        :itemsLength="SPREAD_CHART_LENGTH"
                        style="padding-right: 0.5rem"
                    />
                    <SquareButton @click="() => (showNgramFiltersModal = true)">
                        Filters
                        <template #suffix>
                            <Count :count="checkedFilters" />
                        </template>
                    </SquareButton>
                    <Select v-model="lookbackWindow" :options="lookbackWindows" />
                    <SquareButton @click="downloadNgramCsv">Download</SquareButton>
                </div>
            </div>
        </template>

        <!-- NGrams Selection Table -->
        <template #content>
            <!-- Skeletons on loading -->

            <transition name="shift-away">
                <CampaignsTable
                    v-if="campaignsPopoutOpen"
                    :buttonRef="campaignsTableButton"
                    :popoutWidth="tableWidth"
                    @click-outside="campaignsPopoutOpen = false"
                />
            </transition>

            <div ref="tableWidthRef">
                <!-- pMax Search terms Opt in -->
                <div v-if="showPMaxOptInMessage" class="empty-state-container">
                    <img src="@/assets/img/reports-empty-state.png" style="height: 144px" />
                    <Spacer height="0.25rem" />
                    <Text size="f-8" style="width: 370px"
                        >Loading Performance Max search terms may take a few minutes. Click Load
                        Search Terms to begin the process in the background. Feel free to continue
                        working, and we'll notify you once it's complete.</Text
                    >
                    <Spacer height="0.25rem" />
                    <oButton
                        ref="pMaxOptInButton"
                        @clicked="optInToPMax"
                        :loading="optingInToPMax"
                        size="large"
                        >Load Search Terms
                        <!-- Icon Slot -->
                        <template #icon>
                            <DownloadIcon hex="#fff" />
                        </template>
                    </oButton>
                </div>
                <NGramTableSkeleton v-else-if="nGramDataLoading" />
                <oTable
                    class="ngram-table"
                    v-else-if="nGramItems.length > 0"
                    :headers="tableHeaders"
                    :items="searchedNgramItems"
                    :order-by="nGramSorting.key"
                    :order="nGramSorting.order"
                    @sort="persistSort"
                    fixed-layout
                    :border-radius="24"
                    :per-page="25"
                    pagination-method="intersection"
                >
                    <template #header.ngram>
                        <Spacer width="1.875rem" />
                        <Tooltip content="Select All" placement="top">
                            <Checkbox :model-value="allNGramsSelected" @click="toggleAllNgrams" />
                        </Tooltip>
                        <Spacer width="1.25rem" />
                        <Text size="f-8" weight="500">N-Gram</Text>
                        <Spacer width="1rem" />
                        <oInput
                            ref="searchInput"
                            v-model="searchedNgramText"
                            type="text"
                            class="ngram-text-search-input"
                            name="ngramTextSearchInput"
                            autocomplete="off"
                        />
                    </template>
                    <template #header.vpi>
                        <Text as="span" size="f-8" weight="500">VPI</Text>
                        <Popout
                            v-model="vpiPopoverOpen"
                            :offset="[0, 16]"
                            :borderRadius="24"
                            trigger="mouseenter"
                            placement="top"
                            max-width="23rem"
                            wrapperClasses="vpi-popout"
                        >
                            <img
                                class="info-circle-icon"
                                style="min-width: 1rem"
                                src="@/assets/img/icon-info.svg"
                            />
                            <template #content>
                                <Text size="f-8" weight="400" style="padding: 1rem 1.25rem">
                                    VPI (Value Per Impression) is the average value generated by
                                    each impression of this n-gram.
                                </Text>
                            </template>
                        </Popout>
                    </template>
                    <template #column.ngram="{ value, row }">
                        <NGramCell
                            v-bind="row"
                            :ngram="value"
                            :avg-engagement-rate="avgEngagementRate"
                        />
                    </template>
                    <template #column.cost="{ value }">
                        <Money :value="value" />
                    </template>
                    <template #column.impressions="{ value }">
                        <Number :value="value" />
                    </template>
                    <template #column.conversions="{ value }">
                        <Number :value="value" />
                    </template>
                    <template #column.conversionValue="{ value }">
                        <Money :value="value" />
                    </template>
                    <template #column.cpa="{ value }">
                        <Money :value="value" />
                    </template>
                    <template #column.roas="{ value }">
                        <Percent :value="value" />
                    </template>
                    <template #column.cpi="{ value }"> {{ formatCpi(value) }} </template>
                    <template #column.vpi="{ value }"> {{ formatVpi(value) }} </template>
                    <template #column.vsAvg="{ value, row }">
                        <Text size="f-8" weight="500" :color="row.vsAverageColor">
                            <Percent :value="value" :decimal-places="2" include-sign />
                        </Text>
                    </template>
                    <template #column.nscoreSortValue="{ row }">
                        <NScoreCell v-bind="row" />
                    </template>
                    <template #column.keywordConflicts="{ value, row }">
                        <AffectedKeywordsCell :ngram="row.ngram" />
                    </template>
                    <template #column.actions="{ row }">
                        <oButton size="small" color="white" @clicked="detailedViewNGram = row">
                            Detailed View
                        </oButton>
                    </template>
                </oTable>

                <!-- Empty state due to filters -->
                <div v-else class="empty-state-container">
                    <img src="@/assets/img/segments-empty-state.png" style="height: 174px" />
                    <Spacer height="0.25rem" />
                    <Text size="f-8">No n-grams match your filters.</Text>
                </div>
            </div>
        </template>
    </Section>
    <Section v-else-if="currentStep === 1" :group="true" :items="sectionGroupItems">
        <template #selected-n-grams-header-content>
            <div class="step-2-section-buttons">
                <div class="add-ngram-buttons">
                    <SquareButton @click="searchTermsPanel = true">View Search Terms</SquareButton>
                    <Select v-model="lookbackWindow" :options="lookbackWindows" />
                </div>
            </div>
        </template>
        <template #selected-n-grams-content>
            <!-- Skeletons on loading -->
            <NGramTableSkeleton
                v-if="nGramDataLoading"
                :number-of-rows="selectedNGramItems.length"
            />
            <oTable
                v-else
                :headers="tableHeaders"
                :items="selectedNGramItems"
                order-by="cost"
                order="DESC"
                fixed-layout
                :border-radius="24"
            >
                <template #header.ngram>
                    <Spacer width="1.875rem" />
                    <Tooltip content="Select All" placement="top">
                        <Checkbox :model-value="allNGramsSelected" @click="toggleAllNegatives" />
                    </Tooltip>
                    <Spacer width="1.25rem" />
                    <Text size="f-8" weight="500">N-Gram</Text>
                </template>
                <template #column.ngram="{ value }">
                    <!-- add click to the table cell for a larger clickable area -->
                    <div class="ngram-cell" @click="selectNegative(value)">
                        <div v-if="addingNgramsToNegative" class="spinner-container">
                            <Spinner />
                        </div>
                        <Checkbox v-else :model-value="finalNgramSelection.includes(value)" />
                        <Spacer width="1.25rem" />
                        <EntityPill :type="Improvement.LocationEntity.NGram" :content="value" />
                    </div>
                </template>
                <template #column.cost="{ value }">
                    <Money :value="value" />
                </template>
                <template #column.impressions="{ value }">
                    <Number :value="value" />
                </template>
                <template #column.conversions="{ value }">
                    <Number :value="value" />
                </template>
                <template #column.conversionValue="{ value }">
                    <Money :value="value" />
                </template>
                <template #column.cpa="{ value }">
                    <Money :value="value" />
                </template>
                <template #column.roas="{ value }">
                    <Percent :value="value" />
                </template>
                <template #column.cpi="{ value }">
                    {{ formatCpi(value) }}
                </template>
                <template #column.vpi="{ value }">
                    {{ formatVpi(value) }}
                </template>
                <template #column.vsAvg="{ value, row }">
                    <Text size="f-8" weight="500" :color="row.vsAverageColor">
                        <Percent :value="value" :decimal-places="2" include-sign />
                    </Text>
                </template>
                <template #column.nscoreSortValue="{ row }">
                    <NScoreCell v-bind="row" />
                </template>
                <template #column.keywordConflicts="{ value, row }">
                    <AffectedKeywordsCell :keyword-resource-names="value" :ngram="row.ngram" />
                </template>
                <template #column.actions="{ row }">
                    <oButton size="small" color="white" @clicked="detailedViewNGram = row">
                        Detailed View
                    </oButton>
                </template>
            </oTable>
        </template>
        <template #keyword-destinations-content>
            <NGramKeywordDestination v-if="sharedSetData && campaignData" />
        </template>
    </Section>

    <Spacer v-if="currentStep === 0 && selectedNGramItems.length > 1" />
    <Spacer v-if="currentStep === 1" height="7rem" />

    <!-- Detailed View Panel -->
    <DetailedViewPanel
        :ngram="detailedViewNGram"
        @clearDetailedView="detailedViewNGram = undefined"
    />

    <!-- Search Terms Panel -->
    <SearchTermsPanel v-model="searchTermsPanel" :ngrams="selectedNGramItems" />

    <BatchBar
        :title="`N-Gram${initialNgramSelectionCount > 1 ? 's' : ''}`"
        :model-value="showBatchBar"
        @clear-selected="clearAllNgrams"
        :count="initialNgramSelectionCount"
        :full-screen="true"
    >
        <template #buttons>
            <oButton color="dark" @clicked="searchTermsPanel = true"> View Search Terms </oButton>
            <oButton color="blue" @clicked="goToNegativeDestination">
                Select Negative Destinations
                <template #icon><RightArrowIcon hex="#fff" /></template>
            </oButton>
        </template>
    </BatchBar>

    <NGramFiltersModal v-if="showNgramFiltersModal" />
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue'
import { useRouter } from 'vue-router'

import {
    Section,
    oTable,
    Spacer,
    Tooltip,
    Text,
    Checkbox,
    EntityPill,
    Spinner,
    oButton,
    Percent,
    BatchBar,
    RightArrowIcon,
    NGramSpreadChart,
    Count,
    SquareButton,
    CircleCampaignIcon,
    SelectArrowIcon,
    Select,
    Number,
    Popout,
    oInput,
} from '@opteo/components-next'
import { useNGramTool } from '@/composition/toolkit/nGramTool/useNGramTool'
import { useNGramFilters } from '@/composition/toolkit/nGramTool/useNGramFilters'
import NGramTableSkeleton from '@/components/toolkit/ngram/NGramTableSkeleton.vue'
import NGramFiltersModal from '@/components/toolkit/ngram/NGramFiltersModal.vue'
import DetailedViewPanel from '@/components/toolkit/ngram/DetailedViewPanel.vue'
import SearchTermsPanel from '@/components/toolkit/ngram/SearchTermsPanel.vue'
import NGramKeywordDestination from '@/components/toolkit/ngram/NGramKeywordDestination.vue'
import { Improvement } from '@opteo/types'
import { useElementBounding } from '@vueuse/core'

import Money from '@/components/global/oMoney.vue'
import CampaignsTable from './CampaignsTable.vue'
import NGramCell from './NGramCell.vue'
import AffectedKeywordsCell from './AffectedKeywordsCell.vue'
import NScoreCell from './NScoreCell.vue'

const {
    // Navigation
    currentStep,
    goToNegativeDestination,
    currencyCode,
    performanceMaxActive,
    tabLinks,
    downloadNgramCsv,

    // NGram Tables
    tableHeaders,
    nGramItems,
    allNGramsSelected,
    selectedNGramItems,
    initialNgramSelectionCount,
    toggleAllNgrams,
    clearAllNgrams,
    toggleAllNegatives,
    selectNegative,
    nGramDataLoading,
    addingNgramsToNegative,
    finalNgramSelection,
    finalNgramSelectionCount,
    searchedNgramText,
    searchedNgramItems,
    avgEngagementRate,
    campaignSelectionCount,

    // Indicator
    SPREAD_CHART_LENGTH,
    spreadChartItems,
    performanceMetricType,

    // Keywords Destinations
    sharedSetData,
    campaignData,

    showPMaxOptInMessage,
    optInToPMax,
    optingInToPMax,
    pMaxOptInButton,

    // utils
    formatCpi,
    formatVpi,
} = useNGramTool()

const { checkedFilters, lookbackWindow, lookbackWindows, showNgramFiltersModal } = useNGramFilters()

const tableWidthRef = ref<HTMLElement>()
const tableBoundingRect = useElementBounding(tableWidthRef)
const tableWidth = computed(() => `${tableBoundingRect.width.value + 16}px`)

// Campaigns Table Popout
const campaignsTableButton = ref()
const campaignsPopoutOpen = ref(false)

type SortingOrder = { key: string; order: 'ASC' | 'DESC' | null }

const nGramSorting = ref<SortingOrder>({ key: 'nscoreSortValue', order: 'DESC' })

function persistSort(sorting: SortingOrder) {
    nGramSorting.value = sorting
}

// DetailedViewPanel
const detailedViewNGram = ref()

const searchTermsPanel = ref(false)

const negativeListModalOpen = ref(false)

const sectionGroupItems = computed(() => [
    {
        id: 'selected-n-grams',
        title: 'Selected N-Grams',
        innerShadow: false,
    },
    {
        id: 'keyword-destinations',
        title: 'Keyword Destinations',
        contentMaxWidth: 604,
        innerShadow: false,
    },
])

const router = useRouter()

const tab = computed(() => {
    const key = router.currentRoute.value.name?.toString().split('toolkitngramtool-')[1]

    const tab = tabLinks.value.find(tab => tab.key === key)

    if (!key || !tab) return tabLinks.value[0]

    return tab
})

const vpiPopoverOpen = ref(false)

const showBatchBar = computed(
    () =>
        currentStep.value === 0 &&
        initialNgramSelectionCount.value > 0 &&
        !detailedViewNGram.value &&
        !searchTermsPanel.value &&
        !showNgramFiltersModal.value
)
</script>

<style scoped lang="scss">
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.spinner-container {
    height: 1.25rem;
    width: 1.25rem;
    @include flex-center;
}

.ngram-cell,
:deep(.ngram-cell) {
    display: flex;
    align-items: center;
    padding-left: 1.875rem;
    width: 100%;
    cursor: pointer;
    overflow: hidden;
}

:deep(.vpi-popout) {
    padding-left: 0.5rem;
    display: flex;
    align-items: center;
}

:deep(.nscore-popout-wrapper) {
    display: flex;
    align-items: center;
}

.select-ngram-buttons {
    display: grid;
    grid-template-columns: 240px auto 146px 96px;
    gap: 0.75rem;
}
.add-ngram-buttons {
    display: grid;
    grid-template-columns: auto 146px;
    gap: 0.75rem;
}

.nscore-cell,
.conflicts-cell,
.keyword-source-cell,
:deep(.nscore-cell, .conflicts-cell, .keyword-source-cell) {
    display: flex;
    align-items: center;
}
.conflicts-cell {
    position: relative;
    cursor: pointer;
}

.info-circle-icon,
:deep(.info-circle-icon) {
    width: 1rem;
    cursor: pointer;
}

.fixed-footer-wrapper {
    @include flex-center;
    gap: 2rem;
    margin: auto;
    @include fixed;
    @include bg-opteo-white;
    @include z-10;
    @include container;
    border-radius: 0;
    @include pv-32;
    width: 100vw;
    bottom: 0;
    left: 0;
}
.fixed-footer-container {
    @include br-16;
    @include ph-24;
}

.empty-state-container {
    @include container;
    @include flex-center;
    padding: 7rem 2rem;
    flex-direction: column;
    gap: 1rem;
}

.shift-away-enter-active,
.shift-away-leave-active {
    transition-property: transform, visibility, opacity;
    transition-timing-function: cubic-bezier(0.54, 1.5, 0.38, 1.11);
    transition-duration: 0.3s;
}

.shift-away-enter-from,
.shift-away-leave-to {
    opacity: 0;
    transform: translate(-50%, 0rem);
}

.ngram-text-search-input {
    max-width: 9.25rem;
    @include relative;
}

.ngram-text-search-input :deep(input) {
    padding: 10px 14px 10px 38px;
}
.ngram-text-search-input::before {
    content: '';
    position: absolute;
    left: 14px;
    top: 0;
    bottom: 0;
    width: 12px;
    background: url('@/assets/img/searchIcon.svg') center / contain no-repeat;
}
</style>
