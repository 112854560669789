<template>
    <ImprovementView
        :push-messages="pushMessages"
        :push-action-text="pushActionText"
        :is-adjustable="true"
        :on-push="onPush"
    >
        <ImprovementContent>
            <ImprovementHeader :title="title">
                <div v-if="aboveMobile">
                    <div v-if="singleCampaign">
                        <EntityPillLink
                            :entityPillData="campaignNames[0].entityPillData"
                            :deepLinkParams="campaignNames[0].deepLinkParams"
                        />
                    </div>
                    <div v-else>
                        <EntityPillLink
                            :entityPillData="{ content: campaignGroupName, type: 'campaign-group' }"
                            :deepLinkParams="null"
                        />
                    </div>
                    <Spacer height="2rem" />
                </div>
                <Text as="p">
                    <span v-if="singleCampaign">
                        In this campaign, your ads are
                        {{ isLocationUnderperforming ? 'underperforming' : 'performing well' }}
                        for searchers in the <b>{{ locationTargetType }}</b> listed below.
                    </span>
                    <span v-else>
                        In this campaign group, your ads are
                        {{ isLocationUnderperforming ? 'underperforming' : 'performing well' }}
                        for searchers in the <b>{{ locationTargetType }}</b> below.
                    </span>

                    <template v-if="isCpaMode">
                        Searchers in this location generated
                        <b><Number :value="metrics.conversions.value" /></b>
                        {{ metrics.conversions.copy }} at a cost of
                        <b><Money :value="metrics.cost.value" /></b> with an average CPA of
                        <b><Money :value="metrics.cpa.value" /></b>. This is
                        <Text
                            as="span"
                            :color="isLocationUnderperforming ? 'red' : 'green'"
                            weight="600"
                        >
                            <Percent :value="performanceDifference" />
                            {{ isLocationUnderperforming ? 'higher' : 'lower' }}
                        </Text>
                        {{
                            singleCampaign
                                ? 'than the campaign average'
                                : 'than the CPA of the campaigns below'
                        }}
                        (<b><Money :value="metrics.environmentCpa.value" /></b>).
                    </template>

                    <template v-else>
                        Searchers in this location generated
                        <b><Money :value="metrics.conversionsValue.value" /></b>
                        {{ metrics.conversionsValue.copy }} at a cost of
                        <b><Money :value="metrics.cost.value" /></b> with an average ROAS of
                        <b><Roas :value="metrics.roas.value" /></b>. This is
                        <Text
                            as="span"
                            :color="isLocationUnderperforming ? 'red' : 'green'"
                            weight="600"
                        >
                            <Percent :value="performanceDifference" />
                            {{ isLocationUnderperforming ? 'lower' : 'higher' }}
                        </Text>
                        {{
                            singleCampaign
                                ? 'than the campaign average'
                                : 'than the ROAS of the campaigns below'
                        }}
                        (<b><Roas :value="metrics.environmentRoas.value" /></b>).
                    </template>

                    <span v-if="hasExistingBidMod">
                        Consider updating your bid adjustment from
                        <Text as="span" :color="oldBidIsPositive ? 'green' : 'red'" weight="600">
                            <Percent :value="oldBidModifier" :include-sign="true" />
                        </Text>
                        to
                        <Text as="span" :color="newBidIsPositive ? 'green' : 'red'" weight="600">
                            <Percent :value="newBidModifier" :include-sign="true" /> </Text
                        >.
                    </span>
                    <span v-else>
                        Consider setting a
                        <Text as="span" :color="newBidIsPositive ? 'green' : 'red'" weight="600">
                            <Percent :value="newBidModifier" :include-sign="true" />
                        </Text>
                        bid adjustment for <span v-if="singleCampaign">searchers in </span>this
                        location.</span
                    >
                </Text>

                <Spacer height="2rem" />

                <div v-if="aboveMobile">
                    <EntityPillLink
                        :entityPillData="{ content: trueLocationName, type: 'location' }"
                        :deepLinkParams="{ campaignId: locationCampaignId }"
                    />
                    <Spacer height="2rem" />
                </div>

                <Text>
                    Click <Text as="span" color="green" weight="600">{{ pushActionText }}</Text> to
                    apply a
                    <Text as="span" :color="newBidIsPositive ? 'green' : 'red'" weight="600">
                        <Percent :value="newBidModifier" :include-sign="true" />
                    </Text>
                    adjustment for searchers based in this location. This bid adjustment will be
                    applied to the
                    <Text as="span" weight="600" v-if="campaignCount > 1">{{ campaignCount }}</Text>
                    {{ singleCampaign ? 'campaign listed above' : 'campaigns listed below' }}.
                </Text>
                <div v-if="!singleCampaign">
                    <Spacer height="2rem" />
                    <EntityPillLinkList :items="campaignNames" />
                    <Spacer height="2rem" />
                    <ImprovementNote
                        ><b>Note:</b> Frequently campaigns don't have enough location performance
                        data for Opteo to suggest a bid adjustment per campaign. In this scenario,
                        Opteo combines campaigns with insufficient data to suggest an adjustment
                        based on the sum of their data.
                    </ImprovementNote>
                </div>
            </ImprovementHeader>
            <ImprovementHelpLink path="/en/articles/2033681-adjust-location-bids">
                Click here to learn more about location bid adjustments in Opteo.
            </ImprovementHelpLink>
        </ImprovementContent>

        <ImprovementContent>
            <div v-if="mapImageUrl" class="location-breakdown-image">
                <!-- Show placeholder while map loads. -->
                <div v-show="mapIsLoading" class="relative">
                    <div class="map-spinner">
                        <Spinner />
                    </div>
                    <oImage
                        v-if="locationTypeIsCountry"
                        :src="mapLoaderSmall"
                        :placeholder="mapLoaderSmallPlaceholder"
                        :width="604"
                        :height="420"
                    />
                    <oImage
                        v-else
                        :src="mapLoaderLarge"
                        :placeholder="mapLoaderLargePlaceholder"
                        :width="604"
                        :height="540"
                    />
                </div>
                <!-- Location name -->
                <span class="short-location-name">{{ shortLocationName }}</span>
                <!-- Render map once it has loaded. -->
                <oImage :src="mapImageUrl" @load="setMapIsLoadingTrue" />
            </div>

            <!-- If map is non-renderable display placeholder. -->
            <div v-else class="relative">
                <div class="overlay-tag">
                    <Text as="p" size="f-9">
                        Map preview unavailable for this {{ locationTargetType }}
                    </Text>
                </div>
                <div class="location-breakdown-image">
                    <oImage
                        :src="mapLoaderSmall"
                        :placeholder="mapLoaderSmallPlaceholder"
                        :width="604"
                        :height="420"
                    />
                </div>
            </div>
            <Spacer height="2rem" />

            <oTable
                :headers="locationTableHeaders"
                :items="locationTableItems"
                responsive-mode="columns"
                responsive-breakpoint="768px"
            >
                <template v-slot:[`column.bidMod`]="bidMod">
                    <Text
                        v-if="bidMod.value.includes('+') || bidMod.value.includes('-')"
                        :color="bidMod.value.includes('+') ? 'green' : 'red'"
                        as="span"
                        weight="500"
                        size="f-8"
                    >
                        {{ bidMod.value }}
                    </Text>
                    <Text v-else as="span" weight="400" size="f-8">
                        {{ bidMod.value }}
                    </Text>
                </template>
            </oTable>
        </ImprovementContent>

        <ImprovementContent>
            <ImprovementCreatedDate :last-updated="lastUpdated" :total-days="window" />
        </ImprovementContent>

        <template v-slot:adjust-step-1>
            <ImprovementContent>
                <!-- TODO: should we use the actual current bid here??? -->
                <ImprovementBidAdjustment
                    ref="impBidAdjustmentRef"
                    bid-type="location"
                    ent-type="location"
                    :current-bid="1"
                    :new-bid="newBidModValue"
                    @on-bid-update="onBidUpdate"
                >
                    <template v-slot:entity-pill>
                        <div v-if="aboveMobile">
                            <div v-if="singleCampaign">
                                <EntityPillLink
                                    :entityPillData="campaignNames[0].entityPillData"
                                    :deepLinkParams="campaignNames[0].deepLinkParams"
                                />
                            </div>
                            <div v-else>
                                <EntityPillLink
                                    :entityPillData="{
                                        content: campaignGroupName,
                                        type: 'campaign-group',
                                    }"
                                    :deepLinkParams="null"
                                />
                            </div>
                        </div>
                    </template>
                    <template v-slot:text>
                        <span v-if="singleCampaign">
                            Your ads are
                            {{ isLocationUnderperforming ? 'underperforming' : 'performing well' }}
                            for searchers in the <b>{{ locationTargetType }}</b> listed below.
                        </span>
                        <span v-else>
                            Your ads are
                            {{ isLocationUnderperforming ? 'underperforming' : 'performing well' }}
                            for searchers in the <b>{{ locationTargetType }}</b> below.
                        </span>

                        <template v-if="isCpaMode">
                            Searchers in this location generated
                            <b><Number :value="metrics.conversions.value" /></b>
                            {{ metrics.conversions.copy }} at a cost of
                            <b><Money :value="metrics.cost.value" /></b> with an average CPA of
                            <b><Money :value="metrics.cpa.value" /></b>. This is
                            <Text
                                as="span"
                                :color="isLocationUnderperforming ? 'red' : 'green'"
                                weight="600"
                            >
                                <Percent :value="performanceDifference" />
                                {{ isLocationUnderperforming ? 'higher' : 'lower' }}
                            </Text>
                            {{
                                singleCampaign
                                    ? 'than the campaign average'
                                    : 'than the CPA of the campaigns below'
                            }}
                            (<b><Money :value="metrics.environmentCpa.value" /></b>).
                        </template>

                        <template v-else>
                            Searchers in this location generated
                            <b><Money :value="metrics.conversionsValue.value" /></b>
                            {{ metrics.conversionsValue.copy }} at a cost of
                            <b><Money :value="metrics.cost.value" /></b> with an average ROAS of
                            <b><Roas :value="metrics.roas.value" /></b>. This is
                            <Text
                                as="span"
                                :color="isLocationUnderperforming ? 'red' : 'green'"
                                weight="600"
                            >
                                <Percent :value="performanceDifference" />
                                {{ isLocationUnderperforming ? 'lower' : 'higher' }}
                            </Text>
                            {{
                                singleCampaign
                                    ? 'than the campaign average'
                                    : 'than the ROAS of the campaigns below'
                            }}
                            (<b><Roas :value="metrics.environmentRoas.value" /></b>).
                        </template>

                        <span v-if="hasExistingBidMod">
                            Opteo recommends updating your bid adjustment from
                            <Text
                                as="span"
                                :color="oldBidIsPositive ? 'green' : 'red'"
                                weight="600"
                            >
                                <Percent :value="oldBidModifier" :include-sign="true" />
                            </Text>
                            to
                            <Text
                                as="span"
                                :color="newBidIsPositive ? 'green' : 'red'"
                                weight="600"
                            >
                                <Percent :value="newBidModifier" :include-sign="true" /> </Text
                            >.
                        </span>
                        <span v-else>
                            Opteo recommends setting a
                            <Text
                                as="span"
                                :color="newBidIsPositive ? 'green' : 'red'"
                                weight="600"
                            >
                                <Percent :value="newBidModifier" :include-sign="true" />
                            </Text>
                            bid adjustment for <span v-if="singleCampaign">searchers in </span>this
                            location.</span
                        >
                        <Spacer height="2rem" />
                        <EntityPillLinkList
                            v-if="!aboveMobile && trueLocationName.length > 30"
                            :items="[
                                {
                                    entityPillData: { type: 'location', content: trueLocationName },
                                    deepLinkParams: { campaignId: locationCampaignId },
                                },
                            ]"
                        />
                        <EntityPillLink
                            v-else
                            :entityPillData="{ content: trueLocationName, type: 'location' }"
                            :deepLinkParams="{ campaignId: locationCampaignId }"
                        />
                    </template>
                </ImprovementBidAdjustment>
                <Spacer height="40px" />
                <div v-if="!singleCampaign">
                    <Text>
                        This bid adjustment will be applied to the <b>{{ campaignCount }}</b>
                        campaigns listed below.
                    </Text>
                    <Spacer height="2rem" />
                    <EntityPillLinkList :items="campaignNames" />
                    <Spacer height="2rem" />
                </div>
            </ImprovementContent>
        </template>
    </ImprovementView>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import {
    ImprovementHeader,
    ImprovementContent,
    ImprovementCreatedDate,
    ImprovementNote,
    Spacer,
    Text,
    Number,
    Percent,
    Roas,
    oButton,
    oTable,
    oImage,
    Spinner,
} from '@opteo/components-next'

import ImprovementView from '@/components/improvement/ImprovementView.vue'
import ImprovementHelpLink from '@/components/improvement/ImprovementHelpLink.vue'
import Money from '@/components/global/oMoney.vue'
import ImprovementBidAdjustment from '@/components/improvement/ImprovementBidAdjustment.vue'
import useMediaQuery from '@/composition/global/useMediaQuery'

import { useAdjustLocationBids } from '@/composition/improvement/types/useAdjustLocationBids'
import EntityPillLink from '@/components/global/Entity/EntityPillLink.vue'
import EntityPillLinkList from '@/components/global/Entity/EntityPillLinkList.vue'

export default defineComponent({
    name: 'AdjustLocationBids',
    components: {
        ImprovementHeader,
        ImprovementContent,
        ImprovementCreatedDate,
        ImprovementView,
        ImprovementHelpLink,
        ImprovementNote,
        EntityPillLink,
        EntityPillLinkList,
        Spacer,
        Text,
        Number,
        Money,
        Percent,
        Roas,
        oButton,
        oTable,
        oImage,
        Spinner,
        ImprovementBidAdjustment,
    },
    setup() {
        return { ...useAdjustLocationBids(), ...useMediaQuery() }
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.location-breakdown-image {
    @include container;
    @include relative;
    overflow: hidden;
}

.map-skeleton {
    width: 100%;
}

.map-spinner {
    @include absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
}
.overlay-tag {
    @include absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    @include container;
    @include ph-16;
    @include pv-8;
    border-radius: 99px;
    z-index: 1;
    white-space: nowrap;
}
.short-location-name {
    @include absolute;
    top: 1.8125rem;
    left: 1.875rem;
    @include f-4;
    @include fw-500;
    @include z-2;
    opacity: 0.15;
}
</style>
