import { computed, inject, nextTick, onBeforeUnmount, onMounted, provide, ref, watch } from 'vue'
import sumBy from 'lodash-es/sumBy'
import countBy from 'lodash-es/countBy'
import orderBy from 'lodash-es/orderBy'
import round from 'lodash-es/round'

import { useRouter } from 'vue-router'

import {
    hideSnackbar,
    oButton,
    showAsyncPush,
    showSnackbar,
    useNumber,
} from '@opteo/components-next'

import { useAccount } from '@/composition/account/useAccount'
import { Endpoint, useAPI, authRequest } from '@/composition/api/useAPI'

import { useNGramFilters } from './useNGramFilters'

// Types
import { Routes } from '@/router/routes'
import { Gads, NgramTool, Targets, AdWords } from '@opteo/types'
import { useDomainMoney } from '@/composition/domain/useDomainMoney'
import { delay } from '@opteo/promise'
import { isNull } from 'lodash-es'
import { useUser } from '@/composition/user/useUser'
import { downloadCsv } from '@/lib/globalUtils'

const loadingStateMessages = {
    SEARCH_TERM_VIEW_NOT_CACHED: 'Fetching latest search terms from Google Ads..',
    BLOCKED_SEARCH_TERM_NOT_CACHED: 'Fetching latest keywords from Google Ads..',
    UNDERPERFORMING_NGRAMS_NOT_CACHED: 'Fetching underperforming ngrams in the industry..',
    SEARCH_TERM_INSIGHT_NOT_CACHED:
        'Fetching latest search terms for Performance Max (this can take a few minutes)',
    GA4_SESSIONS_NOT_CACHED: 'Fetching latest GA4 search term bounce rates..',
}

export interface NGramItem {
    cpa: number
    roas: number
    cpi: number
    vpi: number
    vsAvg: number
    vsAverageColor: string
    nScoreColor: string
    industryPerformance: number
    ngram: string
    campaignId: number
    adGroupId?: number | undefined
    nscore?: number
}

export function provideNGramTool() {
    // Other composition functions
    const { groupId } = useUser()
    const { currentRoute, push } = useRouter()
    const { currencyCode, accountId, performanceMode, currencySymbol, accountInfo, accountName } =
        useAccount()
    const {
        lookbackWindow,
        excludedCampaigns,
        excludeNGramFilters,
        excludeSearchTermFilters,
        excludeKeywordFilters,
        excludeMatchTypeFilters,
        shinyFilters,
        stringifiedFilters,
        stringifiedCampaignSelection,
    } = useNGramFilters()

    // Navigation Logic
    const tabLinks = computed(() => [
        {
            key: 'search-campaigns',
            name: 'Search Campaigns',
            to: { name: Routes.ToolkitNGramToolSearch },
            count:
                countBy(
                    nGramCampaigns.value,
                    c => c.campaignType === Gads.enums.AdvertisingChannelType.SEARCH
                ).true ?? 0,
        },
        {
            key: 'performance-max',
            name: 'Performance Max',
            to: { name: Routes.ToolkitNGramToolPmax },
            count:
                countBy(
                    nGramCampaigns.value,
                    c => c.campaignType === Gads.enums.AdvertisingChannelType.PERFORMANCE_MAX
                ).true ?? 0,
        },
        {
            key: 'shopping-campaigns',
            name: 'Shopping Campaigns',
            to: { name: Routes.ToolkitNGramToolShopping },
            count:
                countBy(
                    nGramCampaigns.value,
                    c => c.campaignType === Gads.enums.AdvertisingChannelType.SHOPPING
                ).true ?? 0,
        },
    ])

    const steps = computed(() => [
        { index: 0, title: 'N-Gram Tool' },
        { index: 1, title: 'Add Negatives' },
    ])

    const performanceMaxActive = computed(
        () => currentRoute.value.name === Routes.ToolkitNGramToolPmax
    )
    const shoppingActive = computed(
        () => currentRoute.value.name === Routes.ToolkitNGramToolShopping
    )

    const currentStep = ref(0)

    function resetState() {
        currentStep.value = 0
        clearAllNgrams()
        clearAllNegatives()
    }

    function goToNGramSelection() {
        currentStep.value = 0
    }
    function goToNegativeDestination() {
        currentStep.value = 1
    }

    function closeNGramTool() {
        resetState()
        push({ name: Routes.ToolkitTools })
    }

    // nGram Table Headers
    const cpaHeaders = [
        {
            key: 'cost',
            text: 'Cost',
            width: 100,
            vPadding: '0.875rem',
            sortable: true,
        },
        {
            key: 'conversions',
            text: 'Conv.',
            width: 100,
            vPadding: '0.875rem',
            sortable: true,
        },
        {
            key: 'cpa',
            text: 'CPA',
            width: 100,
            vPadding: '0.875rem',
            sortable: true,
        },
    ]

    const roasHeaders = [
        {
            key: 'cost',
            text: 'Cost',
            width: 100,
            vPadding: '0.875rem',
            sortable: true,
        },
        {
            key: 'conversionValue',
            text: 'C. Value',
            width: 100,
            vPadding: '0.875rem',
            sortable: true,
        },
        {
            key: 'roas',
            text: 'ROAS',
            width: 100,
            vPadding: '0.875rem',
            sortable: true,
        },
    ]

    const cpiHeaders = [
        {
            key: 'impressions',
            text: 'Impr.',
            width: 100,
            vPadding: '0.875rem',
            sortable: true,
        },
        {
            key: 'conversions',
            text: 'Conv.',
            width: 100,
            vPadding: '0.875rem',
            sortable: true,
        },
        {
            key: 'cpi',
            text: 'CPI',
            width: 100,
            vPadding: '0.875rem',
            sortable: true,
        },
    ]

    const vpiHeaders = [
        {
            key: 'impressions',
            text: 'Impr.',
            width: 100,
            vPadding: '0.875rem',
            sortable: true,
        },
        {
            key: 'conversionValue',
            text: 'C. Value',
            width: 100,
            vPadding: '0.875rem',
            sortable: true,
        },
        {
            key: 'vpi',
            text: 'VPI',
            width: 100,
            vPadding: '0.875rem',
            sortable: true,
        },
    ]

    const relevantHeaders = computed(() => {
        if (performanceMaxActive.value) {
            if (performanceMode.value === Targets.PerformanceMode.CPA) return cpiHeaders
            return vpiHeaders
        }
        if (performanceMode.value === Targets.PerformanceMode.CPA) return cpaHeaders
        return roasHeaders
    })

    const tableHeaders = computed(() => [
        {
            key: 'ngram',
            text: 'N-Gram',
            noPadding: true,
            vPadding: '0.875rem',
            sortable: false,
        },
        ...relevantHeaders.value,
        {
            key: 'vsAvg',
            text: 'vs Avg.',
            width: 98,
            vPadding: '0.875rem',
            sortable: true,
        },
        {
            key: 'nscoreSortValue',
            text: 'nScore',
            width: 98,
            vPadding: '0.875rem',
            sortable: true,
        },
        {
            key: 'keywordConflicts',
            text: 'Affected Keywords',
            width: 160,
            vPadding: '0.875rem',
            sortable: false,
        },
        {
            key: 'actions',
            text: 'Actions',
            width: 160,
            vPadding: '0.875rem',
            sortable: false,
        },
    ])

    /**
     * Selected nGrams in the first step
     */
    const initialNgramSelection = ref<string[]>([])

    const initialNgramSelectionCount = computed(() => initialNgramSelection.value.length)

    /**
     * Selected nGrams for the kw destination
     */
    const finalNgramSelection = ref<string[]>([])
    const finalNgramSelectionCount = computed(() => finalNgramSelection.value.length)

    const selectedNGramItems = computed(() =>
        nGramItems.value.filter(
            ngram => ngram.ngram && initialNgramSelection.value.includes(ngram.ngram)
        )
    )

    const allNGramsSelected = computed(() => {
        return currentStep.value === 0
            ? searchedNgramItems.value.length &&
                  initialNgramSelectionCount.value === searchedNgramItems.value?.length
            : finalNgramSelectionCount.value === selectedNGramItems.value.length
    })

    function toggleAllNgrams() {
        if (!allNGramsSelected.value) {
            selectAllNgrams()
        } else {
            clearAllNgrams()
        }
    }

    function toggleAllNegatives() {
        if (!allNGramsSelected.value) {
            selectAllNegatives()
        } else {
            clearAllNegatives()
        }
    }

    function selectAllNgrams() {
        initialNgramSelection.value = searchedNgramItems.value?.map(item => item.ngram) ?? []
    }

    function clearAllNgrams() {
        initialNgramSelection.value = []
    }

    function selectAllNegatives() {
        finalNgramSelection.value = selectedNGramItems.value.map(item => item.ngram)
    }

    function clearAllNegatives() {
        finalNgramSelection.value = []
    }

    function selectNgram(ngram: string) {
        const checked = !initialNgramSelection.value.find(_ngram => _ngram === ngram)

        if (checked) {
            initialNgramSelection.value.push(ngram)
        } else {
            initialNgramSelection.value = initialNgramSelection.value.filter(
                _ngram => _ngram !== ngram
            )
        }
    }

    function selectNegative(ngram: string) {
        const checked = !finalNgramSelection.value.find(_ngram => _ngram === ngram)

        if (checked) {
            finalNgramSelection.value.push(ngram)
        } else {
            finalNgramSelection.value = finalNgramSelection.value.filter(_ngram => _ngram !== ngram)
        }
    }

    watch(currentStep, async nextStep => {
        if (nextStep === 1) {
            finalNgramSelection.value = initialNgramSelection.value
        }
        await nextTick()
        // reset scroll position between steps
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'auto',
        })
    })

    watch(currentRoute, () => {
        applyCampaignSelection()
        resetState()
    })

    // Campaigns Selection Table
    const { data: nGramCampaigns, loading: nGramCampaignsLoading } = useAPI<
        NgramTool.NgramCampaign[]
    >(Endpoint.GetNgramCampaigns, {
        body: () => ({
            accountId: accountId.value,
            lookbackWindow: lookbackWindow.value.value,
            groupId: groupId.value,
        }),
        uniqueId: () => `${accountId.value}:${lookbackWindow.value.value}:${groupId.value}`,
        waitFor: () => accountId.value && lookbackWindow.value.value && groupId.value,
    })

    const searchQuery = ref<string>('')

    const { data: sharedSetData, mutate: mutateSharedSetData } = useAPI<
        {
            shared_set_id: number
            shared_set_name: string
            shared_set_resource_name: string
        }[]
    >(Endpoint.GetSharedNegativeLists, {
        body: { type: 'keywords' },
        uniqueId: () => accountId.value,
        waitFor: () => accountId.value,
    })

    const { data: activeCampaignData, loading: activeCampaignsLoading } = useAPI<
        {
            advertising_channel_type: Gads.enums.AdvertisingChannelType
            campaign_id: number
            campaign_name: string
            adgroups_data: {
                adgroup: string
                adgroup_id: number
                campaign_id: number
            }[]
        }[]
    >(Endpoint.GetCampaignsAndAdgroups, {
        body: { campaign_status_enabled: true },
        uniqueId: () => accountId.value,
        waitFor: () => accountId.value,
    })

    const campaignData = computed(() => {
        const activeCampaigns = activeCampaignData.value || []

        return activeCampaigns.length ? activeCampaigns : []
    })

    const newNegativeListCampaigns = computed<{ value: string; label: string }[]>(() => {
        return campaignData.value.map(campaign => {
            return { value: campaign.campaign_id.toString(), label: campaign.campaign_name }
        })
    })

    const filteredCampaigns = computed(() => {
        return (
            nGramCampaigns.value?.filter(campaign => {
                if (performanceMaxActive.value)
                    return (
                        campaign.campaignType === Gads.enums.AdvertisingChannelType.PERFORMANCE_MAX
                    )
                if (shoppingActive.value)
                    return campaign.campaignType === Gads.enums.AdvertisingChannelType.SHOPPING
                return campaign.campaignType === Gads.enums.AdvertisingChannelType.SEARCH
            }) ?? []
        )
    })

    const relevantCampaignIds = computed(
        () =>
            filteredCampaigns.value
                ?.filter(campaign => !excludedCampaigns.value?.includes(campaign.campaignId))
                .map(campaign => campaign.campaignId)
    )

    const selectedFilteredCampaigns = computed(() => {
        if (!stringifiedCampaignSelection.value) return []

        // Get the campaign selection from the stringified version, which is only updated when the user changes the selection
        const campaignIds = JSON.parse(stringifiedCampaignSelection.value)

        return filteredCampaigns.value.filter(campaign => campaignIds.includes(campaign.campaignId))
    })

    const computedBody = computed(() => {
        const filters: NgramTool.NgramFilters = {
            excludeOneWordNGrams: excludeNGramFilters.value.oneWordNGrams.checked,
            excludeTwoWordNGrams: excludeNGramFilters.value.twoWordNGrams.checked,
            excludeThreeWordNGrams: excludeNGramFilters.value.threeWordNGrams.checked,
            excludeStopwords: excludeNGramFilters.value.stopWords.checked,
            excludeLowVolumeNGrams: excludeNGramFilters.value.lowVolume.checked,
            excludeSearchPartners: excludeSearchTermFilters.value.searchPartners.checked,
            excludeSearchTermsBlockedByNegatives:
                excludeSearchTermFilters.value.searchTermsBlockedByNegatives.checked,
            excludePausedKeywords: excludeKeywordFilters.value.pausedKeywords.checked,
            excludeExactMatchs: excludeMatchTypeFilters.value.exactMatch.checked,
            excludeExactMatchCloseVariants:
                excludeMatchTypeFilters.value.exactMatchCloseVariant.checked,
            excludePhraseMatchs: excludeMatchTypeFilters.value.phraseMatch.checked,
            excludePhraseMatchCloseVariants:
                excludeMatchTypeFilters.value.phraseMatchCloseVariant.checked,
            excludeBroadMatchs: excludeMatchTypeFilters.value.broadMatch.checked,
            showOnlyPoorlyPerformingIndustryNgrams:
                shinyFilters.value.industryPoorlyPerforming.checked,
            showOnlyPoorlyPerformingBounceRateNgrams:
                shinyFilters.value.bounceRatePoorlyPerforming.checked,
        }

        return {
            accountId: accountId.value,
            lookbackWindow: lookbackWindow.value.value,
            campaignIds: relevantCampaignIds.value,
            groupId: groupId.value,
            filters,
        }
    })

    // This uniqueId will revalidate the data when changed (e.g. when the user selects a new campaign)
    const uniqueId = computed(
        () =>
            `${accountId.value}:${stringifiedFilters.value}:${stringifiedCampaignSelection.value}:${lookbackWindow.value.value}`
    )

    function applyFilters() {
        stringifiedFilters.value = JSON.stringify(computedBody.value.filters)
    }

    function applyCampaignSelection() {
        stringifiedCampaignSelection.value = JSON.stringify(relevantCampaignIds.value)
    }

    const {
        data: nGramData,
        isValidating,
        loading,
        mutate: mutateNGramData,
        error: nGramDataError,
    } = useAPI<NgramTool.NgramData[]>(Endpoint.GetNgramData, {
        body: () => computedBody.value,
        uniqueId: () => uniqueId.value,
        waitFor: () =>
            !nGramCampaignsLoading.value &&
            stringifiedFilters.value &&
            lookbackWindow.value.value &&
            !activeCampaignsLoading.value &&
            groupId.value,
    })

    const nGramDataLoading = computed(() => {
        if (loading.value || isValidating.value) return true

        // Can't use the app without these 2 cached
        if (
            nGramCacheState.value.reason === 'SEARCH_TERM_VIEW_NOT_CACHED' ||
            nGramCacheState.value.reason === 'BLOCKED_SEARCH_TERM_NOT_CACHED'
        )
            return true

        // Can't use the pMAx without this cached
        if (
            nGramCacheState.value.reason === 'SEARCH_TERM_INSIGHT_NOT_CACHED' &&
            performanceMaxActive.value
        )
            return true

        return false
    })

    const allCampaignsSelected = computed(() => {
        if (!filteredCampaigns.value?.length) return false
        return campaignSelectionCount.value === filteredCampaigns.value.length
    })

    function selectAllCampaigns() {
        if (!allCampaignsSelected.value) {
            excludedCampaigns.value = []
            return
        }

        excludedCampaigns.value =
            filteredCampaigns.value?.map(campaign => campaign.campaignId) ?? []
    }

    function toggleCampaign(campaignId: number) {
        if (excludedCampaigns.value?.includes(campaignId)) {
            excludedCampaigns.value = excludedCampaigns.value?.filter(
                campaign => campaign !== campaignId
            )
            return
        }

        excludedCampaigns.value = [...(excludedCampaigns.value ?? []), campaignId]
    }

    const campaignSelectionCount = computed(
        () =>
            filteredCampaigns.value?.filter(c => !excludedCampaigns.value?.includes(c.campaignId))
                ?.length ?? 0
    )

    // nGram Selection State
    const ngramAverage = computed(() => {
        const campaigns = selectedFilteredCampaigns.value

        const ngramAverageImpressions = sumBy(campaigns, c => c.impressions)
        const ngramAverageCost = sumBy(campaigns, c => c.cost)
        const ngramAverageConversions = sumBy(campaigns, c => c.conversions)
        const ngramAverageConversionValue = sumBy(campaigns, c => c.conversionValue)

        return calculatePerformanceMetric(
            ngramAverageImpressions,
            ngramAverageCost,
            ngramAverageConversions,
            ngramAverageConversionValue
        )
    })

    const performanceMetricType = computed(() =>
        performanceMode.value === Targets.PerformanceMode.CPA
            ? performanceMaxActive.value
                ? 'cpi'
                : 'cpa'
            : performanceMaxActive.value
            ? 'vpi'
            : 'roas'
    )

    function generateNscoreColor(nscore: number | null) {
        if (nscore === null) return '#e6e6e6' // grey
        if (nscore >= 0.7) return '#FF2828' // red
        if (nscore > 0.5) return '#FF9500' // amber
        return '#00a861' // green
    }

    const avgEngagementRate = computed(() => {
        const campaigns = selectedFilteredCampaigns.value

        return sumBy(campaigns, c => c.ga4EngagedSessions) / sumBy(campaigns, c => c.ga4Sessions)
    })

    const nGramItems = computed(
        () =>
            nGramData.value?.map(item => {
                const { impressions, cost, conversions, conversionValue } = item

                // Needs to be recalculated in the frontend because of Infinity
                const performanceMetric = calculatePerformanceMetric(
                    impressions ?? 0,
                    cost ?? 0,
                    conversions ?? 0,
                    conversionValue ?? 0
                )

                const vsAvg = (performanceMetric - ngramAverage.value) / ngramAverage.value

                const vsAverageColor = generateVsAvgColor(vsAvg)

                const nScoreColor = generateNscoreColor(item.nscore ?? null)

                return {
                    ...item,
                    nscoreSortValue: isNull(item.nscore) ? -Infinity : item.nscore,
                    cpa: performanceMetric,
                    roas: performanceMetric,
                    cpi: performanceMetric,
                    vpi: performanceMetric,
                    vsAvg,
                    vsAverageColor,
                    nScoreColor,
                }
            }) ?? []
    )

    // Ngram search
    const searchedNgramText = ref('')

    const searchedNgramItems = computed(() => {
        if (!searchedNgramText.value) {
            // No search text, return all ngrams
            return nGramItems.value
        }

        const searchText = searchedNgramText.value.toLowerCase()

        return nGramItems.value.filter(item => item.ngram.toLowerCase().includes(searchText))
    })

    // Spread Bar
    const SPREAD_CHART_LENGTH = 12
    const spreadChartItems = computed(() =>
        orderBy(
            orderBy(nGramItems.value, nGramItem => nGramItem.nscore ?? -Infinity, 'desc').slice(
                0,
                SPREAD_CHART_LENGTH
            ),
            'cost',
            'desc'
        )
    )

    const campaignTableHeaders = computed(() => [
        {
            key: 'campaignName',
            text: 'Campaign',
            noPadding: true,
            vPadding: '0.875rem',
            sortable: false,
        },
        {
            key: 'cost',
            text: 'Cost',
            vPadding: '0.875rem',
            width: 120,
            sortable: true,
        },
        performanceMode.value === Targets.PerformanceMode.CPA
            ? {
                  key: 'conversions',
                  text: 'Conv.',
                  vPadding: '0.875rem',
                  width: 120,
                  sortable: true,
              }
            : {
                  key: 'conversionValue',
                  text: 'Value',
                  vPadding: '0.875rem',
                  width: 120,
                  sortable: true,
              },
        performanceMode.value === Targets.PerformanceMode.CPA
            ? {
                  key: 'cpa',
                  text: 'CPA',
                  vPadding: '0.875rem',
                  width: 120,
                  sortable: true,
              }
            : {
                  key: 'roas',
                  text: 'ROAS',
                  vPadding: '0.875rem',
                  width: 120,
                  sortable: true,
              },
        {
            key: 'campaignGroupName',
            text: 'Campaign Group',
            vPadding: '0.875rem',
            width: 320,
            sortable: true,
        },
    ])

    const campaignTableItems = computed(() =>
        filteredCampaigns.value
            .filter(campaign => {
                if (!searchQuery.value) return true
                return campaign.campaignName.toLowerCase().includes(searchQuery.value.toLowerCase())
            })
            .map(row => {
                const cpa = row.cost > 0 ? row.cost / row.conversions : 0
                const roas = row.conversionValue > 0 ? row.conversionValue / row.cost : 0

                return {
                    ...row,
                    cpa,
                    roas,
                }
            })
    )

    watch(nGramCampaigns, () => {
        if (nGramCampaigns.value) {
            applyCampaignSelection()

            applyFilters()
        }
    })

    // Panel Table Headers
    const detailedViewNgramHeaders = computed(() => [
        { key: 'ngram', text: 'N-Gram', vPadding: '0.875rem', sortable: true },
        ...relevantHeaders.value.map(header => ({
            ...header,
            width: performanceMaxActive.value || shoppingActive.value ? 98 : 94,
        })),
        {
            key: 'vsAvg',
            text: 'vs Avg.',
            width: 102,
            vPadding: '0.875rem',
            sortable: true,
        },
        {
            key: 'nscoreSortValue',
            text: 'nScore',
            width: 102,
            vPadding: '0.875rem',
            sortable: true,
        },
        {
            key: 'keywordConflicts',
            text: 'Affected Keywords',
            width: 198,
            vPadding: '0.875rem',
            sortable: true,
        },
    ])

    // Keyword Destinations Logic
    const newNegativeListRn = ref('')
    const onPushError = ref({ status: false, message: '' })
    const addingNgramsToNegative = ref(false)

    const accountLevelEntity = ref({
        id: 'entity-id',
        label: 'Account Level',
        type: 'account-level',
        checked: false,
    })

    const destinations = computed(() => [
        {
            key: 'negative-list',
            name: 'Negative Lists',
            active: true,
            count: accountLevelEntity.value.checked ? 1 : 0,
        },
        ...(!performanceMaxActive.value
            ? [{ key: 'campaign', name: 'Campaigns', active: false }]
            : []),
    ])

    const existingSharedSetNames = computed(
        () => sharedSetData.value?.map(set => set.shared_set_name)
    )

    // Snackbar logic
    const nGramCacheState = ref<{
        isCached: boolean
        reason?: keyof typeof loadingStateMessages
    }>({
        isCached: false,
    })

    const snackBarMessage = computed(
        () => nGramCacheState.value?.reason && loadingStateMessages[nGramCacheState.value.reason]
    )

    const cacheSetInterval = ref<ReturnType<typeof setInterval>>()

    function setupSetInterval() {
        if (cacheSetInterval.value) clearInterval(cacheSetInterval.value)

        cacheSetInterval.value = setInterval(async () => {
            await checkCache()
        }, 1_000)
    }

    async function checkCache() {
        const res = await authRequest<{
            isCached: boolean
            reason?: keyof typeof loadingStateMessages
        }>(Endpoint.CheckNgramCache, {
            body: {
                groupId: groupId.value,
                accountId: accountId.value,
                lookbackWindow: lookbackWindow.value.value,
            },
        })

        nGramCacheState.value = res

        if (res.isCached) {
            cacheSetInterval.value && clearInterval(cacheSetInterval.value)
        }
    }

    watch(lookbackWindow, async () => {
        nGramCacheState.value = {
            isCached: false,
        }

        await setupSetInterval()
    })

    onBeforeUnmount(() => {
        cacheSetInterval.value && clearInterval(cacheSetInterval.value)
        hideSnackbar()
    })

    onMounted(() => setupSetInterval())

    // Refresh data if needed logic
    watch(snackBarMessage, async (newVal, oldVal) => {
        if (newVal) {
            showSnackbar({
                message: newVal,
                indefiniteTimeout: true,
            })

            await setupSetInterval()
        }

        if (newVal && !oldVal) {
            return authRequest(Endpoint.DispatchHighPriorityNgramJob, {
                body: {
                    accountId: accountId.value,
                    lookbackWindow: lookbackWindow.value.value,
                },
            })
        }

        if (!newVal && oldVal) {
            await mutateNGramData()
            nGramCacheState.value = {
                isCached: true,
            }

            cacheSetInterval.value && clearInterval(cacheSetInterval.value)
            hideSnackbar()

            return
        }

        if (!newVal) {
            hideSnackbar()

            return
        }
    })

    // pMax Opt in logic
    const { data: optedInToPMax, mutate: checkIfOptedIn } = useAPI<boolean>(
        Endpoint.IsAccountOptedInToPMax,
        {
            body: () => ({
                accountId: accountId.value,
            }),
            uniqueId: () => accountId.value,
            waitFor: () => accountId.value,
        }
    )

    const showPMaxOptInMessage = computed(() => {
        if (!performanceMaxActive.value) return false

        const performanceMaxCampaignCount =
            countBy(
                nGramCampaigns.value,
                c => c.campaignType === Gads.enums.AdvertisingChannelType.PERFORMANCE_MAX
            ).true ?? 0

        if (performanceMaxCampaignCount === 0) return false

        return !optedInToPMax.value
    })
    const pMaxOptInButton = ref<typeof oButton>()
    const optingInToPMax = ref(false)

    async function optInToPMax() {
        optingInToPMax.value = true

        await authRequest(Endpoint.OptInToPMax, {
            body: {
                accountId: accountId.value,
            },
        })

        optingInToPMax.value = false
        pMaxOptInButton.value?.flashSuccess()

        await delay(2400)
        await checkCache()
        await checkIfOptedIn()
    }

    // Util functions
    function calculatePerformanceMetric(
        impressions: number,
        cost: number,
        conversions: number,
        conversionValue: number
    ) {
        if (performanceMaxActive.value) {
            if (performanceMode.value === Targets.PerformanceMode.CPA) {
                return conversions > 0 ? conversions / impressions : 0 // CPI
            }
            return conversionValue > 0 ? conversionValue / impressions : 0 // VPI
        }

        if (performanceMode.value === Targets.PerformanceMode.CPA) {
            return cost > 0 ? cost / conversions : 0 // CPA
        }

        return conversionValue > 0 ? conversionValue / cost : 0 // ROAS
    }

    function generateVsAvgColor(vsAvg: number) {
        const merticIsInverted =
            !performanceMaxActive.value && performanceMode.value === Targets.PerformanceMode.CPA

        return (vsAvg > 0 && merticIsInverted) || (vsAvg < 0 && !merticIsInverted) ? 'red' : 'green'
    }

    function formatCpi(cpi: number) {
        if (cpi >= 1 || cpi === 0) return useNumber({ value: cpi }).displayValue.value
        return cpi.toPrecision(2)
    }

    function formatVpi(vpi: number) {
        if (vpi >= 1 || vpi === 0) return useDomainMoney({ value: vpi }).value.displayValue.value
        return `${currencySymbol.value}${vpi.toPrecision(2)}`
    }

    function downloadNgramCsv() {
        const items = orderBy(
            nGramItems.value.map(item => {
                const primaryColumns = {
                    'Account Name': accountInfo.value?.name,
                    CID: accountInfo.value?.idOnPlatform,
                    'N-Gram': item.ngram,
                }

                // CPI and VPI need more precision
                const performanceMetricRounding = performanceMaxActive.value === true ? 5 : 2

                const metricsColumns = {
                    Clicks: item.clicks,
                    Conversions: round(item.conversions, 2),
                    'Conversion Value': round(item.conversionValue, 2),
                    [performanceMetricType.value.toUpperCase()]: round(
                        item[performanceMetricType.value],
                        performanceMetricRounding
                    ),
                    [`${performanceMetricType.value.toUpperCase()} Avg`]: round(
                        ngramAverage.value,
                        performanceMetricRounding
                    ),
                    [`${performanceMetricType.value.toUpperCase()} vs Avg`]: round(item.vsAvg, 2),
                }

                const insightColumns = {
                    'Underperforming in Industry': item.hasPoorIndustryPerformance
                        ? 'TRUE'
                        : 'FALSE',
                    'GA4 Total Sessions': item.ga4Sessions,
                    'GA4 Engaged Sessions': item.ga4EngagedSessions,
                    'GA4 Engagement Rate': item.ga4Sessions
                        ? round(item.ga4EngagedSessions / item.ga4Sessions, 2)
                        : 0,
                    'Flagged for High Bounce Rate': item.hasPoorEngagement,
                }

                if (performanceMaxActive.value) {
                    return {
                        ...primaryColumns,
                        Impressions: item.impressions,
                        ...metricsColumns,
                        ...insightColumns,
                    }
                } else {
                    return {
                        ...primaryColumns,
                        Cost: round(item.cost, 2),
                        ...metricsColumns,
                        'Number of Contributing Search Terms': item.searchTerms.length,
                        ...insightColumns,
                    }
                }
            }),
            // @ts-expect-error impressions is defined when performanceMaxActive is true, and vice-versa
            r => (performanceMaxActive.value ? r.impressions : r.cost),
            'desc'
        )

        const formattedLookbackWindow = lookbackWindow.value.label.replace('Last ', '')

        downloadCsv({
            dataSet: `${accountName.value} - Ngrams - ${formattedLookbackWindow}`,
            columnHeaders: Object.keys(items[0]),
            items,
        })
    }

    const toProvide = {
        // Navigation
        tabLinks,
        steps,
        currentStep,
        goToNGramSelection,
        goToNegativeDestination,
        closeNGramTool,
        currencyCode,
        performanceMaxActive,
        shoppingActive,
        downloadNgramCsv,
        resetState,

        // NGram Tables
        tableHeaders,
        nGramItems,
        searchedNgramItems,
        selectedNGramItems,
        allNGramsSelected,
        initialNgramSelectionCount,
        toggleAllNgrams,
        clearAllNgrams,
        selectNgram,
        toggleAllNegatives,
        selectNegative,
        nGramDataLoading,
        addingNgramsToNegative,
        initialNgramSelection,
        finalNgramSelection,
        finalNgramSelectionCount,
        mutateNGramData,
        searchedNgramText,
        avgEngagementRate,

        // Filters Logic
        applyFilters,
        applyCampaignSelection,
        uniqueId,

        // Campaign Table
        campaignTableHeaders,
        campaignTableItems,
        campaignSelectionCount,
        searchQuery,
        allCampaignsSelected,
        selectAllCampaigns,
        toggleCampaign,
        ngramAverage,
        relevantHeaders,

        // Panels
        detailedViewNgramHeaders,

        // Indicator
        SPREAD_CHART_LENGTH,
        spreadChartItems,
        performanceMetricType,

        // Keywords Destinations
        destinations,
        accountLevelEntity,
        newNegativeListCampaigns,
        existingSharedSetNames,
        onPushError,
        sharedSetData,
        campaignData,
        newNegativeListRn,

        // Adding the Negatives
        mutateSharedSetData,

        showPMaxOptInMessage,
        optInToPMax,
        optingInToPMax,
        pMaxOptInButton,

        // Util functions
        calculatePerformanceMetric,
        generateVsAvgColor,
        formatCpi,
        formatVpi,
    }

    provide('nGramTool', toProvide)

    return toProvide
}

export function useNGramTool() {
    const injected = inject<ReturnType<typeof provideNGramTool>>('nGramTool')

    if (!injected) {
        throw new Error(
            `useNGramTool not yet injected, something is wrong. useNGramTool() can only be called in a /ngram/ route.`
        )
    }

    return injected
}
