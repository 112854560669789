<template>
    <div
        v-if="keywordResourceNames"
        :class="['keyword-conflicts', { 'has-modal': hasModal }]"
        @click="hasModal ? (showModal = true) : undefined"
    >
        <EntityPill
            :type="Improvement.LocationEntity.Keyword"
            :content="`${keywordResourceNames?.length} ${
                keywordResourceNames.length === 1 ? 'Keyword' : 'Keywords'
            }`"
            :tooltip="false"
        />
        <div v-if="hasModal" class="open-modal-button">
            <oButton color="white" size="small">Show</oButton>
        </div>
    </div>
    <div v-else class="keyword-conflicts">
        <EntityPill :type="Improvement.LocationEntity.Keyword">
            <Skeleton
                width="4.5rem"
                color="hsla(174, 100%, 22%, 0.2)"
                shimmer-color="hsla(174, 100%, 22%, 0.06)"
            />
        </EntityPill>
    </div>

    <Modal v-model="showModal" width="calc(100% - 44px)" noPadding>
        <template #title>
            <Text as="h3" weight="600">Affected Keywords</Text>
            <Spacer width="1rem" />
            <EntityPill :type="Improvement.LocationEntity.NGram" :content="ngram" />
        </template>

        <template #content>
            <Section :group="true" :outer-shadow="false" :items="keywordSectionItems">
                <template v-for="item in keywordSectionItems" v-slot:[`${item.id}-header-content`]>
                    <OnboardingModal
                        v-if="item.keywords.length > 0"
                        :model-value="true"
                        :full-width="false"
                        max-width="36.5rem"
                        style="padding: 0.75rem 1.25rem; border-radius: 1.125rem"
                    >
                        <template #copy>
                            Adding this negative at <b>account level</b> could prevent
                            <b>{{ item.trafficAmount }}</b> ads showing for searches that match the
                            following {{ item.title }} keywords.
                        </template>
                    </OnboardingModal>
                </template>
                <template v-slot:[`${item.id}-content`] v-for="item in keywordSectionItems">
                    <oTable
                        v-if="item.keywords.length > 0"
                        :headers="keywordHeaders"
                        :items="item.keywords"
                        fixed-layout
                        :border-radius="24"
                        class="affected-keywords-table"
                        order-by="cost"
                        order="DESC"
                        :per-page="8"
                    >
                        <template #column.keywordText="{ value }">
                            <Tooltip
                                v-if="typeof value === 'string'"
                                :visibleOnlyWhenOverflow="true"
                                :content="value"
                                :offset="[-8, 20]"
                                :delay="[400, 0]"
                            >
                                <EntityPill
                                    :type="Improvement.LocationEntity.Keyword"
                                    :content="value"
                                />
                            </Tooltip>

                            <EntityPill v-else :type="Improvement.LocationEntity.Keyword">
                                <Skeleton
                                    width="6rem"
                                    color="hsla(174, 100%, 22%, 0.2)"
                                    shimmer-color="hsla(174, 100%, 22%, 0.06)"
                                />
                            </EntityPill>
                        </template>
                        <template #column.campaignName="{ value }">
                            <Tooltip
                                v-if="typeof value === 'string'"
                                :visibleOnlyWhenOverflow="true"
                                :content="value"
                                :offset="[-8, 20]"
                                :delay="[200, 0]"
                            >
                                <EntityPill
                                    :type="Improvement.LocationEntity.Campaign"
                                    :content="value"
                                />
                            </Tooltip>

                            <EntityPill v-else :type="Improvement.LocationEntity.Campaign">
                                <Skeleton
                                    width="6rem"
                                    color="hsla(214, 100%, 50%, 0.2)"
                                    shimmer-color="hsla(214, 100%, 50%, 0.06)"
                                />
                            </EntityPill>
                        </template>
                        <template #column.adGroupName="{ value }">
                            <Tooltip
                                v-if="typeof value === 'string'"
                                :visibleOnlyWhenOverflow="true"
                                :content="value"
                                :offset="[-8, 20]"
                                :delay="[200, 0]"
                            >
                                <EntityPill
                                    :type="Improvement.LocationEntity.AdGroup"
                                    :content="value"
                                />
                            </Tooltip>

                            <EntityPill v-else :type="Improvement.LocationEntity.AdGroup">
                                <Skeleton
                                    width="6rem"
                                    color="hsla(256, 100%, 61%, 0.2)"
                                    shimmer-color="hsla(256, 100%, 61%, 0.06)"
                                />
                            </EntityPill>
                        </template>

                        <template #column.cost="{ value }">
                            <Money v-if="typeof value === 'number'" :value="value" />

                            <Skeleton v-else width="3rem" />
                        </template>
                        <template #column.impressions="{ value }">
                            <Number v-if="typeof value === 'number'" :value="value" />
                            <Skeleton v-else width="3rem" />
                        </template>
                        <template #column.clicks="{ value }">
                            <Number v-if="typeof value === 'number'" :value="value" />
                            <Skeleton v-else width="3rem" />
                        </template>
                        <template #column.conversions="{ value }">
                            <Number v-if="typeof value === 'number'" :value="value" />
                            <Skeleton v-else width="3rem" />
                        </template>
                        <template #column.conversionValue="{ value }">
                            <Money v-if="typeof value === 'number'" :value="value" />
                            <Skeleton v-else width="3rem" />
                        </template>
                        <template #column.cpa="{ value }">
                            <Money v-if="typeof value === 'number'" :value="value" />
                            <Skeleton v-else width="3rem" />
                        </template>
                        <template #column.roas="{ value }">
                            <Percent v-if="typeof value === 'number'" :value="value" />
                            <Skeleton v-else width="3rem" />
                        </template>
                        <template #column.cpi="{ value }">
                            <span v-if="typeof value === 'number'"> {{ formatCpi(value) }}</span>
                            <Skeleton v-else width="3rem" />
                        </template>
                        <template #column.vpi="{ value }">
                            <span v-if="typeof value === 'number'">
                                {{ formatVpi(value) }}
                            </span>
                            <Skeleton v-else width="3rem" />
                        </template>
                        <template #column.vsAvg="{ value, row }">
                            <Text
                                v-if="typeof value === 'number'"
                                size="f-8"
                                weight="500"
                                :color="row.vsAverageColor"
                            >
                                <Percent :value="value" :decimal-places="2" include-sign />
                            </Text>
                            <Skeleton v-else width="3rem" />
                        </template>
                    </oTable>
                    <div v-else class="empty-state-container">
                        <Text size="f-8" style="max-width: 320px" align="center">
                            No <b>{{ item.title }}</b> keywords will be affected when adding this
                            ngram as a negative keyword.
                        </Text>
                    </div>
                </template>
            </Section>
        </template>
    </Modal>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue'

import {
    Section,
    Text,
    Spacer,
    oButton,
    EntityPill,
    oTable,
    Modal,
    Number,
    Percent,
    OnboardingModal,
    Tooltip,
} from '@opteo/components-next'
import { Gads, Improvement, NgramTool } from '@opteo/types'

import { useNGramFilters } from '@/composition/toolkit/nGramTool/useNGramFilters'
import { useNGramTool } from '@/composition/toolkit/nGramTool/useNGramTool'
import Skeleton from '@/components/util/Skeleton.vue'
import Money from '@/components/global/oMoney.vue'
import { useAccount } from '@/composition/account/useAccount'
import { Endpoint, useAPI } from '@/composition/api/useAPI'

type Props = {
    ngram: string
    modal?: boolean
}

const showModal = ref(false)

const props = withDefaults(defineProps<Props>(), {
    modal: true,
})

const { accountId } = useAccount()
const { lookbackWindow } = useNGramFilters()
const {
    relevantHeaders,
    calculatePerformanceMetric,
    ngramAverage,
    formatCpi,
    formatVpi,
    generateVsAvgColor,
} = useNGramTool()

const { data: affectedKeywords } = useAPI<
    {
        resourceName: string
        matchType: Gads.enums.KeywordMatchType
    }[]
>(Endpoint.GetNgramAffectedKeywords, {
    body: () => ({
        accountId: accountId.value,
        ngram: props.ngram,
    }),
    uniqueId: () => `${accountId.value}:${props.ngram}`,
})

const keywordResourceNames = computed(
    () => affectedKeywords.value?.map(keyword => keyword.resourceName)
)

const { data: keywordMetrics, loading } = useAPI<NgramTool.KeywordMetrics[]>(
    Endpoint.GetKeywordMetrics,
    {
        body: () => ({
            accountId: accountId.value,
            keywordResourceNames: keywordResourceNames.value,
            lookbackWindow: lookbackWindow.value.value,
        }),
        uniqueId: () => `${JSON.stringify(keywordResourceNames.value)}`,
        waitFor: () => showModal.value && keywordResourceNames.value,
    }
)

const keywords = computed(
    () =>
        affectedKeywords.value?.map(affectedKeyword => {
            const metrics = keywordMetrics.value?.find(
                keyword => keyword.resourceName === affectedKeyword.resourceName
            )

            if (!metrics) return affectedKeyword

            const performanceMetric = calculatePerformanceMetric(
                metrics.impressions,
                metrics.cost,
                metrics.conversions,
                metrics.conversionValue
            )

            const vsAvg = (performanceMetric - ngramAverage.value) / ngramAverage.value

            const vsAverageColor = generateVsAvgColor(vsAvg)

            return {
                cpa: performanceMetric,
                roas: performanceMetric,
                cpi: performanceMetric,
                vpi: performanceMetric,
                vsAvg,
                vsAverageColor,
                ...metrics,
                ...affectedKeyword,
            }
        }) ?? []
)

const hasModal = computed(
    () => props.modal && keywordResourceNames.value && keywordResourceNames.value.length > 0
)

const keywordSectionItems = computed(() => {
    const exactMatchKeywords = []
    const phraseMatchKeywords = []
    const broadMatchKeywords = []

    keywords.value.forEach(keyword => {
        switch (keyword.matchType) {
            case Gads.enums.KeywordMatchType.EXACT:
                exactMatchKeywords.push(keyword)
                break
            case Gads.enums.KeywordMatchType.PHRASE:
                phraseMatchKeywords.push(keyword)
                break
            case Gads.enums.KeywordMatchType.BROAD:
                broadMatchKeywords.push(keyword)
                break
            default:
                break
        }
    })

    const sections = []
    if (exactMatchKeywords.length > 0) {
        sections.push({
            id: 'exact-match',
            title: 'Exact Match',
            trafficAmount: 'all',
            innerShadow: false,
            keywordMatchType: Gads.enums.KeywordMatchType.EXACT,
            keywords: exactMatchKeywords,
        })
    }
    if (phraseMatchKeywords.length > 0) {
        sections.push({
            id: 'phrase-match',
            title: 'Phrase Match',
            trafficAmount: 'the majority of',
            innerShadow: false,
            keywordMatchType: Gads.enums.KeywordMatchType.PHRASE,
            keywords: phraseMatchKeywords,
        })
    }
    if (broadMatchKeywords.length > 0) {
        sections.push({
            id: 'broad-match',
            title: 'Broad Match',
            trafficAmount: 'some',
            innerShadow: false,
            keywordMatchType: Gads.enums.KeywordMatchType.BROAD,
            keywords: broadMatchKeywords,
        })
    }

    return sections
})

const keywordHeaders = computed(() => [
    { key: 'keywordText', text: 'Keyword', vPadding: '0.875rem', sortable: true },
    { key: 'campaignName', text: 'Campaign', vPadding: '0.875rem', sortable: true },
    { key: 'adGroupName', text: 'Ad Group', vPadding: '0.875rem', sortable: true },
    { key: 'impressions', text: 'Impr.', width: 96, vPadding: '0.875rem', sortable: true },
    { key: 'clicks', text: 'Clicks', width: 96, vPadding: '0.875rem', sortable: true },
    ...relevantHeaders.value.map(header => ({
        ...header,
        width: 98,
    })),
    {
        key: 'vsAvg',
        text: 'vs Avg.',
        width: 120,
        vPadding: '0.875rem',
        sortable: true,
    },
])
</script>

<style scoped lang="scss">
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

:deep(.affected-keywords-table td[data-label='Keyword']),
:deep(.affected-keywords-table td[data-label='Campaign']),
:deep(.affected-keywords-table td[data-label='Ad Group']) {
    overflow: hidden;
    position: relative;
}
:deep(.affected-keywords-table td[data-label='Keyword']::after),
:deep(.affected-keywords-table td[data-label='Campaign']::after),
:deep(.affected-keywords-table td[data-label='Ad Group']::after) {
    content: '';
    background: linear-gradient(270deg, #fff 1.5rem, rgba(255, 255, 255, 0) 100%);
    @include absolute;
    top: 0;
    right: -1rem;
    width: 6rem;
    bottom: 0;
    pointer-events: none;
}

.empty-state-container {
    @include container;
    @include br-24;
    @include flex-center;
    flex-direction: column;
    gap: 1.5rem;
    width: 100%;
    padding: 7rem 0;
}

.keyword-conflicts {
    display: flex;
    align-items: center;
    position: relative;
}
.keyword-conflicts.has-modal {
    cursor: pointer;
}

.open-modal-button {
    position: absolute;
    right: -2.625rem;
    top: 50%;
    transform: translate(0, -50%);
    z-index: 2;
    opacity: 0;
}

.keyword-conflicts.has-modal:hover .open-modal-button {
    opacity: 1;
}

// .keyword-conflicts.has-modal::after {
//     content: '';
//     background: linear-gradient(270deg, #fff 1.5rem, rgba(255, 255, 255, 0) 100%);
//     @include absolute;
//     top: 0;
//     right: 0;
//     width: 4rem;
//     bottom: 0;
//     opacity: 0;
// }

// .keyword-conflicts.has-modal:hover::after {
//     opacity: 1;
// }

:deep(.keyword-source-popout-wrapper) {
    display: flex;
    align-items: center;
}

.popout-content {
    display: flex;
    flex-direction: column;
    padding: 1.5rem;
    gap: 1.5rem;
}

.popout-header {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.popout-text {
    @include container;
    padding: 1rem;
}
</style>
