<template>
    <div class="page-wrapper">
        <div v-if="!hideHeader" :class="['page-header', { 'has-middle': $slots['middle'] }]">
            <div v-if="$slots['left-header']" class="left">
                <slot name="left-header"> </slot>
            </div>
            <div v-else class="left">
                <Avatar
                    :color="accountColor"
                    :platform="accountPlatform"
                    show-account-type
                    :width="40"
                    :height="40"
                >
                    {{ accountInitials }}
                </Avatar>
                <Spacer width="1.375rem" />
                <Text as="h1">{{ title }}</Text>
            </div>
            <div v-if="$slots['middle']">
                <slot name="middle"></slot>
            </div>
            <div v-if="$slots['right-header']" class="right">
                <slot name="right-header"> </slot>
            </div>
        </div>
        <slot name="content"></slot>
    </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { Avatar, Spacer, Text } from '@opteo/components-next'
import { useAccount } from '@/composition/account/useAccount'

type Props = {
    title: string
    maxWidth?: number
    hideHeader?: boolean
}

const props = withDefaults(defineProps<Props>(), {
    maxWidth: 1232,
    hideHeader: false,
})

const { accountInitials, accountColor, accountPlatform } = useAccount()
const horizontalPadding = 80
const wrapperMaxWidth = computed(() => `${props.maxWidth + horizontalPadding}px`)
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';

.page-wrapper {
    width: 100%;
    @include ph-40;
    @include mb-64;
    @include block;
    margin-left: auto;
    margin-right: auto;
    max-width: v-bind(wrapperMaxWidth);
}
.page-header {
    padding-top: 5rem;
}

.left {
    @include flex;
    @include items-center;
}
.middle {
    @include flex;
    @include items-center;
}
.right {
    @include flex;
    @include items-center;
    justify-self: end;
}
@media screen and (min-width: $mq-768-min) {
    .page-header {
        display: grid;
        grid-template-columns: auto 1fr;
    }
}

@media screen and (min-width: $mq-1024-max) {
    .page-header {
        display: grid;
        margin-bottom: 4.5rem;
    }
    .page-header.has-middle {
        display: grid;
        grid-template-columns: 1fr auto 1fr;
    }
}
</style>
