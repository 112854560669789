<template>
    <ImprovementView
        :push-messages="pushMessages"
        :push-action-text="pushActionText"
        :steps="adjustSteps"
        :on-push="onPush"
    >
        <ImprovementContent>
            <ImprovementHeader :title="title">
                <Text as="p" v-if="hasExistingSitelinks">
                    Opteo recommends adding at least <b>4</b> sitelink extensions at the account
                    level. Sitelink extensions highlight and link searchers to specific pages on
                    your website. This should help to increase click through rates and improve
                    quality scores. Sitelinks are applied at the account level, and ensure coverage
                    across all campaigns in your account:
                </Text>
                <Text as="p" v-else>
                    Opteo recommends adding at least <b>4</b> sitelink extensions at the account
                    level. Sitelink extensions highlight and link searchers to specific pages on
                    your website. This should help to increase click through rates and improve
                    quality scores. Sitelinks are applied at the account level, and ensure coverage
                    across all campaigns in your account:
                </Text>

                <Spacer height="2rem" />
                <template v-if="entityPillLinkList">
                    <EntityPillLinkList
                        v-if="
                            needsShowingEntityPillList({
                                entities: entityPillLinkList,
                                content: entityPillLinkList[0].entityPillData.content,
                            })
                        "
                        :items="entityPillLinkList"
                    />
                    <EntityPillLink
                        v-else
                        :entity-pill-data="{
                            type: 'campaign',
                            content: entityPillLinkList[0].entityPillData.content,
                        }"
                        :deep-link-params="{
                            campaignId: entityPillLinkList[0].deepLinkParams.campaignId,
                        }"
                    />
                </template>
                <template v-else>
                    <EntityPillList
                        v-if="
                            needsShowingEntityPillList({
                                entities: entityPillList,
                                content: entityPillList[0].content,
                            })
                        "
                        :items="entityPillList"
                    />
                    <EntityPill
                        v-else
                        :type="entityPillList[0].type"
                        :content="entityPillList[0].content"
                    />
                </template>

                <Spacer height="2rem" />

                <Text as="p" v-if="hasExistingSitelinks">
                    Click <Text as="span" weight="600" color="blue">{{ adjustSteps[0] }}</Text> to
                    write, preview and add <b>{{ missingSitelinkCount }}</b> more sitelink
                    extension<span v-if="missingSitelinkCount !== 1">s</span> at the account level.
                    Sitelinks will be automatically applied to any new campaigns.
                </Text>
                <Text as="p" v-else>
                    Click <Text as="span" weight="600" color="blue">{{ adjustSteps[0] }}</Text> to
                    write, preview and add sitelink extensions to your campaigns. New campaigns will
                    have sitelink extensions applied automatically.
                </Text>
            </ImprovementHeader>
            <ImprovementHelpLink path="/improvements/extensions/add-sitelink-extensions">
                Click here to learn more about the benefits of sitelink extensions.
            </ImprovementHelpLink>
        </ImprovementContent>

        <div v-if="hasExistingSitelinks">
            <ImprovementContent>
                <Text as="h3" size="f-4" weight="600">Existing Sitelinks</Text>
            </ImprovementContent>
            <ImprovementContent :ad-width="true">
                <TextAd
                    :ad="highestClickedAdStatic"
                    highlight="sitelinks"
                    :title-tag="adTitleTag"
                />
            </ImprovementContent>
        </div>

        <div v-else>
            <ImprovementContent>
                <Text as="h3" size="f-4" weight="600">Sitelink Examples</Text>
            </ImprovementContent>

            <ImprovementContent :ad-width="true">
                <AdExtensionPreview
                    :title-tag="{
                        title: 'Accountancy Service Example',
                        classes: 'opteo-blue',
                    }"
                    :sitelinks="sitelinkExamplePreviews.accountant"
                />
                <Spacer height="2.5rem" />
                <AdExtensionPreview
                    :title-tag="{
                        title: 'Electronics Retailer Example',
                        classes: 'opteo-blue',
                    }"
                    :sitelinks="sitelinkExamplePreviews.tech"
                />
            </ImprovementContent>
        </div>

        <ImprovementContent>
            <Spacer height="3rem" />
            <ImprovementCreatedDate :last-updated="lastUpdated" :total-days="90" />
        </ImprovementContent>

        <template v-slot:adjust-step-1>
            <ImprovementContent>
                <ImprovementHeader title="Input Sitelink Extensions">
                    <Text as="p">
                        Use the form below to add account-level sitelink extensions to your
                        campaigns. Opteo recommends adding at least
                        <b>4</b> account-level sitelinks. Use the preview of your highest clicked ad
                        to see how sitelinks will appear on the search engine results page.
                    </Text>
                    <Spacer height="1rem" />
                    <Text as="p">
                        Click
                        <Text as="span" color="green" weight="600">{{ pushActionText }}</Text> to
                        push your sitelink extensions to Google Ads.
                    </Text>
                </ImprovementHeader>
            </ImprovementContent>

            <ImprovementContent :ad-width="true">
                <TextAd :ad="highestClickedAd" highlight="sitelinks" :title-tag="adTitleTag" />
            </ImprovementContent>

            <Spacer height="3rem" />
            <ImprovementContent>
                <div class="form-container">
                    <oForm ref="sitelinkForm">
                        <div v-for="(extension, index) in sitelinkExtensions" :key="index">
                            <div
                                :class="
                                    index + 1 < sitelinkExtensions.length
                                        ? 'form-section'
                                        : 'form-section-no-bb'
                                "
                            >
                                <Text as="h6" weight="600">
                                    Sitelink {{ getNumberWord(index + 1) }}
                                </Text>
                                <Spacer height="2rem" />
                                <oInput
                                    v-model="extension.anchor"
                                    ref="anchorRefs"
                                    :name="`anchor${index}`"
                                    label="Sitelink Anchor"
                                    placeholder="Sitelink Anchor"
                                    :max-characters="MAXIMUM_ANCHOR_LENGTH"
                                    required
                                />
                                <Spacer height="1.5rem" />
                                <oInput
                                    v-model="extension.line1"
                                    :name="`line1-${index}`"
                                    label="Sitelink Line One"
                                    placeholder="Sitelink Line One"
                                    :max-characters="MAXIMUM_DESCRIPTION_LENGTH"
                                />
                                <Spacer height="1.5rem" />
                                <oInput
                                    v-model="extension.line2"
                                    :name="`line2-${index}`"
                                    label="Sitelink Line Two"
                                    placeholder="Sitelink Line Two"
                                    :max-characters="MAXIMUM_DESCRIPTION_LENGTH"
                                />
                                <Spacer height="1.5rem" />
                                <oInput
                                    v-model="extension.url"
                                    :name="`url${index}`"
                                    label="URL"
                                    :validator="urlValidator"
                                    placeholder="https://www.example.com"
                                    required
                                />
                            </div>
                        </div>
                        <div class="button-container">
                            <oButton
                                :size="aboveMobile ? 'small' : 'medium'"
                                color="white"
                                @clicked="resetSitelinkExtensions"
                                :fullWidth="aboveMobile ? false : true"
                            >
                                Reset Form
                            </oButton>
                            <Spacer v-if="aboveMobile" width="0.5rem" />
                            <Spacer v-else height="0.75rem" />
                            <oButton
                                :size="aboveMobile ? 'small' : 'medium'"
                                color="white"
                                @clicked="resetSitelinkExtensions(true)"
                                :fullWidth="aboveMobile ? false : true"
                            >
                                Clear Form
                            </oButton>
                        </div>
                    </oForm>
                </div>
            </ImprovementContent>
        </template>
    </ImprovementView>
</template>

<script setup lang="ts">
import {
    ImprovementHeader,
    ImprovementContent,
    ImprovementCreatedDate,
    EntityPillList,
    EntityPill,
    oInput,
    oForm,
    Text,
    Spacer,
    TextAd,
    AdExtensionPreview,
    oButton,
} from '@opteo/components-next'
import { EntityPillLink, EntityPillLinkList } from '@/components/global/Entity'
import ImprovementView from '@/components/improvement/ImprovementView.vue'
import ImprovementHelpLink from '@/components/improvement/ImprovementHelpLink.vue'

import { useAddSitelinkExtension } from '@/composition/improvement/types/account-level-extensions/useAddSitelinkExtension'
import { getNumberWord, urlValidator } from '@/lib/globalUtils'
import useMediaQuery from '@/composition/global/useMediaQuery'

const { aboveMobile } = useMediaQuery()
const {
    title,
    pushMessages,
    onPush,
    lastUpdated,
    highestClickedAd,
    highestClickedAdStatic,
    adTitleTag,
    sitelinkForm,
    sitelinkExtensions,
    resetSitelinkExtensions,
    sitelinkExamplePreviews,
    entityPillList,
    entityPillLinkList,
    hasExistingSitelinks,
    missingSitelinkCount,
    MAXIMUM_ANCHOR_LENGTH,
    MAXIMUM_DESCRIPTION_LENGTH,
    anchorRefs,
    pushActionText,
    adjustSteps,
} = useAddSitelinkExtension()

const needsShowingEntityPillList = ({
    entities,
    content,
}: {
    entities: typeof entityPillList | typeof entityPillLinkList
    content: string | undefined
}) => {
    if (!entities) return false

    return entities.length > 1 || (!aboveMobile && content && content.length > 30)
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.form-container {
    @include container;
    @include relative;
}

.form-section {
    @include pa-36;
    border-bottom: 1px solid;
    @include opteo-border;
}
.form-section-no-bb {
    @include pa-36;
}

.button-container {
    border-top: 1px solid;
    @include opteo-border;
    @include flex;
    @include items-center;
    @include ph-36;
    @include pv-28;
}

@media screen and (max-width: $mq-767-max) {
    .button-container {
        @include block;
        @include pa-24;
    }
    .form-section,
    .form-section-no-bb {
        @include pa-24;
    }
}
</style>
