<template>
    <!-- <OnboardingTour
        step-name="tour_ngram"
        :buttonText="'Continue to N-Gram Tool'"
        contentMaxWidth="470px"
    >
        <template v-slot:header>
            <Text as="h4" weight="600" align="center">N-Gram Tool</Text>
        </template>
        <template v-slot:copy>
            Find the worst performing n-grams in your account and add them as negative keywords.
            Decrease wasted spend, reduce irrelevant impressions and improve audience targeting in
            just a few clicks.
        </template>
    </OnboardingTour> -->

    <FullScreenOverlay @close="handleNavigation">
        <PageWrapper :title="steps[currentStep].title">
            <template #right-header>
                <Tabs :links="tabLinks" :active="$route.name" />
            </template>
            <template #content>
                <router-view />
            </template>
        </PageWrapper>
    </FullScreenOverlay>
</template>

<script lang="ts" setup>
import { provideNGramTool } from '@/composition/toolkit/nGramTool/useNGramTool'

import { Tabs } from '@opteo/components-next'

import FullScreenOverlay from '@/components/util/FullScreenOverlay.vue'
import PageWrapper from '@/layouts/PageWrapper.vue'

const { tabLinks, steps, currentStep, goToNGramSelection, closeNGramTool } = provideNGramTool()

function handleNavigation() {
    currentStep.value === 0 ? closeNGramTool() : goToNGramSelection()
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

:global(.section-buttons) {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
:global(.step-2-section-buttons) {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

// Transitions
.page-fade-enter-active,
.page-fade-leave-active {
    transition: opacity 0.48s ease;
}

.page-fade-enter-from,
.page-fade-leave-to {
    opacity: 0;
}
</style>
